<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Aim & Scope" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJRTMR</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4">
                <h3>Aim :</h3>
                <div class="aim row">
                  <div class="col-sm-7">
                    <p class="ps-3">
                      The Journal's mission is to be a Scholarly Open Access, Peer-Reviewed,
                      Multidisciplinary, Monthly, and Fully Refereed Magazine Focusing On Theories, Methodologies, And
                      Applications In Engineering And Technology. It publishes refereed original research articles and
                      technical notes in all areas of engineering and technology. All submitted articles must report
                      original, previously unpublished research results, whether experimental or theoretical and will be
                      peer-reviewed twice. Papers submitted to the Journal must meet these criteria and must not be
                      considered for publication elsewhere. Manuscripts must adhere to the Journal's style and are subject
                      to review and editing. To provide a platform for publishing results and research with a vital
                      empirical component, to bridge the significant gap between research and practice by encouraging the
                      publication of original, novel, industry-relevant research, and to solicit original and unpublished
                      research papers based on theoretical or experimental works.
                    </p>
                  </div>
                  <div class="col-sm-5">
                    <img class="mt-5" src="../../../assets/images/AboutUs/aimscopeOne.png" alt="image" />
                  </div>
                  <div>
                    <p class="ps-3"></p>
                  </div>
                </div>
                <div class="scope">
                  <h5>Scope :</h5>
                  <div class="ps-3">
                    <p>
                      International Journal Of Recent Trends In Multidisciplinary Research (IJRTMR) Is A Bi-Monthly
                      Peer-Reviewed, Refereed, Indexed International Research Journal, So There Is A Wide Scope For
                      Publication. IJRTMR is A Platform For All Researchers In Varied Subject To Publishes Original
                      Authoritative Research Papers, Articles, Research Projects, Reviews, Mini-Reviews, Case Studies,
                      Synopsis, And Short Research Communications. Authors Are Encouraged To Submit Complete Unpublished
                      And Original Works, Which Are Not Under Review In Any Other Journals.
                    </p>
                  </div>
                </div>
                <div class="aim-journal">
                  <h5>The aim of the journal is to :</h5>
                  <div>
                    <ul>
                      <li>
                        <p>
                          Distribute original, scientific, theoretical, or practical research in engineering and related
                          domains.
                        </p>
                      </li>
                      <li>
                        <p>
                          By encouraging the publishing of unique, innovative, industry-relevant research, you can bridge
                          the gap between theory and practice that now exists.
                        </p>
                      </li>
                      <li>
                        <p>
                          By encouraging the publishing of unique, cutting-edge research pertinent to the industry, we can
                          bridge the substantial gap between theory and practice.
                        </p>
                      </li>
                      <li>
                        <p>
                          They seek innovative and unpublished research articles for worldwide publication based on
                          theoretical or experimental efforts.
                        </p>
                      </li>
                      <li>
                        <p>
                          Solicit unpublished and unique research papers based on theoretical or experimental research.
                        </p>
                      </li>
                      <li>
                        <p>
                          Publish original, theoretical, and practical advances in Mechanical Engineering, Civil
                          Engineering, Computer Science & Engineering, Textile Engineering, Information Technology,
                          Electrical and Electronics Engineering, Electronics and Telecommunication, and all
                          interdisciplinary streams of Engineering Sciences.
                        </p>
                      </li>
                      <li>
                        <p>
                          Impart a platform for publishing results and research with a vital empirical component.
                        </p>
                      </li>
                      <li>
                        <p>
                          Provide a venue for publishing results and research with an essential empirical component.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijrtmr" },
        { id: 2, name: "Aim & Scope", to: "/ijrtmr/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijrtmr/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijrtmr/GuidanceForEms-Ijrtmr"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijrtmr/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijrtmr/current-issue" },
        { id: 7, name: "Archives", to: "/ijrtmr/archives" },
        {
          id: 8,
          name: "Conference",
          to: "/ijrtmr/conference",
        },
        { id: 9, name: "Editorial Board", to: "/ijrtmr/editorial-board" },
        { id: 10, name: "Topics", to: "/ijrtmr/topics" },
        { id: 11, name: "Call for papers", to: "/ijrtmr/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijrtmr/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijrtmr/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijrtmr/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijrtmr/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijrtmr/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijrtmr/downloads" },
        { id: 18, name: "FAQs", to: "/ijrtmr/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijrtmr/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijrtmr/ijrtmr-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}</style>
