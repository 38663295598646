<template>
    <div>
        <div class="project-details-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="projects-details-desc">
                            <p>Below are the mandatory criteria for any article to be considered for publication in the
                                Fifth Dimension Research Publication (FDRP). Please adhere to these criteria to avoid
                                rejection of the article by the editorial team.</p>

                            <div class="features-text mb-3">
                                <h4>Article adheres to the manuscript preparation guidelines explained below:</h4>
                                <ul class="features-list">
                                    <li>
                                        <p>Article Should Be In Microsoft Word Format Only</p>
                                    </li>
                                    <li>
                                        <p>Articles Should Be Written In Single Column Format, Using Times New Roman
                                            Font, 11 Point Font Size. Keep The Layout Of The Text As Simple As Possible.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Equations And Formula Should Be Readable, Preferably Written Using Equation
                                            Editing Software’s (E.G. Math Type). Alternately, Authors Have To Provide
                                            The Fonts Used For Creating The Equations/Formulae.</p>
                                    </li>
                                    <li>
                                        <p>All Figures Provided Are Of High Resolution, Preferably 300dpi.</p>
                                    </li>
                                    <li>
                                        <p>References Should Be In Times New Roman</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3">
                                <h4>Manuscript preparation guidelines:</h4>
                                <p>Articles submitted to the FDRP-Journal’s should conform to the guidelines below.
                                    Please review the author checklist supplied at the end of this document before
                                    submitting. The following list outlines the subjects that will be covered
                                    chronologically in the article:</p>
                                <ul>
                                    <li>
                                        <p>Title</p>
                                    </li>
                                    <li>
                                        <p>Abstract</p>
                                    </li>
                                    <li>
                                        <p>Keywords
                                        </p>
                                    </li>
                                    <li>
                                        <p>Introduction</p>
                                    </li>
                                    <li>
                                        <p>Concept headings (include statistical methodology, if any)
                                        </p>
                                    </li>
                                    <li>
                                        <p>Discussion</p>
                                    </li>
                                    <li>
                                        <p>Conclusion</p>
                                    </li>
                                    <li>
                                        <p>Acknowledgements (If any)</p>
                                    </li>
                                    <li>
                                        <p>References</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3">
                                <h4>Electronic formats :</h4>
                                <p>Authors must send their articles in MS Word (.doc) format. In case of any difficulty
                                    with the manuscript submission process or concern regarding the suitability of your
                                    files, please get in touch with us at <b>editor’s email ID </b>with corresponding
                                    Journal
                                    Editor.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Article title :</h4>
                                <p>The title should be brief and relevant to the article's content. In the title, avoid
                                    using abbreviations. Titles should be given in title case, meaning all words are
                                    capitalized except for prepositions, articles, and conjunctions. calibri should be
                                    used for all plant names.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Author names & affiliations :</h4>
                                <p>Provide first names or initials (if used), middle names or initials (if used), and
                                    surnames for all authors. Affiliation details should include—department, university
                                    or organization, city, state, and country for all authors. One of the authors should
                                    be designated as the corresponding author with an asterisk (*) against their name.
                                    Only the corresponding author's email address should be provided in the article. It
                                    is the corresponding author's responsibility to ensure that the author list and the
                                    summary of the author's contributions to the study are accurate and complete.
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>Abstract & Keywords :</h4>
                                <p>The abstract introduces the topic and should be no more than 300 words long. It
                                    should describe the methodologies utilized without delving into methodological
                                    detail, and it should summarize the most relevant findings. Please do not include
                                    any sources in the abstract and, if feasible, avoid utilizing acronyms.</p>
                                <p>For indexing reasons, authors should give 4-6 keywords. Keywords should be
                                    capitalized and separated by a comma. Avoid using generic and plural phrases and
                                    several notions (for example, 'and' 'of').
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>Headings :
                                </h4>
                                <p>The headers and subheadings, beginning with "1. Introduction," are written in both
                                    uppercase and lowercase characters, bolded, and flush left aligned. From the
                                    Introduction through the Acknowledgements, each heading is given a sequential number
                                    starting with 1, 2, 3, etc. The numbering for subheadings is 1.1, 1.2, etc. To
                                    further separate a subsection, the numbers 1.1.1, 1.1.2, etc.
                                </p>
                                <p>The font size for the heading is 11 points in boldface, and subsections with 10
                                    points and not bold. Do not underline any of the headings or add dashes, colons,
                                    etc. (10)
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>Abbreviations :
                                </h4>
                                <p>All abbreviations should be specified in the text when they are first used, along
                                    with the abbreviation in parentheses. Magnetic Resonance Imaging, for example (MRI).
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>Units and Symbols :
                                </h4>
                                <p>Symbols should be used when referring to alpha, beta, mu, etc. (Ex: α, β, µ, etc.).
                                    All units follow the International System of Units (SI units).
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>Figures: General guidelines
                                </h4>
                                <p><b>Format and resolution of the figures: </b>TIFF, JPG, or EPS files in high quality
                                    are required from the authors. Figures must be produced with a minimum resolution of
                                    300 ppi.
                                </p>
                                <p><b>File size: </b>The file sizes should be at most 20 MB.
                                </p>
                                <p><b>File naming: </b>Figure files should be named with the author's serial number and
                                    last name included. Figure 1 should be titled "Figure 1. Marley" if the author's
                                    name is Bob Marley.
                                </p>
                                <p><b>Citation: </b>All figures must be cited in the text, and authors should indicate
                                    where they should be inserted.
                                </p>
                                <p><b>Figure captions: </b>These must be included in the text and provided sequentially
                                    at the end of the article. The captions should be short, having 10-15 words in
                                    sentence case style. E.g., <b>Eg. Figure 1.</b> Percentage of detection rate vs. the
                                    number of nodes.
                                </p>
                                <p><b>Permissions: </b>Authors should obtain permission from authors for copyright
                                    figures and tables before submitting them to the Journal of Science and Technology.
                                </p>
                                <p>All figures will be released under the Creative Commons Attribution License, allowing
                                    them to be freely used, disseminated, and built upon with due acknowledgment. Please
                                    only submit previously copyrighted statistics if you have permission from the
                                    copyright owners to publish them under the CCAL approval.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Tables: General guidelines</h4>
                                <p>Tables should be included in the text file at the end of the article.
                                    All tables should have a concise title and be written as Table 1 with a period (.).
                                </p>
                                <p><b>Eg. </b>Table 1. Stimulation settings.</p>
                                <p>Footnotes can be used to explain abbreviations. Tables extending beyond 1 page should
                                    be avoided.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Acknowledgments :</h4>
                                <p>Individuals who contributed to the work but did not meet the authorship standards
                                    should be acknowledged in the Acknowledgements, along with their contributions.
                                    Writers are asked to ensure that everybody identified in the Acknowledgements agrees
                                    to be so named.
                                </p>
                            </div>
                            <div class="mb-3">
                                <h4>References :</h4>
                                <p>The reference list should only contain works that have been published or approved. It
                                    is appropriate to mention meetings, conference sessions, abstracts, or articles that
                                    were submitted but have yet to be accepted.
                                </p>
                                <p><b>In-text citations: </b>References cited in the text should be in Times New Roman.
                                </p>
                                <p><b>Reference List: </b>The books cited in your text should be listed here. Your text
                                    should contain it at the conclusion. It ought to be put in order numerically by
                                    citation. These are some examples.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Overview of the production process :</h4>
                                <p>Upon acceptance of the work, the manuscript will be edited and inspected for missing
                                    materials or discrepancies. The production staff will contact the authors and
                                    request the missing material in their manuscript. This will be the last opportunity
                                    for authors to make changes to the text. When the modified files have been
                                    finalized, they will be typeset to create the first proofs. Writers will receive the
                                    first proofs of their pieces to address any outstanding issues. Writers are
                                    instructed not to provide any revisions during the proofing process. They are asked
                                    to team with the presentation team to ensure a smooth workflow and timely
                                    publication of the journal issue.</p>
                            </div>
                            <div class="mb-3">
                                <h4>Author checklist before submission :</h4>
                                <p>Are you ready to submit your article? We recommend you check these items before
                                    uploading the article on our website. In case of any difficulty in submitting your
                                    article, please get in touch with us at <b>editor’s email ID</b> with corresponding
                                    Journal
                                    Editor/<b>support@fdrpjournals.org</b>
                                </p>
                                <ul>
                                    <li>
                                        <p>Manuscript is adhering to guidelines.</p>
                                    </li>
                                    <li>
                                        <p>All author affiliations provided.</p>
                                    </li>
                                    <li>
                                        <p>Corresponding author’s email address provided.</p>
                                    </li>
                                    <li>
                                        <p>The document includes calls out for each figure and table.</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3">
                                <h4 class="editorial-title">Editorial management system (Guidance)</h4>
                                <h5>How to submit article?</h5>
                                <p>Create Account by using Author Name, email ID, Mobile No. Once Account created,
                                    author can enter directly Login Credential. After that, By Using New Submission,
                                    author can submit Article.
                                </p>
                                <p><b>Note: </b>Make sure are you entered correct email ID, Mobile No. because we will
                                    send Notification registered Email, Mobile No.</p>
                                <p><b>While Submitting Article, author can choose below Details:</b></p>
                                <ul>
                                    <li>
                                        <p>Research Area</p>
                                    </li>
                                    <li>
                                        <p>Mode of Process</p>
                                    </li>
                                    <li>
                                        <p>Type of article</p>
                                    </li>
                                    <li>
                                        <p>Type of Issues</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-3">
                                <img src="../../assets/images/AuthorImageOne.png">
                            </div>
                            <div class="mb-3">
                                <h5>Author can know task Process?</h5>
                                <p class="mb-2">Yes. After submitted article. Your article under Editorial Check. It can
                                    be
                                    represented, in terms of Plagiarism Check, Peer-Review etc. Once your article
                                    accepted after Review process, Author can view the Task with Status.</p>
                                <img src="../../assets/images/AuthorImageTwo.png">
                            </div>
                            <div class="mb-3">
                                <h5>Author can know Review Process?</h5>
                                <p class="mb-2">Yes. After plagiarism Check, Article will assign to reviewer with
                                    respective Research area. Once Editorinchief, received response from reviewers.
                                    Final Note will be Displayed, Like -Accept/Accept with minor correction/ Reject
                                    notification will send to author.</p>
                                <img src="../../assets/images/AuthorImageThree.png">
                            </div>
                            <div class="mb-3">
                                <h5>How Author can Know submitted Article Accepted or Not?</h5>
                                <p class="mb-2">Once Reviewer’s Command Satisfactory, Editorinchief will release
                                    Acceptance letter along with Tentative Article Publication Scheduled Date through
                                    Editorial Office. Author can view in Acceptance Tap.</p>
                                <img src="../../assets/images/AuthorImageFour.png">
                            </div>
                            <div class="mb-3">
                                <h5>What is the Procedure for Accepted Article to be publish?</h5>
                                <p>Once you’re Article Accepted for Publication, Author have to submit below mentioned
                                    Documents within a Week.</p>
                                <p><b>Copyright form</b>(before generating copyright form-Author have to update profile.
                                    Like, Address, Date of Birth etc. then only copyright form will generate. Take print
                                    out, manually corresponding author have to signature)</p>
                                <p><b>Final Manuscript </b>(As per our journal format)⦁</p>
                                <p><b>Article Processing fee receipt</b>(with DOI, Without DOI)
                                </p>
                                <p>Once we received all necessary Documents. We will process your article. Article will
                                    publish with in <b>24hrs to 48hrs.</b></p>
                                <img src="../../assets/images/AuthorImageFive.png">
                            </div>
                            <div class="mb-3">
                                <img src="../../assets/images/AuthorImageSix.png">
                            </div>
                            <div class="mb-3">
                                <img src="../../assets/images/AuthorImageSeven.png">
                            </div>
                            <div class="mb-3">
                                <h4>Payment</h4>
                                <img src="../../assets/images/AuthorImageEight.png">
                            </div>
                            <div class="mb-3">
                                <h4>Author will get certificates including corresponding authors?</h4>
                               <p>Yes. All author will get certificates. Author can download file folder. Also Published Article Copy and Journal Archive link also.</p>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsDetailsContent',
}
</script>
<style scoped>
.editorial-title{
    color: #0000ff;
}
</style>