<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Editorial Board" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJEEE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-4 ps-4">
            <div
              class="containers"
              style="display: flex; flex-direction: row; margin-bottom: 20px"
            >
              <div
                class="editorialBox p-4"
                style="margin-right: 2%; width: 49%"
              >
                <h6>Patron/Editor-in-chief :</h6>
                <p><b>Name:</b> Dr. S. Sivaganesan</p>
                <p>
                  <b>Designation:</b> Professor& Head Department of Electrical &
                  Electronics Engineering.
                </p>
                <p>
                  <b>Address:</b> Holymary Institute of Technology &
                  Science.Hyderabad-501301.India
                </p>
                <p>
                  <b>E-Mail:</b> Editor_indjeee@fdrpjournals.org,<br>
                  sivaganesan@hmgi.ac.in
                </p>
              </div>
              <div class="editorialBox p-4" style="width: 49%">
                <h6>Managing Editor:</h6>
                <p><b>Name:</b> Ms. E. Sathya</p>
                <p>
                  <b>Designation:</b> Assistant Professor Department of
                  Electronics and Communication Engineering,
                </p>
                <p>
                  <b>Address:</b> K.K.C College of Engineering & Technology,
                  Tamil Nadu 621802. India.
                </p>
                <p><b>E-Mail:</b> Editor_indjeee@fdrpjournals.org</p>
              </div>
            </div>

            <div class="row editorialBox my-2 mx-1" v-for="(data, index) in membersList" :key="(index)">
              <div class="col-lg-3 col-md-12 col-sm-12 p-3 editorialImage">
                <img :src="data.member_image_url" alt="img"  />
              </div>
              <div class="col p-3">
                <h6>{{ data.member_role }}</h6>
              <p><b>Name:</b>{{ data.member_name }}</p>
              <p><b>Designation:</b> {{ data.member_designation }}</p>
              <p><b>Address:</b> {{ data.member_address }}</p>
              <p><b>E-mail:</b> {{ data.member_email }}</p>
            <p><b>Research Area:</b>{{ data.member_researcharea }}</p>
            <p><b>Profile Link: </b>{{ data.member_website }}</p>
            <p><b>Country:</b> {{ data.member_country }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjeee/Guidance-For-Ems-Indjeee"},
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
      membersList:[]
    };
  },
  methods:{
    getMemberData() {
      this.api.getData("editorial/5","journals/v1/").then(
        (res) => {
          this.membersList = res.data.membersList;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getMemberData();
  },
};
</script>
<style scoped lang="scss">
h3 {
  font-size: 24px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}

.editorialBox {
  /* border: 2px solid #EEEDE7; */
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p {
  text-align: left;
  margin-bottom: 5px;
}
.editorialImage img {
  height: 200 !important;
}
.imageBox {
  width: 70%;
  height: auto;
  margin-top: 30px;
}
@media screen and (max-width: 992px) {
  .containers {
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
</style>
