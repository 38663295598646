<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Call For Papers" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4">
                <div>
                  <h3>Call For Papers :</h3>
                  <div class="call-for-paper ps-3">
                    <p>
                      Indian Journal of Computer Science and Technology (INDJCST) is an online Quarterly Journal by Fifth
                      Dimension Research Publication.
                    </p>
                    <p>
                      It is an academic, online, open access, Peer reviewed National Journal. It aims to
                      publish original, theoretical, and practical advances in Computer Science and Technology and all
                      interdisciplinary streams of Engineering Sciences. The board of committee of INDJCST will review all
                      submitted papers.
                    </p>
                    <p>
                      Papers for the regular issues of the Journal can be submitted, around the year, electronically at
                      editorinchief@indjcst.com. After the final acceptance of the article, based upon the detailed review
                      process, the paper will immediately be published online.
                    </p>
                  </div>
                </div>
                <div class="deadlines">
                  <h5>Important Deadlines for Current Issue :</h5>
                  <h6>
                    Last Date for Manuscript Submission: 25th of Apirl, August,
                    December
                  </h6>
                  <div>
                    <ul>
                      <li>
                        <p>
                          Submit your paper in Microsoft word format (.doc or .docx); if you used latex, send it in PDF
                          format.
                        </p>
                      </li>
                      <li>
                        <p>
                          Please submit your paper with a signed Copyright Transfer Form (the corresponding author marks
                          enough, no need at all), and it should want to was sent while submitting the papers to us.
                        </p>
                      </li>
                      <li>
                        <p>
                          If the manuscript wants not accepted in our journal, then the Copyright Transfer Form
                          submitted with us will become void.
                        </p>
                      </li>
                      <li>
                        <p>
                          The manuscripts submitted with the copyrights form only will be forwarded to the review process.
                        </p>
                      </li>
                      <li>
                        <p>
                          Submitting the paper to multiple journals is an offense, don’t waste our valuable time.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="steps mt-2">
                  <h5>
                    For author’s convenience, we strongly follow the following
                    steps :
                  </h5>
                  <div>
                    <ul>
                      <li>
                        <p>A response will to given within 12 hours.</p>
                      </li>
                      <li>
                        <p>The paper will review within Two days.</p>
                      </li>
                      <li>
                        <p>
                          The author will get their Acceptance/Rejection Notification within three days of submitting a
                          paper.
                        </p>
                      </li>
                      <li>
                        <p>
                          All the accepted papers will be open and accessible with a full PDF download.
                        </p>
                      </li>
                      <li>
                        <p>
                          All Authors will get a soft copy of the certificate, acceptance letter, and reviewer evaluation
                          grading.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <h6>
                    Submit your <b>article:</b><i class="text-success">editorinchief@indjcst.com</i> (or)
                    Online submission
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
