<template>
    <div>
      <Navbar />
      <PageTitle pageTitle="Editorial management system (Guidance)" />
      <div class="journal-page-area pb-100" style="padding-top: 50px">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJRTMR</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
            <div class="col-lg-9 col-md-9 mt-2">
                <h3 class="headingThree">
                    1.	How to submit article?
                </h3>
                <div class="ps-3">
                    <p>Create <b>Account</b> by using Author <b>Name, email ID, Mobile No.</b>  Once Account created, author can enter directly Login Credential. After that, By Using New Submission, author can submit Article.</p>
                    <p><b class="text-danger">Note:</b> Make sure are you entered correct email ID, Mobile No. because we will send Notification registered Email, Mobile No.</p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/ijireimageone.png" />
                </div>
                <div class="ps-3">
                    <p>While Submitting Article, author can choose below Details:</p>
                    <ul>
                        <li><p>Research Area</p></li>
                        <li><p>Mode of Process</p></li>
                        <li><p>Type of article</p></li>
                        <li><p>Type of Issues</p></li>
                    </ul>
                </div>
                <div class="row p-4">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/WKLEAbMwkdg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="headingThree mt-4">
                    2.	Author can know task Process?
                </h3>
                <div class="ps-3">
                    <p>Yes. Absolutely.</p>
                    <p>After submitted article. Your article under Editorial Check. It can be represented, in terms of Plagiarism Check, Peer-Review etc. Once your article accepted after Review process, Author can view the Task with Status.</p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagetwo.png"/>
                </div>
                <h3 class="headingThree mt-4">
                    3.	Author can know Review Process?
                </h3>
                <div class="ps-3">
                    <p>Yes. Absolutely. </p>
                    <p>After plagiarism Check, Article will assign to reviewer with respective Research area. Once Editorinchief, received response from reviewers.  Final Note will be Displayed, Like -Accept/Accept with minor correction/ Reject notification will send to author.</p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagethree.jpg"/>
                </div>
                <h3 class="headingThree mt-4">
                    4.	How Author can Know submitted Article Accepted or Not? 
                </h3>
                <div class="ps-3">
                    <p>Once Reviewer’s Command Satisfactory, Editorinchief will release Acceptance letter along with Tentative Article Publication Scheduled Date through Editorial Office. Author can view in Acceptance Tap.  </p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagefour.jpg"/>
                </div>
                <h3 class="headingThree mt-4">
                    5.	What is the Procedure for Accepted Article to be publish? 
                </h3>
                <div class="ps-3">
                    <p>Once you’re Article Accepted for Publication, Author have to follow 3 steps.</p>
                    <p>a)	Copyright form (before generating copyright form-Author have to update profile. Like, Address, Date of Birth etc. then only copyright form will generate. Take print out manually corresponding author have to signature)</p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagefive.jpg"/>
                </div>
                <div class="ps-3">
                    <p>b)	Final Manuscript (As per our journal format)</p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagesevan.jpg"/>
                </div>
                <div class="ps-3">
                    <p>c)	Article Processing fee receipt (with DOI, With out DOI) </p>
                </div>
                <div class="row p-4"> 
                    <img src="../../../assets/images/Guidance-for-Ems-ijire/imagesix.jpg"/>
                </div>
                <div class="ps-3">
                    <p>Above three document should be submit in Final submission tab. Once we received all necessary Documents. We will process your article. Article will publish with in 24hrs to 48hrs.</p>
                </div>
                <div class="row p-4">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ZIyJ7cgJ4qw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <h3 class="headingThree mt-4">
                    6. Author will get certificates including corresponding authors? 
                </h3>
                <div class="ps-3">
                    <p>Yes. All author will get certificates. Author can download file folder. Also Published Article Copy and Journal Archive link also.</p>
                </div>
                <div class="row p-4">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/FtLSCMLBpyw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
    
    <script>
  import Navbar from "../../Layout/Navbar.vue";
  import Footer from "../../Layout/Footer";
  import PageTitle from "../../Common/PageTitle";
  export default {
    components: {
      Navbar,
      Footer,
      PageTitle,
    }, 
    data: function () {
      return {
        links: [
        { id: 1, name: "Home", to: "/ijrtmr" },
        { id: 2, name: "Aim & Scope", to: "/ijrtmr/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijrtmr/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijrtmr/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijrtmr/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijrtmr/current-issue" },
        { id: 7, name: "Archives", to: "/ijrtmr/archives" },
        {id:8,name:"Conference",to:"/ijrtmr/conference"},
        { id: 9, name: "Editorial Board", to: "/ijrtmr/editorial-board" },
        { id: 10, name: "Topics", to: "/ijrtmr/topics" },
        { id: 11, name: "Call for papers", to: "/ijrtmr/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijrtmr/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijrtmr/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijrtmr/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijrtmr/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijrtmr/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijrtmr/downloads" },
        { id: 18, name: "FAQs", to: "/ijrtmr/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijrtmr/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijrtmr/ijrtmr-contact" },
      ],

      };
    },
  };
  </script>
    
<style scoped>

</style>
    
  
