<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Instruction For Authors" />
        <InstructionForAuthors />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import InstructionForAuthors from '../Authors/InstructionForAuthors'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        InstructionForAuthors,
        Footer,
    }
}
</script>
