<template>
  <div>
    <Navbar/>
    <PageTitle pageTitle="Call For Paper"/>
    <div></div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar.vue"
import Footer from "../Layout/Footer.vue";
import PageTitle from "../../components/Common/PageTitle.vue";
export default {
components : {
    Navbar,
    Footer,
    PageTitle
}
}
</script>

<style>

</style>