<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Indexing & Abstracting" />
    <div class="journal-page-area ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
       
            <div class="col-lg-9 col-md-9">
            <div class="row ">
                <div class="col-lg-3 col-md-3 col-sm-3 m-3 indexingImage single-pricing-table" v-for="data in indexingList" :key="data.indexing_id" v-b-tooltip.hover.bottom @click="openIndexingURL(data.indexing_url)" :title="data.indexing_name">
                          <img :src="data.indexing_image_url" class="rounded mx-auto d-block" width="250" height="150"/>
                </div>
            </div>
          
          </div>
    
         
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
    
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        {
          id: 8,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 9, name: "Topics", to: "/ijsreat/topics" },
        { id: 10, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 12,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 16,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 17, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
      indexingList: [],
    };
  },
  methods: {
      openIndexingURL: function(indexingURL) {
         window.open(indexingURL, '_blank')
      },
      getIndexing: function() {
        this.api.getDataModule("indexing/3", "journals/v1/").then((res) => {
            console.log(res.data);
            this.indexingList = res.data.indexingList;
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },
    mounted: function() {
      this.getIndexing()
    }
};
</script>
<style scoped>
.indexingImage {
 margin:10px;
 cursor: pointer;
 
}
.indexingImage img {
  padding: 10px;
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.single-pricing-table:hover {
  transform: translateY(-10px);
  box-shadow: none;
} 
</style>