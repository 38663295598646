<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Topics"/>
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container ">
            <div class="row pt-4">
              <h3>Topics :</h3>
              <div class="ps-3">
                <ul>
                  <li><p>Computing Theory</p></li>
                  <li><p>Scientific Computing</p></li>
                  <li><p>Cloud Computing</p></li>
                  <li><p>High-Performance Computing</p></li>
                  <li><p>Numerical And Symbolic Computing</p></li>
                  <li><p>Database Systems</p></li>
                  <li><p>Real-Time Systems</p></li>
                  <li><p>Operating Systems</p></li>
                  <li><p>Warning Systems</p></li>
                  <li><p>Decision Support Systems</p></li>
                  <li><p>Information Processes And Systems</p></li>
                  <li><p>Systems Integration</p></li>
                  <li><p>Robotics</p></li>
                  <li><p>Bio-Informatics</p></li>
                  <li><p>Web Intelligence</p></li>
                  <li><p>Artificial Intelligence</p></li>
                  <li><p>Security</p></li>
                  <li><p>Networking</p></li>
                  <li><p>Software Engineering</p></li>
                  <li><p>Pattern Recognition</p></li>
                  <li><p>E-Science And E-Commerce</p></li>
                  <li><p>Signal And Image Processing</p></li>
                  <li><p>Control Theory And Applications</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
     Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}


</style>
