<template>
    <div class="work-slider">
        <carousel
            :autoplay="5000"
            :settings='settings' 
            :breakpoints='breakpoints'
            :wrap-around="true"
        >
            <slide 
                v-for="slide in carouselItems" 
                :key="slide.id"
            >
                <div class="work-card">
                    <img :src="slide.image" alt="image">
                    
                    <div class="content text-center">
                        <div class="caseStudyTitle">
                        <span>
                            <router-link :to='slide.category.toLowerCase()'>{{slide.category}}</router-link>
                        </span>
                    </div>
                        <div class="caseStudyTitle">
                        <h3>
                            <router-link :to="slide.category.toLowerCase()">{{slide.title}}</router-link>
                        </h3>
                    </div>
                    <div class="caseStudyButton">
                        <router-link class="work-btn" :to="slide.category.toLowerCase()+'/current-issue'">Current Issue</router-link>
                    </div>
                    </div>
                </div>
            </slide>

            <template #addons>
                <Navigation />
            </template>
        </carousel>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'CaseStuduesCarousel',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/ijire-nlogo.png'),
                category: 'IJIRE',
                title: 'International Journal of Innovative Research in Engineering',
            },
            {
                id: 2,
                image: require('../../assets/images/ijret-nlogo.png'),
                category: 'IJSREAT',
                title: 'International Journal of Scientific Research in Engineering and Technology',
            },
            {
                id: 3,
                image: require('../../assets/images/ijrtmr-nlogo.png'),
                category: 'IJRTMR',
                title: 'International Journal of Recent Trends in Multidisciplinary Research',
            },
            {
                id: 4,
                image: require('../../assets/images/ijcse-nlogo.png'),
                category: 'INDJCST',
                title: 'Indian Journal of Computer Science and Technology',
            },
            {
                id: 5,
                image: require('../../assets/images/ijeee-nlogo.png'),
                category: 'INDJEEE',
                title: 'Indian Journal of Electrical and Electronics Engineering',
            },
            {
                id: 6,
                image: require('../../assets/images/ijece-nlogo.png'),
                category: 'INDJECE',
                title: 'Indian Journal of Electronics and Communication Engineering',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            930: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'left',
            },
        },
    }),
})
</script>

<style scoped>
.caseStudyTitle {
    text-align: left;
}
/* .caseStudyButton {
    text-align: right;
} */

</style>
