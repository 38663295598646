<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Peer Review Policy" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJECE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row pt-4 gy-4">
                <h3>Peer review policy :</h3>
                <div class="peer-review mt-2">
                  <div class="ps-3">
                    <p>
                      Indian journal of electronics and communication engineering (INDJECE)
                       adopts a review process. Once
                      submitted, a paper dealing with suitable topics will be
                      sent to the editor-in-chief and/or associate editor, and
                      then be reviewed by at least two experts in the relevant
                      field. The reviewers are either members of our editorial
                      board or special external experts invited by the journal.
                      In light of the reviewers’ comments, the editor-in-chief
                      and/or associate editor will make the final decision over
                      the publication, and the editor-in-chief and/or associate
                      editor return the decision to the author.
                    </p>
                    <p>
                      There are four possible decisions concerning the paper:
                      acceptance, minor revision, major revision, and rejection.
                      Acceptance means the paper will be published directly
                      without any revision. Minor revision means the author
                      should make minor changes to the manuscript according to
                      reviewers’ comments and submit the revised version to
                      INDJECE. The revised version will be accepted or rejected at
                      the discretion of the editor-in-chief and/or associate
                      editor. Major revision means that the author should modify
                      the manuscript significantly according to reviewers’
                      comments and submit the revised version to INDJECE. The
                      revised version will be accepted or rejected at the
                      discretion of the editor-in-chief and/or associate editor.
                      Rejection means the submitted paper will not be published.
                    </p>
                    <p>
                      If a paper is accepted, the editor-in-chief and/or
                      associate editor will send an acceptance letter to the
                      author and ask the author to prepare the paper in MS Word
                      using the template of INDJECE.
                    </p>
                  </div>
                </div>
                <div class="plagiarism">
                  <h5>Plagiarism policy :</h5>
                  <div class="ps-3">
                    <p>
                      Plagiarism is committed when one author uses another work
                      without permission, credit, or acknowledgement. Plagiarism
                      takes different forms, from literal copying to
                      paraphrasing the work of another. Any allegations of
                      plagiarism made to a journal will be investigated by the
                      editor-in-chief or managing editor. If the allegations
                      appear to be founded, we will request all named authors of
                      the paper to explain of the overlapping material. If the
                      explanation is not satisfactory, we will reject the
                      submission, and may also reject future submissions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjece" },
        { id: 2, name: "Aim & Scope", to: "/indjece/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjece/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjece/guidance-for-ems"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjece/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjece/current-issue" },
        { id: 7, name: "Archives", to: "/indjece/archives" },
        { id: 8, name: "Editorial Board", to: "/indjece/editorial-board" },
        { id: 9, name: "Topics", to: "/indjece/topics" },
        { id: 10, name: "Call for papers", to: "/indjece/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjece/instruction-for-authors",
        },



        { id: 12, name: "Ethics & Policies", to: "/indjece/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjece/indexing-abstracting" },
        { id: 14, name: "Impact Factor", to: "/indjece/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjece/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjece/downloads" },
        { id: 17, name: "FAQs", to: "/indjece/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjece/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjece/indjece-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}
h5 {
  font-size: 18px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}

</style>
