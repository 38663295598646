<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Blog Right Sidebar" />
        <BlogTwoContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogTwoContent from '../BlogTwo/BlogTwoContent'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        BlogTwoContent,
        Footer,
    }
}
</script>