<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Download Forms" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
           <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table" > 
              <div class="" style="text-align:center;padding-top: 80px;"> 
             <h6> Manuscript Template</h6>
             <button @click="getManuScript(this.ManuScriptUrl)" class="btn m-2">Download</button>
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table">
              <div  style="text-align:center;padding-top: 80px;"> 
                <h6 class="justify content center"> Copyright Form</h6>
             <button @click="getCopyRightForm(this.CopyRightFormUrl)" class="btn m-2">Download</button> 

              </div>
        
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle
  },
  data: function () {
    return {
      ManuScriptUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/1/manuscripttemplate/IJIRE-Manuscript-Template-new.docx',
      CopyRightFormUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/1/copyrightform/IJIRE_Registration+%26Copyright-Form.pdf',
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
    };
  },
  methods: {
    getManuScript: function(getUrl) {
      window.open(getUrl, '_blank')
    },
    getCopyRightForm: function(getForm) {
      window.open(getForm, '_blank')
    }
  }
};
</script>
<style scoped>
.indexingImage  {
  height: 250px;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.indexingImage {
 margin:10px;
 
}
.btn {
  color:#0000ff;
  border:1px solid #0000ff ;
  background-color: #fff;
}
.btn:hover {
  color:#fff;
  background-color: #0000ff;
}
</style>