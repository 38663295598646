<template>
  <div>
     <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJRTMR</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=3">
                <button class="default-btn mr-3">Submit Your Article<span></span></button>
              </a>
                </div>
              </div>
              <div class="row gy-5 pt-4">
                <div class="about-the-journal">
                  <h3>Welcome To IJRTMR :</h3>
                  <div class="ps-3">
                    <p>
                    </p>
                    <p>
                      International Journal of Recent Trends in Multidisciplinary Research (IJRTMR) is an Online,
                      Bi-Monthly International Journals for the Publication of New Ideas Based by Academicians,
                      Educationists, Engineers, And Corporate People. The Research Results And Fundamental Advancement Are
                      All Aspects Of Innovative Research In Engineering & Technology And Various Engineering Disciplines;
                      Etc. IJRTMR Is A Scholarly Open Access, Peer - Review Journal Which Helps Academic
                      Person As Well As Student Community. IJRTMR Provides The Academic Community And Industry For The
                      Submission Of Original Research And Applications Described To Various Engineering Field And
                      Technologies.
                    </p>
                  </div>
                </div>

                <div class="journal-particular mt-3">
                  <h5>Journal particulars :</h5>
                  <div class="row">
                    <div class="pb-2">
                      <p class="ps-3">
                      </p>
                    </div>

                    <div class="col-6">
                      <ul>
                        <li class="pb-2">
                          <p>
                            <b>Title :</b>International Journal Of Recent Trends
                            In Multidisciplinary Research
                          </p>
                        </li>
                        <li class="pb-2">
                          <p><b>Frequency :</b>Bi-Monthly</p>
                        </li>
                        <li class="pb-2">
                          <p><b>ISSN :</b> 2583-0368</p>
                        </li>
                        <li class="pb-2">
                          <p>
                            <b> Publisher :</b> Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li class="pb-2">
                          <p>
                            <b>Editor-in-Chief :</b> Dr.S.Sivaganesan (Professor
                            & Head)
                          </p>
                        </li>
                        <li class="pb-2">
                          <p>
                            <b>Copyright :</b> Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li class="pb-2">
                          <p><b>Starting Year :</b> 2021</p>
                        </li>
                        <li class="pb-2">
                          <p><b>Subject :</b> Engineering</p>
                        </li>
                      </ul>
                    </div>

                    <div class="col-6">
                      <ul>

                        <li class="pb-2">
                          <p><b>Language :</b> English</p>
                        </li>
                        <li class="pb-2">
                          <p><b>Publication Format :</b> Online</p>
                        </li>
                        <li class="pb-2">
                          <p><b>Email Id :</b> editorinchief@ijrtmr.com</p>
                        </li>
                        <li class="pb-2">
                          <p><b>Mobile No :</b> +91 9840521421</p>
                        </li>
                        <li class="pb-2">
                          <p><b>Website :</b> www.ijrtmr.com</p>
                        </li>
                        <li class="pb-2">
                          <p>
                            <b>Address :</b> Fifth Dimension research
                            Publication (P) Ltd. No: 38D5F, Rajaji nagar,
                            Ariyalur-621713. Tamilnadu, India.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="container mt-1"> 
                  <h5 class="pb-2">Why Choose IJRTMR Journal :</h5>
                  <div class="row"> 
               <div class="col-lg-6 col-md-12 col-sm-12"> 
                <ul> 
                  <li><p>Peer reviewed refereed Journal</p></li>
                  <li><p>Easy, fast, transparent paper publication process.</p></li>
                  <li><p>Registered with ministry of MSME, Govt. of India.</p></li>
                  <li><p>Open Access Journal</p></li>
                  <li><p>Strict Plagiarism policy</p></li>
                  <li><p>Soft copy of certificate just after the publication of paper</p></li>
                  <li><p>Simple steps for publication of research and review articles.</p></li>
                </ul>
               </div>
               <div class="col-lg-6 col-md-12 col-sm-12"> 
            <ul> 
             
                  <li><p>IJRTMR promotes "GO GREEN" by providing e-publication.</p></li>
                  <li><p>High Visibility & Indexing</p></li>
                  <li><p>Published by: 5th Dimesion Research Publication</p></li>
                  <li><p>Publication Ethics: COPE's best practice guidelines.</p></li>
                  <li><p>Publisher license under: Creative commons by NC ND</p></li>
            </ul>
     
               </div>
                </div>
          </div>
          <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=3">
                <button class="default-btn mr-3">Submit Your Article<span></span></button>
              </a>
                </div>
              </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Ijrtmr",
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijrtmr" },
        { id: 2, name: "Aim & Scope", to: "/ijrtmr/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijrtmr/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijrtmr/GuidanceForEms-Ijrtmr"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijrtmr/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijrtmr/current-issue" },
        { id: 7, name: "Archives", to: "/ijrtmr/archives" },
        {
          id: 8,
          name: "Conference",
          to: "/ijrtmr/conference",
        },
        { id: 9, name: "Editorial Board", to: "/ijrtmr/editorial-board" },
        { id: 10, name: "Topics", to: "/ijrtmr/topics" },
        { id: 11, name: "Call for papers", to: "/ijrtmr/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijrtmr/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijrtmr/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijrtmr/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijrtmr/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijrtmr/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijrtmr/downloads" },
        { id: 18, name: "FAQs", to: "/ijrtmr/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijrtmr/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijrtmr/ijrtmr-contact",
        },
      ],
    };
  },
};
</script>
  
<style scoped lang="scss">
.containerbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 120px 50px 120px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;

  .imageBox {
    width: 120px;
    height: auto;
    margin-right: 50px;
    border-radius: 5px;
  }
}

@media screen and (max-width:1199px) {
  .containerbox {
    flex-direction: column;

    .imageBox {
      margin-bottom: 50px;
    }

    .contentBox {
      margin-left: 0px !important;
    }
  }
}</style>
  
