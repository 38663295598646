<template>
    <div>
      <Navbar />
      <PageTitle pageTitle="Submit Your Article's" parentPage="Fifth Dimension Research Publication" />
      <div class="pricing-area-two pt-50 pb-70 bg-f4f7fe">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>IJIRE</h3>
                </div>
  
                <div class="price">
                  <p>
                    International Journal of Innovative Research in Engineering
                  </p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="IJIRELOGO" class="journalImage"/>
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">2582-8746</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">
                        Bimonthly(Feb,Apr,Jun,Aug, Oct,Dec)
                      </p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=1"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/ijire">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>IJSREAT</h3>
                </div>
  
                <div class="price">
                  <p>
                    International Journal of Scientific Research in Engineering &
                    Technology
                  </p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="IJRETLOGO" class="journalImage"/>
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">2583-1240</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">
                        Bimonthly(Feb,Apr,Jun,Aug, Oct,Dec)
                      </p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=2"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/ijsreat">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>IJRTMR</h3>
                </div>
  
                <div class="price">
                  <p>
                    International Journal of Recent Trends in Multidisciplinary
                    Research
                  </p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="IJRTMRLOGO" class="journalImage" />
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">2583-0368</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">
                        Bimonthly(Feb,Apr,Jun,Aug, Oct,Dec)
                      </p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=3"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/ijrtmr">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>INDJEEE</h3>
                </div>
  
                <div class="price">
                  <p>Indian Journal of Electrical and Electronics Engineering</p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="IJEEELOGO" class="journalImage" />
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">Applied</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=4"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/indjeee">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>INDJECE</h3>
                </div>
  
                <div class="price">
                  <p>
                    Indian Journal of Electronics and Communication Engineering
                  </p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="IJECELOGO" class="journalImage"/>
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">Applied</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=5"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/indjece">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-sm-6">
              <div class="single-pricing-table center">
                <div class="pricing-header">
                  <h3>INDJCST</h3>
                </div>
  
                <div class="price">
                  <p>Indian Journal of Computer Science and Technology</p>
                </div>
  
                <ul class="pricing-features row">
                  <div class="col-md-5">
                    <img :src="INDCSTLOGO" class="journalImage" />
                  </div>
                  <div class="col-md-7 data-cols">
                    <li>
                      e-ISSN:
                      <p class="title-content">2583-5300</p>
                    </li>
                    <li>
                      Product type:
                      <p class="title-content">Journals</p>
                    </li>
                    <li>
                      Published By:
                      <p class="title-content">
                        Fifth Dimension Research Publication
                      </p>
                    </li>
                    <li>
                      Publication Month:
                      <p class="title-content">3 Issues Per Year (Apr,Aug,Dec)</p>
                    </li>
                    <li>
                      Mode:
                      <p class="title-content">Online</p>
                    </li>
                  </div>
                </ul>
  
                <div class="btn-box">
                  <a class="default-btn" href="http://editorial.fdrpjournals.org/login?journal=6"> SUBMIT YOUR ARTICLE
                    <span></span></a>
                  <!-- <router-link class="default-btn" to="/indjcst">
                    SUBMIT YOUR ARTICLE
                    <span></span>
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Shape Images -->
        <div class="shape-img2">
          <img src="../../assets/images/shape/shape-2.svg" alt="image" />
        </div>
        <div class="shape-img3">
          <img src="../../assets/images/shape/shape-2.svg" alt="image" />
        </div>
        <div class="shape-img4">
          <img src="../../assets/images/shape/shape-4.png" alt="image" />
        </div>
        <div class="shape-img5">
          <img src="../../assets/images/shape/shape-5.png" alt="image" />
        </div>
        <div class="shape-img6">
          <img src="../../assets/images/shape/shape-6.png" alt="image" />
        </div>
        <div class="shape-img9">
          <img src="../../assets/images/shape/shape-9.png" alt="image" />
        </div>
        <div class="shape-img10">
          <img src="../../assets/images/shape/shape-10.png" alt="image" />
        </div>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../Layout/Navbar.vue"
  import Footer from "../Layout/Footer";
  import PageTitle from "../Common/PageTitle.vue";
  import INDCSTLOGO from "../../assets/images/ijcse-nlogo.png"
  import IJIRELOGO from "../../assets/images/ijire-nlogo.png";
  import IJRETLOGO from "../../assets/images/ijret-nlogo.png";
  import IJRTMRLOGO from "../../assets/images/ijrtmr-nlogo.png";
  import IJEEELOGO from "../../assets/images/ijeee-nlogo.png";
  import IJECELOGO from "../../assets/images/ijece-nlogo.png";
  
  export default {
    name: "OurAffordablePricingPlans",
    components: {
      Navbar,
      Footer,
      PageTitle,
    },
    data: function () {
      return {
        INDCSTLOGO,
        IJIRELOGO,
        IJRETLOGO,
        IJRTMRLOGO,
        IJEEELOGO,
        IJECELOGO,
      };
    },
  };
  </script>
  
  <style scoped>
  .journalImage {
      width: 270px;
      height: 200px;
      padding-bottom: 10px;
  }
  .single-pricing-table .pricing-features img {
  border-radius: 0px;
  }
  </style>
