<template>
    <div>
      <Navbar />
      <PageTitle pageTitle="Editorial Board"/>
      <div class="journal-page-area pb-100 " style="padding-top: 50px;">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-lg-3">
              <div class="menu-wrapper">
                <div class="menu-header">
                  <h4>IJSREAT</h4>
                </div>
                <ul class="no-style">
                  <li v-for="sublink in links" :key="sublink.id">
                    <i class="fas fa-chevron-right"></i>
                    <router-link :to="sublink.to">
                      {{ sublink.name }}
                      <span></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-9 col-md-9 mt-4 ps-4">
              <div class="containers" style="display: flex; flex-direction: row; margin-bottom: 20px;"> 
          <div class="editorialBox p-4" style=" margin-right: 2%; width: 49%;"> 
            <h6>Patron/Editor-in-chief :</h6>
            <p><b>Name:</b>  Dr. S. Sivaganesan</p>
            <p>
              <b>Designation:</b>  Professor& Head Department of Electrical &
              Electronics Engineering.
            </p>
            <p>
              <b>Address:</b>  Holymary Institute of Technology &
              Science.Hyderabad-501301.India
            </p>
            <p>
              <b>E-Mail:</b>    editorinchief@ijsreat.com,<br>
                        sivaganesan@hmgi.ac.in
            </p>
          </div>
          <div class="editorialBox p-4" style=" width: 49%;"> 
            <h6>Managing Editor:</h6>
          <p><b>Name:</b>  Dr. S. Thanga Revathi</p>
          <p><b>Designation:</b>  Assistant Professor Department of Computer Science and Engineering</p>
          <p><b>Address:</b>  SRM Institute of Science and Technology, Chennai-603203. India.</p>
          <p><b>E-Mail:</b>  thangars@srmist.edu.in</p>
          </div>
         </div>

         <div class="row editorialBox my-2 mx-1" v-for="(data, index) in membersList" :key="(index)">
          <div class="col-lg-3 col-md-12 col-sm-12 p-3 editorialImage">
            <img :src="data.member_image_url" alt="img"  />
          </div>
          <div class="col p-3">
            <h6>{{ data.member_role }}</h6>
              <p><b>Name:</b>{{ data.member_name }}</p>
              <p><b>Designation:</b> {{ data.member_designation }}</p>
              <p><b>Address:</b> {{ data.member_address }}</p>
              <p><b>E-mail:</b> {{ data.member_email }}</p>
            <p><b>Research Area:</b>{{ data.member_researcharea }}</p>
            <p><b>Profile Link: </b>{{ data.member_website }}</p>
            <p><b>Country:</b> {{ data.member_country }}</p>
          </div>
         </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../../Layout/Navbar.vue"
  import Footer from "../../Layout/Footer";
  import PageTitle from "../../Common/PageTitle";
  export default {
    name: "ProjectsDetailsContent",
    components: {
       Navbar,
      Footer,
      PageTitle,
    },
    data: function () {
      return {
        links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        {
          id: 8,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 9, name: "Topics", to: "/ijsreat/topics" },
        { id: 10, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 12,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 16,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 17, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
      membersList:[]
      };
    },
    methods:{
    getMemberData() {
      this.api.getData("editorial/3","journals/v1/").then(
        (res) => {
          this.membersList = res.data.membersList;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getMemberData();
  },
  };
  </script>
  <style scoped lang="scss">
  h3 {
    font-size: 24px;
  }
  .titleMail{
    margin-left: -5px;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
   
  }

  ul li {
    list-style: none;
  }
  
  .mission-of-journal ul li {
    margin-left: -12px;
  }

.contact-address ul li h6 span {
  padding:8px;
  color:#0000ff;
  background: white;
  border-radius: 50px;
  margin:10px;
  font-size: 15px;
}
.contact-address ul li h6  {
  color:white;
}

.numbers {
  margin-left: 50px;
}



.contact-address {
  background: #0000ff;
  margin: 10px;
  color: aliceblue;
  border-radius: 20px;
}

.email {
  margin-left: 50px;
  
}
.email p{
  color:white;
}

.editorialBox {
    /* border: 2px solid #EEEDE7; */
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p{
    text-align:left;
    margin-bottom: 5px;
    
}
.editorialImage img {
  height: 200 !important;
}
.imageBox {
  width: 70%;
  height: auto;
  margin-top: 30px;
}
@media screen and (max-width:992px){
  .containers{
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
  </style>
  
