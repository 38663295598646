<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-1.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">Sarah Taylor</router-link></li>
                                            <li>June 24, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">How To Boost Your Digital Marketing Agency</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-2.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">James Anderson</router-link></li>
                                            <li>June 26, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">The Rise Of Smarketing And Why You Need It</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-3.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">Steven Smith</router-link></li>
                                            <li>June 25, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">How To Use Music To Boost Your Business</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-4.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">Sarah Taylor</router-link></li>
                                            <li>June 24, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">Creative solutions to improve your business!</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-5.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">James Anderson</router-link></li>
                                            <li>June 26, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">Finding the human in technology</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-6.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">Steven Smith</router-link></li>
                                            <li>June 25, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">Ideas people want to spend time with</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-7.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">Sarah Taylor</router-link></li>
                                            <li>June 24, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">Make the customer the hero of your story</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post">
                                <div class="post-image">
                                    <router-link to="/blog-details"><img src="../../assets/images/blog/blog-8.jpg" alt="image"></router-link>
                                </div>

                                <div class="post-content">
                                    <div class="post-meta">
                                        <ul>
                                            <li>By: <router-link to="/blog-details">James Anderson</router-link></li>
                                            <li>June 26, 2020</li>
                                        </ul>
                                    </div>
                                    
                                    <h3><router-link to="/blog-details">Soaring above the rest to provide the best</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <router-link to="/blog-details" class="read-more-btn">Read More <i class="flaticon-right-arrow"></i></router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <a href="/blog-two" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                                <a href="/blog-two" class="page-numbers">1</a>
                                <span class="page-numbers current" aria-current="page">2</span>
                                <a href="/blog-two" class="page-numbers">3</a>
                                <a href="/blog-two" class="page-numbers">4</a>
                                <a href="/blog-two" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <BlogSidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BlogSidebar from '../BlogTwo/BlogSidebar'
    export default {
        name: 'blogtwocontent',
        components: { 
            BlogSidebar
        }
    }
</script>