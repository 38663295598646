<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Contact Us" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-5">
            <div
              class="containers"
              style="display: flex; flex-direction: row; margin-bottom: 20px"
            >
              <div
                class="editorialBox p-4"
                style="margin-right: 2%; width: 49%"
              >
                <p>
                  <b>Publisher:</b> Fifth Dimension research Publication (P)
                  Ltd. No: 38D5F, Rajaji nagar, Ariyalur-621713. Tamilnadu,
                  India.
                </p>
                <p><b>Website:</b>www.fdrpjournals.org</p>
                <p><b>Phone : </b>+91 9840521421</p>
                <p><b>Email : </b><i>fdrpjournals@gmail.com</i></p>
              </div>
              <div class="editorialBox p-4" style="width: 49%">
                <p>
                  <b>Editor-in-chief:</b>
                </p>
                <p>
                  <b>Address :</b>Dr. S. Sivaganesan, House No: 55-E, Kamarajar
                  Nagar, 2nd Street, Ariyalur-621713. Tamil Nadu, India.
                </p>

                <p><b>Phone :</b> +91 98405 21421</p>
                <p>
                  <b>Email : </b
                  ><i
                    >editorinchief@theijire.com, editorinchiefijire@gmail.com</i
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.editorialBox {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media screen and (max-width: 992px) {
  .containers {
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
</style>