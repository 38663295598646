<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Paper Submission" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8 pt-4 col-md-8">
            <div class="container">
            <!-- ================================================Form======================================================================== -->
            <h3 class="headingThree">
                  Submit your Article by Using
              </h3>
              <p>FDRP-Editorial management system </p>
              <a href="http://editorial.fdrpjournals.org/login?journal=2">
                <button class="default-btn mr-3">Submit Your Paper<span></span></button>
              </a>
              <div class="mission-container mt-5">
                  <h5>Submitting Manuscript/Paper is very easy process:</h5>
                  <div class="ps-3">
                    <p>Create <b>Account</b>by using Author <b>Name, email ID, Mobile No.</b> Once Account created, author can enter directly Login Credential. After that, By Using New Submission, author can submit Article.</p>
                  </div>
                  <div class="ps-3">
                    <p><b class="text-danger">Note:</b> Make sure are you entered correct email ID, Mobile No. because we will send Notification registered Email, Mobile No.</p>
                  </div>
                  <div class="ps-3">
                    <p>While Submitting Article, author can choose below Details:</p>
                  </div>
                  <div class="row">
                    <div class="col">
                      <ul>
                        <li><p>Research Area</p></li>
                        <li><p>Mode of Process</p></li>
                        <li><p>Type of article</p></li>
                        <li><p>Type of Issues</p></li>
                      </ul>
                      <b>Repeat users:</b><p>Please click the "Login" button.</p>
                      <b>Authors:</b><p>Please click the "Login" button to the system as "Author." You may then submit your manuscript and track its progress through the system.</p>
                      <b>Reviewers:</b><p>Please click the "Login" button from the menu above and login to the system as "Reviewer." You may then view and/or download manuscripts assigned to you for review or submit your comments to the editor and the authors.</p>
                       <b>To change your username and/or password:</b><p>Once you are registered, you may change your contact information, username and/or password at any time. Simply log in to the system and click on "Profile" in the navigation bar at the left of the page.</p>
                    </div>
                  </div>
              </div>
            <!-- <a href="http://editorial.fdrpjournals.org/login?journal=2">http://editorial.fdrpjournals.org/login?journal=2</a> -->

            <!-- <div class="p-4 border border-blacks">
              <h5 style="padding-bottom: 20px">Submit your manuscript :</h5>
              <form @submit.prevent="Onsubmit">
                <div class="container row">
                  <div class="row mb-3">
                    <label for="authorName" class="col col-form-label"
                      >Name of the corresponding author :</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="authorName"
                      placeholder="Author name"
                      v-model="userData.authorName"
                    />
                  </div>
                  <div class="row mb-3">
                    <label for="paperTitle" class="col col-form-label"
                      >Title of the paper</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="paperTitle"
                      placeholder="Paper title"
                      v-model="userData.paperTitle"
                    />
                  </div>

                  <div class="row mb-3">
                    <label for="emailId" class="col col-form-label"
                      >Email address :</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="emailId"
                      placeholder="Email"
                      v-model="userData.email"
                    />
                  </div>

                  <div class="row mb-3">
                    <label for="contactNumber" class="col col-form-label"
                      >Contact number :</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="contactNumber"
                      placeholder="Contact number"
                      v-model="userData.number"
                    />
                  </div>

                  <div class="row mb-3">
                    <label for="state" class="col col-form-label"
                      >State :</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="state"
                      placeholder="state"
                      v-model="userData.state"
                    />
                  </div>

                  <div class="row mb-3">
                    <label for="country" class="col col-form-label"
                      >Country :</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      placeholder="country"
                      v-model="userData.country"
                    />
                  </div>

                  <div class="row mb-3">
                    <label
                      for="exampleFormControlFile1"
                      class="col col-form-label"
                      >Upload paper :
                      <span style="color: red !important; font-size: 12px"
                        >(only docs/doc file) *</span
                      ></label
                    >

                    <input type="file" @change="previewFiles" multiple/>
                  </div>

                  <div class="container mb-3 mt-3" style="display: flex; justify-content: center;">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                  <div class="end">
                    <h6>Are you getting any problem in paper uploading ?</h6>
                    <p>
                      Don't worry please submit / email research paper at
                      <span class="text-success"
                        ><i>editorinchief@ijrtmr.com</i>
                      </span>
                    </p>
                  </div>
                </div>
              </form>
              </div> -->
            </div>
            <!-- ===================================================End of the Form =========================================================================== -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      userData: {
        authorName: "",
        paperTitle: "",
        email: "",
        number: "",
        state: "",
        country:"",
      },
      
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        {
          id: 8,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 9, name: "Topics", to: "/ijsreat/topics" },
        { id: 10, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 12,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 16,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 17, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
  methods: {
   previewFiles(event) {
      console.log(event.target.files);
   },
   Onsubmit : function() {
      console.log('submitted')
    }
}
};
</script>

<style scoped>
h3 {
  font-size: 24px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
.border-blacks {
  border-radius: 10px;
box-shadow: rgb(0 0 0 / 10%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px;
}

label {
  margin-left: -12px;
}

.end p {
  line-height: 1rem;
}
</style>
