<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Call for Papers" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4">
                <div class="call-for-paper">
                  <h3 class="headingThree">Call for Papers :</h3>
                  <div class="ps-3">
                    <p>
                      International Journal of Innovative Research in Engineering (IJIRE) is having ISSN: 2582-8746
                      (online), Bi-Monthly international journal by 5th Dimension Research Publication.
                    </p>
                    <p>
                      It is an academic, online, open access, Peer-reviewed international journal. It aims
                      to publish original, theoretical, and practical advances in Computer Science & Engineering,
                      Information Technology, Electrical and Electronics Engineering, Electronics and Telecommunication,
                      Mechanical Engineering, Civil Engineering, Textile Engineering, and all interdisciplinary streams of
                      Engineering Sciences. The board of committee of IJIRE will review all submitted papers.
                    </p>
                    <p>
                      Papers for the regular issues of the Journal can be submitted, around the year, electronically at
                      editorinchief@theijire.com. After the final acceptance of the paper, based upon the detailed review
                      process, it will immediately be published online. However, the assignment of the report to a
                      specific Volume / Issue (see archive) of the Journal will be taken up by the Editorial Board later;
                      and the author will be intimated accordingly. For Theme Based Special Issues, time-bound Special
                      Calls for Papers will be informed, and the same will only apply to that specific issue.
                    </p>
                  </div>
                </div>

                <div class="current-issue">
                  <h5>Important deadlines for current issue :</h5>
                  <div class="ps-3">
                    <p>
                      <b>Last Date for Manuscript Submission: 25th of every
                        Bimonthly (Feb,Apr,Jun,Aug,Oct,Dec).</b>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <p>
                        Last Date for Manuscript Submission: 25th of every Bimonthly (Feb, Apr, Jun, Aug, Oct, Dec).
                      </p>
                    </li>
                    <li>
                      <p>
                        Please submit your paper with a signed Copyright Transfer Form (the corresponding author signs
                        enough, no need at all), and it should be sent while submitting the paper to us.
                      </p>
                    </li>
                    <li>
                      <p>
                        The manuscripts which are submitted with copyrights form only will forward to the review process.
                      </p>
                    </li>
                    <li>
                      <p>
                        Submitting the paper in multiple journals is offensive, don’t waste our valuable time.
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="steps mt-2">
                  <h5>
                    For the author’s convenience, we strongly follow the following steps.
                  </h5>

                  <ul>
                    <li>
                      <p>The response will be given within 12 hours.</p>
                    </li>
                    <li>
                      <p>The paper will be reviewed within Two days.</p>
                    </li>
                    <li>
                      <p>
                        The author will get their Acceptance/Rejection Notification within three days of submitting the
                        paper.
                      </p>
                    </li>
                    <li>
                      <p>
                        All the accepted papers will be open and accessible with a full PDF download.
                      </p>
                    </li>
                    <li>
                      <p>
                        All Authors will get a soft copy of the certificate, acceptance letter, and reviewer evaluation
                        grading.
                      </p>
                    </li>
                  </ul>
                  <div class="ps-3">
                    <h6>
                      <b>Submit your article :</b><span class="text-success"><i>editorinchief@theijire.com</i></span>
                      (or) Online submission
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
    };
  },
};
</script>
<style scoped></style>
  