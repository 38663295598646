<template>
  <div>
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=1">
                    <button class="default-btn">Submit your Article<span></span></button>
                  </a>
                </div>
              </div>
              <div class="row gy-4 pt-4 journal-home">
                <h3 class="headingThree">
                   Welcome To IJIRE :
                </h3>
                <div>
                  <div class="ps-3">

                    <p>
                      International Journal of Innovative Research in Engineering (IJIRE) is [ISSN-2582-8746] Online
                      Journal Publishing Site, a Bi-monthly international journal for publishing new ideas founded by
                      academicians, educationists, engineers, and corporate people. The research results and fundamental
                      improvement are all elements of Innovative Research in Engineering & Technology, various engineering
                      fields, etc. IJIRE is a scholarly open, accessible, Peer Review Journal that helps
                      academic persons and the student community. IJIRE provides the academic community and industry for
                      offering original research and applications related to Various Engineering fields and Advanced
                      Technologies.
                    </p>
                    <p>
                      The journal aims to be an open-access, free-to-read academic journal that publishes peer-reviewed
                      original research papers and technical notes. It covers all areas of engineering, technology, and
                      applied science and publishes submissions for submission in any field of engineering and technology.
                      All submitted papers should report actual, previously unpublished research results, experimental or
                      theoretical, and will be peer-reviewed. Articles submitted to the journal must meet
                      these measures, which are as follows:
                    </p>
                    <p>To promote the publication of original, novel, industry-relevant research and to solicit original
                      and unpublished papers based on theoretical or experimental works.</p>
                    <p>
                      Papers for the journal's regular issues can be submitted, around the year, electronically (on-line
                      submission) at Manuscript submission (or)
                      <i>editorinchief@theijire.com/ editorinchiefijire@gmail.com.</i>
                      After the final acceptance of the paper, based upon the detailed review process, the report will
                      immediately be published online.
                    </p>
                  </div>
                </div>
                <div class="particulars">
                  <h5>Journal particulars :</h5>
                  <div class="row">
                    <div class="col">
                      <ul>
                        <li>
                          <p>
                            <b>Title :</b>International Journal of Innovative
                            Research in Engineering
                          </p>
                        </li>
                        <li>
                          <p><b>Frequency :</b>Bi-Monthly</p>
                        </li>
                        <li>
                          <p>ISSN :<b></b>2582-8746</p>
                        </li>
                        <li>
                          <p>
                            <b>Publisher :</b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b> Editor-in-Chief :</b> Dr.S.Sivaganesan
                            (Professor & Head)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Copyright : </b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p><b>Starting Year :</b>2020</p>
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <ul>
                        <li>
                          <p><b>Subject : </b>Engineering</p>
                        </li>
                        <li>
                          <p><b>Language :</b>English</p>
                        </li>
                        <li>
                          <p><b>Publication Format :</b>Online</p>
                        </li>
                        <li>
                          <p><b>Email Id : </b>editorinchief@theijire.com</p>
                        </li>
                        <li>
                          <p><b>Mobile No :</b>+91 9840521421</p>
                        </li>
                        <li>
                          <p><b>Website : </b>www.theijire.com</p>
                        </li>
                        <li>
                          <p>
                            <b>Address :</b>Fifth Dimension research Publication
                            (P) Ltd. No: 38D5F, Rajaji nagar, Ariyalur-621713.
                            Tamilnadu, India.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                  <div class="container pt-3"> 
                    <h5>Why Choose IJIRE Journal :</h5>
                    <div class="row pt-3"> 
                      <div class="col-lg-6 col-md-12 col-sm-12"> 
                        <ul> 
                          <li><p><b>ISSN:</b>2582-8746 (Online)</p></li>
                          <li><p><b>Publishing Frequency:</b>6 Issue per year (Bi-Monthly)</p></li>
                          <li><p>IJIRE is Peer reviewed refereed Journal</p></li>
                          <li><p>Easy, fast, transparent paper publication process.</p></li>
                          <li><p>Soft copy of certificate just after the publication of paper.</p></li>
                          <li><p>IJIRE registered with Ministry of MSME, Govt. of India.</p></li>
                          <li><p>Open Access Journal (No Subscription required )</p></li>
                          <li><p>Strict Plagiarism policy</p></li>
                          <li><p>Simple steps for publication of research and review articles.</p></li>
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-12 col-sm-12"> 
                        <ul> 
                          <li><p>Authors can submit the paper at any time by online submission.</p></li>
                          <li><p>Author’s queries shall be resolved within 24 hours</p></li>
                          <li><p>IJIRE promotes "Go Green" by providing e-publication.</p></li>
                          <li><p>High Visibility & Indexing</p></li>
                          <li><p>Published By: 5th Dimension Research Publication</p></li>
                          <li><p>Publication Ethics: COPE’s Best Practice Guidelines</p></li>
                          <li><p>Publisher License under: Creative Commons By NC ND</p></li>
                        </ul>
                      </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=1">
                    <button class="default-btn">Submit your Article<span></span></button>
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsDetailsContent",
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.containerbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 120px 50px 120px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;

  .imageBox {
    width: 120px;
    height: auto;
    margin-right: 50px;
    border-radius: 5px;
  }
}

@media screen and (max-width:1199px) {
  .containerbox {
    flex-direction: column;

    .imageBox {
      margin-bottom: 50px;
    }

    .contentBox {
      margin-left: 0px !important;
    }
  }
}
</style>


