<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Call For Paper" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJRTMR</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row pt-4 gy-4">
                <div class="call-for-paper">
                  <h3>Call For Paper :</h3>
                  <div class="ps-3">
                    <p>
                      International Journal Of Recent Trends In Multidisciplinary Research (IJRTMR) is having ISSN: 2583 -
                      0368 (online), Bi-Monthly international journal by 5th Dimension Research Publication.
                    </p>
                    <p>
                      It is an academic, online, open access, Peer-reviewed international journal. It aims
                      to publish original, theoretical, and practical advances in Computer Science & Engineering,
                      Information Technology, Electrical and Electronics Engineering, Electronics and Telecommunication,
                      Mechanical Engineering, Civil Engineering, Textile Engineering, and all interdisciplinary streams of
                      Engineering Sciences. The board of committee of IJRTMR will review all submitted papers.
                    </p>
                    <p>
                      Papers for the regular issues of the Journal can be submitted, around the year, electronically at
                      editorinchief@ijrtmr.com. After the final acceptance of the paper, based upon the detailed review
                      process, it will immediately be published online. However, the assignment of the report to a
                      specific Volume / Issue (see archive) of the Journal will be taken up by the Editorial Board later;
                      and the author will be intimated accordingly. For Theme Based Special Issues, time-bound Special
                      Calls for Papers will be informed, and the same will only apply to that specific issue.
                    </p>
                  </div>
                </div>
                <div class="current-issue">
                  <h5>Important Deadlines For Current Issue :</h5>
                  <div>
                    <ul>
                      <li>
                        <p>
                          Submit Your Paper In The Form Of Microsoft Word Format (.Doc Or . Docx); if You Used Latex,
                          Then Send It in PDF Format.
                        </p>
                      </li>
                      <li>
                        <p>
                           Submit Your Paper With a Signed Copyright Transfer Form (Corresponding Author Sign Enough, No
                          Need All), And it Should Be Sent While Submitting The Paper To Us.
                        </p>
                      </li>
                      <li>
                        <p>
                          If The Manuscript Is Not Accepted In Our Journal, Then
                          The Copyright Transfer Form Submitted With Us Will
                          Become Void.
                        </p>
                      </li>
                      <li>
                        <p>
                          The Manuscripts Which Are Submitted With Copyrights
                          Form Only Will Forward To Review Process
                        </p>
                      </li>
                      <li>
                        <p>
                          Submitting The Paper In Multiple Journals Is an Offence, Don’t Waste Our Valuable Time
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="steps mt-2">
                  <h5>
                    For Author’s Convenience, We Strongly Follow The Following
                    Steps.
                  </h5>
                  <div>
                    <ul>
                      <li>
                        <p> The response will be given within 12 hours.</p>
                      </li>
                      <li>
                        <p>The paper will be reviewed within Two days.</p>
                      </li>
                      <li>
                        <p>
                          The author will get their Acceptance/Rejection Notification within three days of submitting the
                          paper.
                        </p>
                      </li>
                      <li>
                        <p>
                          All the accepted papers will be open and accessible with a full PDF download.
                        </p>
                      </li>
                      <li>
                        <p>
                          All Authors will get a Soft copy of the certificate, acceptance letter, and reviewer evaluation
                          grading.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 class="ps-3">
                  Submit Your Article:<i class="text-success">editorinchief@ijrtmr.Com</i>
                  (Or) Online Submission
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijrtmr" },
        { id: 2, name: "Aim & Scope", to: "/ijrtmr/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijrtmr/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijrtmr/GuidanceForEms-Ijrtmr"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijrtmr/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijrtmr/current-issue" },
        { id: 7, name: "Archives", to: "/ijrtmr/archives" },
        {
          id: 8,
          name: "Conference",
          to: "/ijrtmr/conference",
        },
        { id: 9, name: "Editorial Board", to: "/ijrtmr/editorial-board" },
        { id: 10, name: "Topics", to: "/ijrtmr/topics" },
        { id: 11, name: "Call for papers", to: "/ijrtmr/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijrtmr/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijrtmr/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijrtmr/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijrtmr/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijrtmr/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijrtmr/downloads" },
        { id: 18, name: "FAQs", to: "/ijrtmr/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijrtmr/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijrtmr/ijrtmr-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
  
