<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
             Peer Review 
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p>
              Peer review is critical to maintaining the standards of our
              publication. Peer-reviewed articles are building blocks of a
              coherent and respected network of knowledge. For each paper
              entering the peer review process, at least two peer reviewers are
              selected, who must be independent from the authors and their
              institutions, able to evaluate the technical aspects of the paper,
              and available to evaluate the paper within the required period.
              The peer reviewers selected are expected to:
            </p>
            <ul>
              <li>
                <p>
                  Furnish An Objective And Detailed Evaluation Of The Paper, And
                  Contribute To The Decision-Making Process;
                </p>
              </li>
              <li>
                <p>
                  Keep Any Information Provided By The Editor Who Has Been
                  Assigned The Paper Confidential, Without Copying Or Keeping
                  The Manuscript;
                </p>
              </li>
              <li>
                <p>
                  Alert The Editor Who Has Been Assigned The Paper Any Content
                  That Has Been Published And That Is Highly Similar To The
                  Paper Being Reviewed;
                </p>
              </li>
              <li>
                <p>
                  Notify The Editor Who Has Been Assigned The Paper Any
                  Potential Conflict Of Interest;
                </p>
              </li>
              <li>
                <p>
                  Reason His/Her Evaluations, And Submit A Complete, Detailed
                  Report To The Editor Who Has Been Assigned The Paper; And
                </p>
              </li>
              <li>
                <p>
                  Commit To Evaluating The Paper As Quickly As Possible To Meet
                  The Deadlines, And Advise The Editor Who Has Been Assigned The
                  Paper Immediately About The Risk Of Failing To Finish The
                  Evaluation Within The Required Period.
                </p>
              </li>
              <p>
                Confidentiality is of paramount importance to the peer review
                process. Reviewers must maintain confidentiality of manuscripts.
                If a reviewer wishes to seek advice from colleagues while
                assessing a manuscript, he/she must consult with the editor and
                provide the names of any such colleagues to the journal with the
                final report.
              </p>
              <p>
                Regardless of whether a submitted manuscript is eventually
                published, correspondence with the journal, reviewers’ comments
                and other confidential material must not be published,
                disclosed, or otherwise publicised without prior written
                consent. Reviewers should be aware that it is our policy to keep
                their names confidential and that we do our utmost to ensure
                this confidentiality.
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>