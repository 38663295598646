<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            Terms and Conditions
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div>
              <p>
                It is a general principle of scholarly communication that the
                editor/guest editor of a learned journal/series is solely and
                independently responsible for deciding which articles submitted
                to the journal shall be published. In making this decision, the
                editor/guest editor is guided by the policies of the journal’s
                editorial board and constrained by such legal requirements in
                force regarding libel, copyright infringement, and plagiarism.
                An outcome of this principle is the importance of the scholarly
                archive as a permanent, historical record of the transactions of
                scholarship. Articles that have been published shall remain
                extant, exact, and unaltered as far as possible. However,
                occasionally, circumstances may arise where an article is
                published that must later be retracted or removed. Such actions
                must not be undertaken lightly and can only occur under
                exceptional circumstances, such as:
              </p>
            </div>
            <div>
              <h5 class="pt-3">Article Withdrawal :</h5>
              <p>
                Valid only for stories in the press that are draughts and
                occasionally have errors or may have inadvertently been
                published twice. The papers may occasionally, but less
                frequently, violate professional or ethical standards through
                numerous uploads, false authorship claims, plagiarism, the
                fraudulent use of data, or similar actions.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Article Retraction :</h5>
              <p>
                Infringements of professional, ethical codes, such as multiple
                submissions, bogus claims of authorship, plagiarism, fraudulent
                use of data, or the like. Occasionally a retraction will be used
                to correct errors in submission or publication.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Article Removal :</h5>
              <p>
                Legal limitations upon the publisher, copyright holder, or
                author(s).
              </p>
            </div>
            <div>
              <h5 class="pt-3">Article Replacement:</h5>
              <p>
                Identification of false or inaccurate data that, if acted upon,
                would pose a severe health risk.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Article withdrawal Policy :</h5>
              <p>
                International Journal of Innovative Research in Engineering
                (IJIRE) is (Online), Bi-monthly international journal for
                publishing new ideas founded by academicians, educationists,
                engineers, and corporate people. The research results and
                fundamental advancement are all aspects of Innovative Research
                in Engineering & Technology, various engineering disciplines,
                etc. IJIRE is a scholarly open, access, Peer Review
                Journal which helps an academic person and student community.
                IJIRE provides the academic community and industry for
                submitting original research and applications related to Various
                Engineering disciplines and Advanced Technologies. IJIRE
                recognizes the importance of the integrity and completeness of
                the scholarly record to researchers and librarians and attaches
                the highest importance to maintaining trust in the authority of
                its electronic archive.
              </p>
              <div>
                <h5 class="pt-3">IJIRE Policy :</h5>
                <p>
                  It is a general principle of scholarly communication that the
                  editor of a learned journal is solely and independently
                  responsible for deciding which articles submitted to the
                  journal shall be published. In making this decision, the
                  editor is guided by the policies of the journal’s editorial
                  board and constrained by such legal requirements in force
                  regarding libel, copyright infringement, and plagiarism. An
                  outcome of this principle is the importance of the scholarly
                  archive as a permanent, historical record of the transactions
                  of scholarship. Articles that have been published shall remain
                  extant, exact, and unaltered as far as possible. However,
                  occasionally, circumstances may arise where an article is
                  published that must later be retracted or removed. Such
                  actions must not be undertaken lightly and can only occur
                  under exceptional circumstances.
                </p>
                <p>
                  This policy has been designed to address these concerns and
                  consider current best practices in the scholarly and library
                  communities. As standards evolve and change, we will revisit
                  this issue and welcome the input of scholarly and library
                  communities. These issues require international standards, and
                  we will actively lobby various information bodies to establish
                  international standards and best practices that the publishing
                  and information industries can adopt.
                </p>
              </div>
              <div>
                <h5 class="pt-3">Article withdrawal :</h5>
                <p>
                  Only used for Articles in Press that represent early versions
                  of articles and sometimes contain errors or may have been
                  accidentally submitted twice. Occasionally, but less
                  frequently, the articles may represent infringements of
                  professional, ethical codes, such as multiple submissions,
                  bogus claims of authorship, plagiarism, fraudulent use of
                  data, or the like. Articles in Press (articles that have been
                  accepted for publication but which have not been formally
                  published and will not yet have the complete volume/issue/page
                  information) that include errors or are discovered to be
                  accidental duplicates of other published article(s) or are
                  determined to violate our journal publishing ethics guidelines
                  in the view of the editors (such as multiple submission, bogus
                  claims of authorship, plagiarism, fraudulent use of data or
                  the like), maybe “Withdrawn” from IJIRE. Withdrawn means that
                  the article content (HTML and PDF) is removed and replaced
                  with an HTML page and PDF stating that the article has been
                  withdrawn according to the IJIRE Policy on Article in Press
                  Withdrawal with a link to the current policy document.
                </p>
              </div>
              <div>
                <h5 class="pt-3">Article retraction :</h5>
                <p>
                  infringement of professional, ethical standards, such as
                  numerous entries, false authorship claims, plagiarism,
                  fraudulent data use, or similar offences. On rare occasions, a
                  retraction will be used to fix submittal or publish mistakes.
                  It has long been a rare occurrence in the academic world for a
                  paper to be retracted by its authors or the editor on the
                  advice of other scholars. Several scholarly and library
                  organizations have created standards for handling retractions,
                  and IJIRE has embraced this best practice for paper
                  retractions.
                </p>
                <p>
                  A retraction notice titled "Retraction: [article title],"
                  signed by the authors and the editor, is released in the
                  paginated section of the next journal issue and mentioned in
                  the contents list.
                </p>
                <p>
                  In the electronic version, a link is made to the original
                  article.
                </p>
                <p>
                  The online article is preceded by a screen containing the
                  retraction note. It is to this screen that the link resolves;
                  the reader can then proceed to the article itself.
                </p>
                <p>
                  The original article is kept in its entirety, with the
                  exception of a watermark on each page showing that it has been
                  "retracted." The HTML form of the text is no longer available.
                </p>
              </div>
              <div>
                <h5 class="pt-3">Article removal :</h5>
                <p>
                  legal limitations :In a minimal number of cases, removing an
                  article from the online database may be necessary. This will
                  only occur where the article is defamatory or infringes
                  others’ legal rights, or where the article is, or we have good
                  reason to expect it will be, the subject of a court order, or
                  where the article, if acted upon, might pose a severe health
                  risk. While the metadata (Title and Authors) will be retained,
                  the text will be replaced with a screen indicating the article
                  has been removed for legal reasons.
                </p>
              </div>
              <div>
                <h5 class="pt-3">Article replacement :</h5>
                <p>
                  In cases where the article, if acted upon, might pose a severe
                  health risk, the original article's authors may wish to
                  retract the flawed original and replace it with a corrected
                  version. In these circumstances, the procedures for retraction
                  will be followed with the difference that the database
                  retraction notice will publish a link to the corrected
                  re-published article and a history of the document.
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}
</style>