<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="International Journal of Recent Trends in Multidisciplinary Research" pageSubTitle="An International Open Access , Peer-reviewed, Refereed Journal, DOI:10.59256/ijrtmr
"/>
        <Ijrtmr />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Ijrtmr from '../Journals/IjrtmrHomePage.vue'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        Ijrtmr,
        Footer,
    }
}
</script>
