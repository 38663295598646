<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix"
            aria-expanded="false"
            aria-controls="flush-collapseSix"
          >
            Copyright, Grants and Owenership Declaration Policy
          </button>
        </h2>
        <div
          id="flush-collapseSix"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div>
              <p>
                All rights reserved. No part of the content protected by this
                copyright notice may be reproduced or used in any form or by any
                means, electronic or mechanical, photocopying, recording, or
                information storage and retrieval system, without the prior
                written permission of the copyright owner. However, copying
                abstracts of documents does not require permission, provided
                full credit is given to the source.
              </p>
              <h6>
                Name of the Journal: International Journal of Innovative
                Research in Engineering (IJIRE)
              </h6>
            </div>
            <div>
              <h5 class="pt-3">Copyright Policy :</h5>
              <p>
                Authors note that the paper cannot be withdrawn at any condition
                once it is accepted. Authors may publish their contribution only
                if it has been substantially modified and may publish their
                contribution elsewhere with the publisher's prior written
                permission. Except for portions of copyrighted works included
                with the author's and copyright holder's permission, the Author
                warrants that it contains no defamatory statements and does not
                infringe any trademark, patent, legal right, or copyright. On
                behalf of all co-authors, the Author signs and undertakes to
                release this information. In exchange for these rights:
              </p>
              <p>
                All proprietary rights were other than copyrights, such as
                patent rights.
              </p>
              <p>
                The privilege to use all or part of this article, including
                tables and figures, in future works of their own, provided that
                the proper acknowledgment is made to the Publisher as a
                copyright holder.
              </p>
              <p>
                The permission to reproduce these Copies is for personal use
                only, not for commercial gain.
              </p>
              <p>
                Each author should ensure that the articles submitted to the
                journal are written, considering the ethical guidelines
                regarding plagiarism. Please note that all contributions undergo
                a comprehensive plagiarism check. If attempted plagiarism is
                discovered in an already published article, authors should
                formally apologize to the authors of the original work. Any work
                containing apparent plagiarism will be rejected outright, and
                the authors will face 01-year publication suspension from the
                journal. The authors will receive proper notification if such a
                situation arises.
              </p>
              <p>
                This paper has been published in a different form elsewhere.
              </p>
              <p>
                It will not be submitted elsewhere for publication before
                acceptance/rejection by this Journal.
              </p>
              <p>
                Any dispute or related violence will be discussed and considered
                only before the ‘Judiciary of Ariyalur’ in Tamil Nadu and India.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Journal Author Rights :</h5>
              <p>
                We need publishing rights for a journal to publish and
                disseminate research articles. A publishing agreement between
                the author and the journalist determines this. This Agreement
                relates to the transfer or license of the copyright to the
                journal. IJIRE supports the need for journal authors to share,
                disseminate, and maximize the impact of their research, and
                these rights, in Am Publications' proprietary journals*, are
                defined below:
              </p>
            </div>
            <div>
              <p>
                <b>For subscription articles: </b> As part of a journal
                publication agreement, authors transfer the copyright to the
                publisher but have the right to:
              </p>
              <ul>
                <li>
                  <p>
                    Share their article for personal use, internal
                    organizational use, and intellectual sharing purposes.
                  </p>
                </li>
                <li>
                  <p>
                    Retain copyright, trademark, and other intellectual property
                    rights (including research data).
                  </p>
                </li>
                <li>
                  <p>Proper attribution and credit for published work.</p>
                </li>
              </ul>
              <p>
                <b>For open access articles: </b>Authors sign an exclusive
                license agreement, where authors have copyright but exclusive
                license rights in their article to the publisher**. In this
                case, authors have the right to:
              </p>
              <ul>
                <li>
                  <p>
                    Share their article in the same ways permitted to third
                    parties under the relevant user license (together with
                    Personal Use rights)
                  </p>
                </li>
                <li>
                  <p>
                    Retain patent, trademark, and other intellectual property
                    rights (including research data).
                  </p>
                </li>
                <li>
                  <p>
                    There must be proper attribution and acknowledgment of
                    published work.
                  </p>
                </li>
                <li>
                  <p>
                    *Please note that society or third-party-owned journals may
                    have different publishing agreements. Please see the
                    journal’s guide for authors for journal-specific copyright
                    information.
                  </p>
                </li>
                <li>
                  <p>
                    **This includes the right for the publisher to make and
                    authorize commercial use. Please see “Rights granted to
                    IJIRE Journal” for more details.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h5 class="pt-3">Rights granted to IJIRE Journals :</h5>
              <p>
                <b
                  >For both subscription and open access articles, published in
                  proprietary titles, journal is granted the following rights:
                </b>
              </p>
              <p>
                The exclusive right to publish and distribute an article, and to
                grant rights to others, including for commercial purposes.
              </p>
              <p>
                For open access articles, journal will apply the relevant third
                party user license where IJIRE journal publish the article on
                its online platforms.
              </p>
              <p>
                The right to provide the article in all forms and media so the
                article can be used on the latest technology even after
                publication.
              </p>
              <p>
                The authority to enforce the rights in the article, on behalf of
                an author, against third parties, for example in the case of
                plagiarism or copyright infringement.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Protecting Author Rights :</h5>
              <p>
                Copyright aims to protect how the article has been written to
                describe an experiment and the results. IJIRE journal is
                committed to its authors to protect and defend their work and
                reputation and takes allegations of infringement, plagiarism,
                ethic disputes, and fraud very seriously.
              </p>
              <p>
                Suppose an author becomes aware of possible plagiarism, fraud,
                or infringement, we recommend contacting a journal publishing
                contact, who can then liaise with our in-house legal department.
                Specific open-access user licenses permit broad re-use that
                might otherwise be counted as copyright infringement. For
                details about how to seek permission to use an article, see our
                permission page.
              </p>
            </div>
            <div>
              <h5 class="pt-3">Disclaimer</h5>
              <p>
                The opinions expressed and figures provided in this Journal,
                IJIRE, are the authors' sole responsibility. Publishers and
                editors have no responsibility in this matter. All such
                liabilities are disclaimed.
              </p>
              <p>
                <i
                  >All disputes are subject to Ariyalur, Tamil Nadu, India
                  jurisdiction only.</i
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>