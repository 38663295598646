<template>
    <div>
        <Navbar /> 
     <PageTitle pageTitle="International Journal of Scientific Research in Engineering & Technology" pageSubTitle="An International Open Access , Peer-reviewed, Refereed Journal, DOI:10.59256/ijsreat
"/> 
        <Ijsreat />
        <Footer />
    </div>
</template>

<script>
import Navbar from "../Layout/Navbar"
import PageTitle from "../Common/PageTitle"
import Ijsreat from '../Journals/IjsreatHomePage.vue'
import Footer from "../Layout/Footer.vue"

export default {
    components: { 
         Navbar,
        PageTitle,
        Ijsreat,
        Footer,
    }
}
</script>
