<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Ethics and Policies" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4">
                <div class="ethicsAndPolicies">
                  <h3>Ethics And Policies :</h3>
                  <div class="ps-3">
                    <p>
                      Indian journal of computer science and technology (INDJCST) is (an online) journal for publishing
                      new ideas founded by academicians, educationists, engineers, and corporate people in computer
                      science and technology. The research results and fundamental advances are all aspects of the Indian
                      journal of computer science and technology (INDJCST) and various engineering disciplines. INDJCST is
                      a scholarly open, access, peer-review journal which helps academic persons and the
                      student community. INDJCST provides the academic community and industry for submitting original
                      research and applications related to various engineering disciplines and advanced technologies in
                      computer science and technology
                    </p>
                    <p>
                      The publication of an article in a peer-reviewed journal is an essential building block
                      in developing a coherent and respected knowledge network. It directly reflects the quality of work
                      of the author and the institutions that support them. peer-reviewed articles support
                      and embody the scientific method. It is, therefore, essential to agree upon standards of expected
                      ethical behavior.
                    </p>
                  </div>
                </div>

                <div class="ethicsAndTopics">
                  <h5>Ethics Topics To Consider When Publishing :</h5>
                  <div class="ps-3">
                    <p>
                      <b>Authorship Of The Paper:</b> : Only those who contributed significantly to the conception,
                      design, execution, or interpretation of the reported study should be given the author title.
                    </p>
                    <p>
                      <b>Originality And Plagiarism: </b>The authors must make sure that their works are wholly original,
                      and if they have borrowed from the ideas or words of others, they must have adequately cited or
                      quoted them.
                    </p>
                    <p>
                      <b>Data Access And Retention: </b> Authors may be asked to provide the raw data in connection with a
                      paper for editorial review and should be prepared to provide public access to such data.
                    </p>
                    <p>
                      <b>Multiple, Redundant Or Concurrent Publication:</b> : Generally, an author should only Publish
                      manuscripts to one journal or primary publication describing the same research. The following uses
                      of a work are not considered prior publications by INDJCST: publication as an academic thesis,
                      publication as an abstract, and publication as an electronic preprint. The INDJCST journal's Guide
                      for Authors includes information about prior publications.
                    </p>
                    <p>
                      <b>Acknowledgement Of Sources:</b> Proper acknowledgment.
                    </p>
                    <p>
                      <b>Disclosure And Conflicts Of Interest:</b> All Submissions Must Disclose Any Connections That
                      Could Be Interpreted As Presenting A Possible Conflict Of Interest.
                    </p>
                    <p>
                      <b>Fundamental Errors In Published Works:</b>: When writers find a significant error or inaccuracy
                      in their work, they should promptly inform the journal editor or publisher and Collaborate with the
                      editor to retract or correct the paper.
                    </p>
                    <p>
                      <b>Reporting Standards:</b> Writers of reports based on original research must provide a truthful
                      account of the work done and An Objective Discussion of its significance.
                    </p>
                    <p>
                      <b>Hazards And Human Or Animal Subjects:</b> If the work involves chemicals, procedures, or
                      equipment that have any unusual hazards inherent in their use or if it involves human or animal
                      subjects, statements of compliance are required.
                    </p>
                    <p>
                      <b>Use Of Patient Images Or Case Details:</b> Research on Patients or Volunteers Needs Ethics
                      Committee Permission And Informed Consent, Which Should be Document In The Paper.
                    </p>
                  </div>
                </div>
                <EditorialPolicy />
                <PeerReviewPolicy />
                <AuthorPolicyVue />
                <Plagiarism />
                <Duplicate />
                <Conflicts />
                <Retraction />
                <Image />
                <FraudulentVue />
                <DataVue />
                <IntegrityVue />
                <CensorshipVue />
                <CopyrightPolicyVue />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
import AuthorPolicyVue from "./Accordion/AuthorPolicy.vue";
import PeerReviewPolicy from "./Accordion/PeerReviewPolicy.vue"
import EditorialPolicy from "./Accordion/Editorial.vue"
import Plagiarism from "./Accordion/PlagiarismPolicy.vue"
import Duplicate from "./Accordion/Duplicate.vue"
import Conflicts from "./Accordion/Conflicts.vue"
import Retraction from "./Accordion/Retraction.vue"
import Image from "./Accordion/Image.vue"
import FraudulentVue from "./Accordion/Fraudulent.vue";
import DataVue from "./Accordion/Data.vue";
import IntegrityVue from "./Accordion/Integrity.vue";
import CensorshipVue from "./Accordion/Censorship.vue";
import CopyrightPolicyVue from "./Accordion/CopyrightPolicy.vue";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
    AuthorPolicyVue,
    PeerReviewPolicy,
    EditorialPolicy,
    Plagiarism,
    Duplicate,
    Conflicts,
    Retraction,
    Image,
    FraudulentVue,
    DataVue,
    IntegrityVue,
    CensorshipVue,
    CopyrightPolicyVue
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
