<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Indian Journal of Electrical and Electronics Engineering" pageSubTitle="An Open Access , Peer-reviewed, Refereed Journal, DOI:10.59256/indjeee
"/>
        <Indjeee />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Indjeee from '../Journals/IndjeeeHomePage'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        Indjeee,
        Footer,
    }
}
</script>
