

<template>
  <div>
    <NavbarVue />
    <PageTitleVue pageTitle="About Us" />
      <div class=" ptb-100">
          <div class="container">
              <div class="row gy-5 aboutUs">
                <div class="row">
                  <div class="col-lg-8 col-md-8">
                    <h3 class="headingThree">Aim:</h3>
                    <p class="pb-4">
            The 5th Dimension Research Publication (FDRP) Is A Registered
            Publication The Publishing Scientific, Original, Peer Reviewed
            Research Oriented Article(S) Of The Journal(S) In The Field Of
            Science, Engineering, Technology, And Management& Humanities Since
            2020. All Journal Of 5th Dimension Research Publication Is Online,
            Open Access, Peer Reviewed, Bi-Monthly International
            Journal.
          </p>
          <h3 class="headingThree">Scope:</h3>
          <p>
            The 5th Dimension Research Publication (FDRP) Is Not Limited To A
            Specific Aspect Of Science, Engineering, Technology, Management And
            Humanities But Is Instead Devoted To A Wide Range Of Subfields In
            The Engineering, Technology, Science, Management And Humanities Of
            The Respective Journals. The Primary Goal Of The Governing Boards Is
            To Maintain High Quality Of Publications. There Will Be A Commitment
            To Expediting The Time Taken For The Publication Of The Article(S).
            The Articles That Are Sent For Reviews Will Have Names Of The
            Authors Deleted With A View Towards Enhancing The Objectivity And
            Fairness Of The Review Process. Articles That Are Devoted To The
            Purely Mathematical Aspects Without A Discussion Of The Physical
            Implications Of The Results Or The Consideration Of Specific
            Examples Are Discouraged. Articles Concerning Engineering,
            Technology, Science, Management And Humanities Should Not Be Limited
            Merely To A Description And Recording Of Observations But Should
            Contain Theoretical And Quantitative Discussion Of The Results.
          </p>
                  </div>
                  <div class="col">
                    <div class="mt-4 pt-4" >
            <img src="../../assets/images/AboutUs/aboutUs.png" />
          </div>
                  </div>
                  
                </div>
                 

          <div>
          <h3 class="headingThree">The aim of the journal is to:</h3>
        <ul>
          <li>
            <p>
              Disseminate Scientific, Theoretical Or Applied Research In The
              Field Of Science, Engineering, Technology, Management And
              Humanities And Allied Fields.
            </p>
          </li>
          <li>
            <p>
              Dispense A Platform For Publishing Results And Research With A
              Strong Experiential Component.
            </p>
          </li>
          <li>
            <p>
              Bridge The Significant Gap Between Research And Practice By
              Promoting The Publication Of Original, Industry-Relevant Research.
            </p>
          </li>
          <li>
            <p>
              Look For Original And Unpublished Research Papers Based On
              Theoretical Or Experimental Works For The Publication Globally.
            </p>
          </li>
          <li>
            <p>
              Publish Innovative, Theoretical And Practical Advances In
              Engineering, Technology, Science, Management And Humanities, All
              Its Related Stream.
            </p>
          </li>
          <li>
            <p>
              Impart A Platform For Publishing Results And Research With A
              Strong Realistic Component.
            </p>
          </li>
          <li>
            <p>
              Build A Bridge For Significant Gap Between Research And Practice
              By Promoting The Publication Of Original, Novel, Industry-Relevant
              Research.
            </p>
          </li>
          <li>
            <p>
              Solicit Original And Unpublished Research Papers, Based On
              Theoretical Or Experimental Works.
            </p>
          </li>
        </ul>
        </div>
          
              </div>
          </div>
      </div>
      <FooterVue />
  </div>
</template>

<script>
import NavbarVue from "../Layout/Navbar";
import FooterVue from "../Layout/Footer.vue";
import PageTitleVue from "../Common/PageTitle.vue";
export default {
  components:{
    NavbarVue,
    FooterVue,
    PageTitleVue
  },
  name: 'ProjectsDetailsContent',
}
</script>

<style scoped>
</style>
