<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
          Authors And Authors Responsibilities
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p>
              Authorship provides credit for a researcher’s paper to a study and
              carries accountability. Each author is expected to meet the
              following requirements:
            </p>
            <ul>
              <li>
                <p>
                  Having Made Substantial Papers To The Conception Or Design Of
                  The Work; Or The Acquisition, Analysis, Or Interpretation Of
                  Data; Or The Creation Of New Software Used In The Work;
                </p>
              </li>
              <li>
                <p>Having Drafted The Work Or Substantively Revising It;</p>
              </li>
              <li><p>Having Approved The Submitted Version; And</p></li>
              <li>
                <p>
                  Having Agreed Both To Be Personally Accountable For The
                  Author's Own Papers And To Ensure That Questions Related To
                  The Accuracy Or Integrity Of Any Part Of The Work, Even Ones
                  In Which The Author Was Not Personally Involved, Are
                  Appropriately Investigated, Resolved, And The Resolution
                  Documented In The Literature.
                </p>
              </li>
            </ul>
            <p>
              The corresponding author, as the person who handles the manuscript
              and correspondence during the publication process, is responsible
              for ensuring that data and materials comply with transparency and
              reproducibility standards of the field and journal; ensuring that
              original data and materials upon which the submission is based are
              preserved following best practices in the field so that they are
              retrievable for reanalysis; confirming that data and materials
              accurately reflects the original; foreseeing and minimizing
              obstacles to the sharing of data and materials described in the
              work; serving as the point of contact for queries about the
              published paper, namely, informing all co-authors of any matters
              arising in relation to the published paper and to ensure such
              matters are dealt with promptly, and notifying the journal
              immediately if he/she becomes aware of any aspect that requires
              correction.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>