<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Topics" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
        <h3>Topics :</h3>
        <div class="row justify-content-between">
            
            <div class="col-lg-4 col-md-4">
                <h6>ENGINEERING</h6>
                <p v-for="topics in engineering" :key="topics">{{topics}}</p>
            </div>
            <div class="col-lg-4 col-md-4">
                <h6>TECHNOLOGY</h6>
             <p v-for="topics in techmology" :key="topics">{{topics}}</p>
            </div>
            <div class="col-lg-4 col-md-4">
              <h6>SCIENCES</h6>
              <p v-for="topics in science" :key="topics">{{topics}}</p>

            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
      engineering: [
        "Acoustical Engineering	",
        "Aeronautical Engineering	",
        "Irrigation Engineering	",
        "Agricultural Engineering	",
        "Applied Engineering	",
        "Architecture & Planning",
        "Architecture Engineering	",
        "Audio Engineering",
        "Automation And Robotics Engineering",
        "Automobile Engineering",
        "Bio Engineering",
        "Biochemical Engineering",
        "Biomedical Engineering",
        "Bio-molecular Engineering",
        "Building And Construction Engineering",
        "Ceramic Engineering",
        "Chassis Engineering",
        "Chemical Engineering",
        "Civil Engineering",
        "Computer Engineering",
        "Corrosion Engineering",
        "Electrical Engineering",
        "Electronics Engineering",
        "Energy Engineering",
        "Engineering Chemistry",
        "Engineering Management",
        "Engineering Mathematics",
      ],
      techmology: [
        "Bio Technology",
        "Ceramic Technology",
        "Construction Technology And Management",
        "Dairy Technology",
        "Electronics And Media Technology",
        "Product Design Technology",
        "Fashion Technology",
        "Fiber Technology",
        "Food Technology",
        "Forensic Science And Technology",
        "Information And Communication Technology",
        "Information Technology",
        "Instrumentation Technology",
        "Leather Technology",
        "Materials Science And Technology",
        "Oil And Paint Technology",
        "Paint Technology",
        "Pharmaceutical Technology",
        "Printing Technology",
        "Pulp And Paper Technology",
        "Rubber Technology",
        "Silk Technology",
        "Sugar Technology",
        "Textile Technology",
        "Nanotechnology",
      ],
      science: [
        "Engineering Sciences",
        "Computer Science",
        "Material Sciences",
        "Biochemistry",
        "Cryogenics",
        "Seismology",
        "Aeronautics",
        "Polymer Science",
        "Information Science",
      ],
    };
  },
};
</script>

<style scoped>
h3 {
  font-size: 24px;
  padding-bottom: 1.5rem;
}
</style>

