<template>
    <div>
        <div class="seo-banner-slider">
            <carousel
                :autoplay="5000"
                :settings='settings' 
                :wrap-around="true"
            >
                <slide
                    v-for="slide in carouselItems" 
                    :key="slide.id"
                >
                    <div class="seo-banner">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container mt-50">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="banner-content">
                                                <h1>{{slide.heading}}</h1>
                                                <p>{{slide.desc}}</p>
                                                
                                                <div class="banner-btn">
                                                    <!-- <a href="http://editorial.fdrpjournals.org" class="default-btn mr-3">Submit Your Paper<span></span></a> -->
                                                    <router-link to="/submit-your-journal" class="default-btn mr-3">
                                                        Submit Your Article<span></span>
                                                    </router-link>
                        
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="seo-banner-image animate-tb">
                                                <img :src = "slide.image">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Shape Images -->
                        <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
                        <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
                        <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
                        <div class="shape-img5"><img src="../../assets/images/shape/shape-5.png" alt="image"></div>
                        <div class="shape-img6"><img src="../../assets/images/shape/shape-6.png" alt="image"></div>
                        <div class="shape-img7"><img src="../../assets/images/shape/shape-7.png" alt="image"></div>
                        <div class="shape-img8"><img src="../../assets/images/shape/shape-8.png" alt="image"></div>
                        <div class="shape-img9"><img src="../../assets/images/shape/shape-9.png" alt="image"></div>
                        <div class="shape-img10"><img src="../../assets/images/shape/shape-10.png" alt="image"></div>
                        <div class="shape-img13"><img src="../../assets/images/shape/shape-13.png" alt="image"></div>
                        <div class="shape-img14"><img src="../../assets/images/shape/shape-14.png" alt="image"></div>
                    </div>
                </slide>

                <template #addons>
                    <Navigation />
                </template>
            </carousel>
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MainBannerFive',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                heading: 'Peer Reviewed International Journal',
                desc: 'Scholarly Peer Review high indexed international journals in various streams.',
                image: require('../../assets/images/seo-banner/international.png'),
            },
            {
                id: 2,
                heading: 'We are a Global Publisher',
                desc: 'Focused to publishing significant research. Let\'s make a positive change, together. Are you in?',
                image: require('../../assets/images/seo-banner/globelPublisher.png'),
            },
            {
                id: 3,
                heading: 'Promote your work',
                desc: 'Read our advice on increasing the impact and visibility of your publication',
                image: require('../../assets/images/seo-banner/promoteTwo.png'),
            },
        ],
        
        isPopup: false,   
    }),
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
})
</script>
