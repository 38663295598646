<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Aim & Scope" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJECE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="aim row pt-4 gy-4">
                <div>
                  <h3>Aim :</h3>
                  <div class="row">
                    <div class="col-sm-9 col-md-7 col-lg-7">
                      <p class="ps-3">
                        The Indian Journal of Electronics and Communication Engineering (INDJECE) is a scholarly and
                        peer-reviewed journal dedicated to providing scientists, engineers, and technicians with the
                        latest developments in Electronics and Communication Engineering. The journal offers a window into
                        the most recent discoveries in four categories, Semiconductors Devices, Systems and Control
                        Engineering, Transmission Lines, VLSI Design and Technology, Artificial Immune Systems, Circuits
                        and Devices, Communication and Information Processing, Conditional Monitoring and Instrumentation,
                        Controllers, Fuzzy and Hybrid Optimization, Neural Networks, Optics, and Optoelectronics. The
                        editorial board welcomes original, substantiated papers on the above topics, which could provoke
                        new scientific interest and benefit those devoted to Electronics and Communication Engineering.
                      </p>
                    </div>
                    <div class="col-sm-5 col-md-5 col-lg-5">
                      <img class="mt-5" src="../../../assets/images/AboutUs/aimscopeThree.png" alt="image" />
                    </div>
                  </div>
                  <div>
                    <p class="ps-3"></p>
                  </div>
                </div>
                <div class="focus-scope">
                  <h5>Focus and Scope :</h5>
                  <div class="ps-3">
                    <p>
                      The INDJECE welcomes original research papers, technical notes, and review articles on the following
                      disciplines:
                    </p>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <ul>
                          <li>
                            <p>Semiconductors Devices</p>
                          </li>
                          <li>
                            <p>Systems And Control Engineering</p>
                          </li>
                          <li>
                            <p>Transmission Lines Etc.</p>
                          </li>
                          <li>
                            <p>VLSI Design And Technology</p>
                          </li>
                          <li>
                            <p>Artificial Immune System</p>
                          </li>
                          <li>
                            <p>Circuits And Devices</p>
                          </li>
                          <li>
                            <p>Communication And Information Processing</p>
                          </li>
                          <li>
                            <p>Conditional Monitoring And Instrumentation</p>
                          </li>
                          <li>
                            <p>Controllers, Drives And Machine Design</p>
                          </li>
                          <li>
                            <p>Devices And Systems</p>
                          </li>
                          <li>
                            <p>Electrical Engineering Communications</p>
                          </li>
                          <li>
                            <p>Devices And Systems</p>
                          </li>
                        </ul>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <ul>
                          <li>
                            <p>Electrical Engineering Communications</p>
                          </li>
                          <li>
                            <p>Electromagnetics And Microwave</p>
                          </li>
                          <li>
                            <p>Fuzzy And Hybrid Optimization</p>
                          </li>
                          <li>
                            <p>Hybrid Renewable Energy And Energy Saving</p>
                          </li>
                          <li>
                            <p>Image Processing</p>
                          </li>
                          <li>
                            <p>Measurement And Testing</p>
                          </li>
                          <li>
                            <p>Neural Networks</p>
                          </li>
                          <li>
                            <p>Nanoscience And Nanotechnology</p>
                          </li>
                          <li>
                            <p>Optics And Optoelectronics</p>
                          </li>
                          <li>
                            <p>Power Engineering</p>
                          </li>
                          <li>
                            <p>Power Transmission</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjece" },
        { id: 2, name: "Aim & Scope", to: "/indjece/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjece/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjece/guidance-for-ems"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjece/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjece/current-issue" },
        { id: 7, name: "Archives", to: "/indjece/archives" },
        { id: 8, name: "Editorial Board", to: "/indjece/editorial-board" },
        { id: 9, name: "Topics", to: "/indjece/topics" },
        { id: 10, name: "Call for papers", to: "/indjece/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjece/instruction-for-authors",
        },



        { id: 12, name: "Ethics & Policies", to: "/indjece/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjece/indexing-abstracting" },
        { id: 14, name: "Impact Factor", to: "/indjece/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjece/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjece/downloads" },
        { id: 17, name: "FAQs", to: "/indjece/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjece/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjece/indjece-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}</style>
