<template>
  <div>
    <div class="about-area-two ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
            <div class="about-image">
              <img
                src="../../assets/images/TeamWork.png"
                alt="image"
                class="rounded-10"
              />
            </div>
          </div>
          <div class="col-lg-7 col-md-12">
            <div class="about-content">
              <div class="section-title text-left">
                <span class="sub-title">About Us</span>
                <h2>We Are a Team of Experts from Every Field of Study.</h2>
              </div>
              <div class="about-text">
                <h4>Our Intent</h4>
                <p>
                  A researcher impact facilitator as opposed to an article free
                  international journal publishers Our ongoing commitment to
                  promoting "real world" effect from the research we publish and
                  the services we provide is the foundation of Fifth Dimension
                  Research Publications new strategy. Supporting the
                  dissemination and influence of research paper publication and
                  ideas is already our main priority. In order to further
                  support these goals and make sure that the research we publish
                  can fully contribute to addressing important global concerns,
                  our next move in this direction is to concentrate our efforts
                  on four transdisciplinary goals.
                </p>
              </div>
              <div class="about-text">
                <h4>What we do</h4>
                <p>
                  FDRP is one of the world's leading digital-first publishers,
                  commissioning, curating and showcasing research paper
                  publications that can make a real difference.
                </p>
              </div>
              <div class="about-text">
                <h4>Who we are</h4>
                <p>
                  We work across the world to share knowledge and provoke the
                  kind of debate that leads to positive change. We are a family
                  business passionate about people and doing things differently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeAredDynamicTeam",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>
