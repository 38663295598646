<template>
    <div class="works-area ptb-100 gray-bg">
        <div class="container-fluid">
            <div class="section-title">
                <span class="sub-title">Recent Issues</span>
                <h2>Our Journal Updates</h2>
            </div>

            <CaseStuduesCarousel />
        </div>
    </div>
</template>

<script>
import CaseStuduesCarousel from '../Carousels/CaseStuduesCarousel'
export default {
    name: 'OurCaseStudies',
    components: {
        CaseStuduesCarousel
    }
}
</script>
