<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
            Duplicate And Redundant Publication
          </button>
        </h2>
        <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p>
              Duplicate or redundant publication occurs when a work or a
              substantial part of it is published more than once by the
              author(s) of the work without appropriate cross-referencing or
              justification. When authors submit manuscripts to our journals,
              these manuscripts should not be under consideration, accepted for
              publication or in press within a different journal, book or
              similar entity.
            </p>
            <p>
              If part of a paper that an author wishes to submit to an INDJEEE has
              appeared or will appear elsewhere, he/she must specify the details
              in the cover letter at the submission. Consideration by the INDJEEE
              is possible only if the main result, conclusions, or implications
              are not apparent from the other work, or if the other work is
              published in a language other than English. In case of any doubt,
              authors should seek advice from the editor handling their paper.
            </p>
            <p>
              If an author is re-using a figure or figures published elsewhere,
              or that is copyrighted, the author must provide documentation that
              the previous publisher or copyright holder has given permission
              for the figure to be re-published. The INDJEEE editor-in-chief
              consider all material in good faith that their journals have full
              permission to publish every part of the submitted material,
              including figures.
            </p>
            <p>
              We expect our readers, reviewers, and editors to raise any
              suspicions of duplicate or redundant publication either by
              contacting the relevant editor or by emailing
              editorinchief@indjeee.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}

.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}

.header[data-v-1309cd11] {
  border: none;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}

.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}

.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}

button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}

.accordion-button:focus {
  border: none;
}

.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}
</style>