<template>
    <div class="seo-analysis-section ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="analysis-img">
                        <img src="../../assets/images/dropUs.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="analysis-form">
                        <div class="section-title text-left">
                            <span class="sub-title">Queries</span>
                            <h2>Drop us a message</h2>
                            <p>Got questions or need details about journals, drop us a message and we will get back to you within 48 hours.</p>
                        </div>

                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="your_email" placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" placeholder="Phone Number">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="message" placeholder="Message">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="text-center">
                                <button type="submit" class="default-btn">
                                    Submit
                                    <span></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GetFreeAnalysis'
    }
</script>
