<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            Plagiarism
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p>
              Plagiarism is unacknowledged copying or an attempt to misattribute
              original authorship, whether of ideas, text, or results. We do not
              tolerate plagiarism in any of our publications, and we reserve the
              right to check all submissions through appropriate plagiarism
              checking tools. Submissions containing suspected plagiarism, in
              whole or part, will be rejected. The INDJECE editors assess all the
              following cases of plagiarism on their individual merits.
            </p>
            <ul>
              <li>
                <p>
                  Plagiarism Can Be Said To Have Clearly Occurred When Large
                  Chunks Of Text Have Been Cut-And-Pasted Without Appropriate
                  And Unambiguous Attribution. Aside From Wholesale Verbatim
                  Reuse Of Text, Due Care Must Be Taken To Ensure Appropriate
                  Attribution And Citation When Paraphrasing And Summarizing The
                  Work Of Others.
                </p>
              </li>
              <li>
                <p>
                  Text Recycling, Or Reuse Of Parts Of Text From An Author’s
                  Previous Research Publication, Is A Form Of Self-Plagiarism.
                  When Reusing Text, Whether From The Author’s Own Publication
                  Or That Of Others, Appropriate Attribution And Citation Is
                  Necessary To Avoid Creating A Misleading Perception Of Unique
                  Paper For The Reader.
                </p>
              </li>
              <li>
                <p>
                  Duplicate Publication Occurs When An Author Reuses Substantial
                  Parts Of His/Her Own Published Work Without Providing The
                  Appropriate References. This Can Range From Publishing An
                  Identical Paper In Multiple Journals, To Only Adding A Small
                  Amount Of New Data To A Published Paper.
                </p>
              </li>
            </ul>
            <p>
              If the allegations appear to be founded, we will request all named
              authors of the paper to explain the overlapping material. If the
              explanation is not satisfactory, we will reject the submission,
              and may also reject future submissions.
            </p>
            <p>
              If plagiarism is discovered post publication, the INDJECE may
              correct or retract the original publication depending on the
              degree of plagiarism, context within the published article and its
              impact on the overall integrity of the published study. We expect
              our readers, reviewers, and editors to raise any suspicions of
              plagiarism, either by contacting the editor or by emailing
              editorinchief@indjece.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>