/* eslint-disable */
import {
    createApp
} from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import Toaster from '@meforma/vue-toaster';
import BootstrapVue3 from 'bootstrap-vue-3'

import './assets/custom.scss'
import api from './util/backend-api';

//const apps = Vue.createApp(App);

//  Vue.config.productionTip = false

const app = createApp(App)
const rootcomp = app.use(router)
    .use(store)
    .use(api)
    .use(Toaster)
    .use(BootstrapVue3)
    .mount('#app');

app.config.globalProperties.api = api
