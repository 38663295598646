<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Special Issue" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span> 
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-2">
              <div class="conferencelist-container" v-if="mainPage">
                <div class="row paperBox mb-4 p-3">
                  <div class="header-container row">
                    <h4 class="col-10 pr-2">{{ icicmct23.title }}</h4>
                    <div class="col-2">
                      <b-button variant="outline-primary" size="sm"
                        @click="viewConferencePapers()"
                        >View Papers</b-button
                      >
                    </div>
                    <h5 class="col-10 pr-2">{{ icicmct23.name }}</h5>
                  </div>
                  <div class="details-container mt-4 row">
                    <div class="author-details pb-3">
                      <p><b>Organized By : </b>{{ icicmct23.conductedBy }}</p>
                    </div>
                    <div class="submit-details pb-3">
                      <p><b>Conference Date : </b>{{ icicmct23.conferenceDate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            <div class="paperlist-container" v-if="!mainPage && !paperPage">
              <div class="row paperBox mb-4 p-2 text-center">
                <h5>2023 | Volume 4 - Issue 3 | ICICMCT'23</h5>
              </div>
              <div
                class="row paperBox mb-3"
                v-for="items in papersListFromJs"
                :key="items.id"
              >
                <div class="col-9 p-3">
                  <h4>{{ items.title }}</h4>
                  <p><b>Author:</b> {{ items.author }}</p>
                  <p><b>Article Type:</b> {{ items.articleType }}</p>
                  <p><b>Pages:</b> {{ items.pages }}</p>
                </div>
                <div class="col-3 p-3">
                  <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="viewPaperDetails(items.id)"
                    >Paper Details</b-button
                  >
                  <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="downloadPDF(items.paperUrl)"
                    >Download PDF</b-button
                  >
                  <b-button
                    class="col-8"
                    variant="outline-primary"
                    size="sm"
                    @click="googleSearch('https://www.google.com/search?q='+items.title)"
                    >Google</b-button
                  >
                </div>
              </div>
            </div>
              <div class="paperdetails-container" v-if="!mainPage && paperPage">
                <div class="row paperBox mb-4 p-3">
                  <div class="header-container row">
                    <h4 class="col-10 pr-2">{{ paperTitle }}</h4>
                    <div class="col-2">
                      <b-button variant="outline-primary" size="sm"
                        @click="downloadPDF()"
                        >Download PDF</b-button
                      >
                    </div>
                  </div>
                  <div class="details-container mt-4 row">
                    <div class="author-details pb-3">
                      <p><b>Author(s) : </b>{{ paperAuthor }}</p>
                    </div>
                    <div class="submit-details pb-3">
                      <p><b>Submission-Year,Month : </b>{{ paperMonth }}</p>
                    </div>
                    <div class="author-details pb-3">
                      <p><b>Pages : </b>{{ paperPages }}</p>
                    </div>
                    <div class="author-details pb-3">
                      <p><b>Article Type : </b>{{ paperType }}</p>
                    </div>
                    <div class="author-details">
                      <p><b>DOI : </b>{{ paperDoi }}</p>
                    </div>
                  </div>
                </div>
                <div class="row p-3 paperBox">
                  <h4 class="mb-2">Abstract</h4>
                  <p class="abstract-details">{{ paperAbs }}</p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
import conferenceIcicmct from "../../../util/conference-icicmct23";

export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  }, 
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
      mainPage: true,
      papersListFromJs: conferenceIcicmct.papersList,
      paperPage: false,
      selectedId: null,
      paperTitle: "",
      paperAuthor: "",
      paperMonth: "",
      paperPages: "",
      paperType: "",
      paperDoi: "",
      paperAbs: "",
      paperUrl: "",
      icicmct23: {
        title: 'ICICMCT\'23',
        name: 'International Conference on Innovations in Computing Materials & Communication Technologies',
        conductedBy: 'IES College of Engineering, Kerala, India',
        conferenceDate: '15 May 2023'
      },
    };
  },
  watch: {
    $route: function () {
      if("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid)
      } else if ("id" in this.$route.query) {
        this.viewConferenceDetails(this.$route.query.id)
        //this.viewPaperDetails(this.$route.query.id)
      } else {
        this.mainPage = true;
        this.paperPage = false;
      }
    }
  },
  methods: {
    downloadPDF: function (paperURL) {
      if(paperURL) {
        window.open(paperURL, "_blank");
      } else{
        window.open(this.paperUrl, "_blank");
      }
    },
    googleSearch(url){
      window.open(url, '_blank');
    },
    viewPaperDetails(pid) {
      this.$router.push({ path: "conference", query: { paperid: pid } });
      this.paperPage = true;
      this.mainPage = false
      this.selectedId = pid;

      const detail = this.papersListFromJs.find((d) => d.id === pid);

      this.paperTitle = detail.title;
      this.paperAuthor = detail.author;
      this.paperMonth = detail.submissionYearMonth;
      this.paperPages = detail.pages;
      this.paperType = detail.articleType;
      this.paperDoi = detail.doi;
      this.paperAbs = detail.abstract;
      this.paperUrl = detail.paperUrl
    },
    viewConferencePapers: function() {
      this.$router.push({ path: "conference", query: { id: 'icicmct23' } })
      this.mainPage = false
    },
    viewConferenceDetails: function() {
      this.mainPage = false
      if ("paperid" in this.$route.query) {
        this.paperPage = true
        this.viewPaperDetails(this.$route.query.paperid)
      } else {
        this.paperPage = false
      }
    }
  },
  mounted: function() {
    if("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid)
    } else if ("id" in this.$route.query) {
      this.viewConferenceDetails(this.$route.query.id)
      //this.viewPaperDetails(this.$route.query.id)
    } else {
      this.mainPage = true;
      this.paperPage = false;
    }
  }
};
</script>
  
  <style scoped>
.issueButton {
  cursor: pointer;
}
.box {
  background: #0000ff;
  border-radius: 8px;
}
.box p {
  color: #fff;

  text-align: center;
  padding: 6px;
}

.btn-outline-primary {
  color: #0000ff;
  border-color: #0000ff;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0000ff;
  background: #0000ff;
}

.paperBox {
  /* border: 2px solid #EEEDE7; */
  word-wrap: break-word;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p {
  margin-bottom: 5px;
  text-align: left;
}
h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.abstract-details {
  white-space: pre-wrap;
}

@media screen and (max-width: 991px) {
  .btn-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 512px) {
  .btn-sm {
    font-size: 10px !important;
  }
}
</style>
  
