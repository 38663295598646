<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Archives" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJEEE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-2">
            <div class="archive-data-container" v-if="!listVisible&&!paperPage">
            <div class="row mb-4" v-for="year in years" :key="year">
              <h6>{{ year }}</h6>
                <div class="row mb-1" v-for="(data, index) in archives[year]" :key="data.index">
                  <div class="col-lg-2 col-md-2 col-sm-2 box m-1 issueButton" v-for="issue in data.sort()" :key="issue.index" @click="viewIssueData(year, index, issue)">
                    <p>Volume {{ index }} Issue {{ issue }}</p>
                </div>
              </div> 
            </div>
            </div>
            <div class="paperlist-container" v-if="listVisible&&!paperPage">
              <div class="row paperBox mb-4 p-2 text-center">
                <h5>{{cYear}} | Volume {{cVolume}} - Issue {{cIssue}}</h5>
              </div>
              <div class="pb-3">
                    <p class="text-center">Published by: Fifth Dimension Research Publication</p>
                  </div>
              <div class="row paperBox mb-3" v-for="paper in papersList" :key="paper.paper_uniqueid">
                <div class="col-9 p-3">
                  <h4>{{ paper.paper_title }}</h4>
                  <p><b>Author:</b> {{ paper.paper_author }}</p>
                  <p><b>Article Type:</b> {{ paper.paper_articletype }}</p>
                  <p><b>Pages:</b> {{paper.paper_pages}}</p>
                </div>
                <div class="col-3 p-3">
                  <b-button class="col-8 mb-2" variant="outline-primary" size="sm" @click="viewPaperDetails(paper.paper_id)">Paper Details</b-button>
                  <b-button class="col-8 mb-2" variant="outline-primary" size="sm" @click="downloadPDF(paper.paper_url)">Download PDF</b-button>
                  <b-button
                    class="col-8"
                    variant="outline-primary"
                    size="sm"
                    @click="googleSearch('https://www.google.com/search?q='+paper.paper_title)"
                    >Google</b-button
                  >
                </div>
              </div>
            </div>
            <div class="paperdetails-container" v-if="paperPage">
              <div class="row paperBox mb-4 p-3">
                <div class="header-container row">
                  <h4 class="col-10 pr-2">{{paperDetails.paper_title}}</h4>
                  <div class="col-2">
                    <b-button variant="outline-primary" size="sm" @click="downloadPDF(paperDetails.paper_url)">Download PDF</b-button>
                  </div>
                </div>
                <div class="details-container mt-4 row">
                  <div class="author-details pb-3">
                    <p><b>Author(s) : </b>{{paperDetails.paper_author}}</p>
                  </div>
                  <div class="submit-details pb-3">
                    <p><b>Submission-Year,Month : </b>{{paperDetails.year}}, {{paperDetails.month}}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Pages : </b>{{paperDetails.paper_pages}}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Article Type : </b>{{paperDetails.paper_articletype}}</p>
                  </div>
                  <div class="author-details pb-3"> 
                    <p><b>DOI : </b>{{ paperDetails.paper_doi }}</p>
                  </div>
                </div>
              </div>
              <div class="row p-3 paperBox">
                <h4 class="mb-2">Abstract</h4>
                <p class="abstract-details">{{paperDetails.paper_abstract}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template> 

<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
     Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjeee/Guidance-For-Ems-Indjeee"},
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
      archives:{},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: '',
      cVolume: '',
      cIssue: '',
      paperDetails: {}
    };
  },
  methods:{
    getArchivesData: function() {
      this.api.getDataModule("archives/5/getarchives","journals/v1/").then((res)=>{
        console.log(res.data)
        this.archives = res.data.archives
        this.sortData()
      }, (err) => {
            console.log(err);
      })
    },
    getPapersList: function(year, volume, issue) {
      this.api.getDataModulePagination("archives/5/getpaperslist","journals/v1/","year="+year+"&volume="+volume+"&issue="+issue).then((res)=>{
        this.papersList = res.data.papersList
      }, (err) => {
        console.log(err);
      })
    },
    getPaperDetails: function(paperid) {
      this.api.getDataModulePagination("archives/5/getpaperdetails","journals/v1/","paperid="+paperid).then((res)=>{
        this.paperDetails = res.data.paperdetails
      }, (err) => {
        console.log(err);
      })
    },
    sortedIssues: function(val) {
      return val.sort().reverse()
    },
    sortData: function() {
      this.years = Object.keys(this.archives).sort().reverse()
    },
    viewIssueData: function(year, volume, issue) {
      this.getPapersList(year, volume, issue)
      this.cYear= year
      this.cVolume = volume
      this.cIssue = issue
      this.listVisible = true
      this.$router.push({path: 'archives', query: {year: year, volume:volume, issue: issue}})
    },
    viewPaperDetails: function(paperid) {
      this.getPaperDetails(paperid)
      this.paperPage = true
      this.$router.push({path: 'archives', query: {paperid: paperid}})
    },
    downloadPDF: function(paperURL) {
      window.open(paperURL, '_blank')
    },
    googleSearch(url){
      window.open(url, '_blank');
    }
  },
  watch: {
    '$route': function() {
      if(!this.paperPage &&
          this.listVisible && this.cYear == this.$route.query.year &&
            this.cVolume == this.$route.query.volume &&
            this.cIssue == this.$route.query.issue) {
        this.listVisible = true
      } else {
        this.listVisible = false
      }

      if('paperid' in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid)
      } else {
        this.paperPage = false
        this.getArchivesData()
      }
    }
  },
  mounted: function(){
    if('paperid' in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid)
    } else if("year" in this.$route.query &&
        "issue" in this.$route.query &&
        "volume" in this.$route.query) {
      this.viewIssueData(this.$route.query.year, this.$route.query.volume, this.$route.query.issue)
    } else{
      this.listVisible = false
      this.paperPage = false
      this.getArchivesData()
    }
  }
};
</script>

<style scoped>
.issueButton {
  cursor: pointer;
}
.box {
  background: #0000ff;
  border-radius: 8px;
}
.box p {
  color: #fff;

text-align: center;
padding:6px;
}

.btn-outline-primary {
    color: #0000ff;
    border-color: #0000ff;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0000ff;
  background: #0000ff;
}

.paperBox {
    /* border: 2px solid #EEEDE7; */
    word-wrap: break-word;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p{
    margin-bottom: 5px;
    text-align:left;
}
h5{
  margin-bottom: 5px;
  margin-top: 5px
}
h6{
  margin-bottom: 5px;
  margin-top: 5px;
}
.abstract-details {
  white-space: pre-wrap;
}
@media screen and (max-width:991px) {
  .btn-sm{
    font-size: 12px !important;
  }
}
@media screen and (max-width:512px) {
  .btn-sm{
    font-size: 10px !important;
  }
}
</style>
