<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSeven">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
            Retractions, Corrections and Expressions of Concern
          </button>
        </h2>
        <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p>
              The INDJEEE operates the following policy for making corrections to the print and online versions of our
              peer-reviewed content. Three kinds of amendments are relevant for peer-reviewed material:
            </p>
            <ul>
              <li>
                <p>
                  Erratum: Notification Of An Important Error Made By The Journal That Affects The Publication Record Or
                  The Scientific Integrity Of The Paper, Or The Reputation Of The Authors Or Of The Journal.
                </p>
              </li>
              <li>
                <p>
                  Corrigendum: Notification Of An Important Error Made By The Author(S) That Affects The Publication
                  Record Or The Scientific Integrity Of The Paper, Or The Reputation Of The Authors Or The Journal.
                </p>
              </li>
              <li>
                <p>
                  Retraction: Notification Of Invalid Results That Affect The Reliability Of A Previously Published
                  Article. The Original Article Is Marked As Retracted But Remains Available To Readers. Retractions Are
                  Reserved For Articles That Are So Seriously Flawed That Their Findings Or Conclusions Should Not Be
                  Relied Upon.
                </p>
              </li>
            </ul>
            <p>
              Accepted manuscripts may make minor changes such as those which would likely occur during typesetting or
              proofreading but not substantive corrections. Anyone who believes that research published by INDJEEE has not
              been carried out in line with these principles could raise their concerns with the relevant editor, or email
              editorinchief@indjeee.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}

.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}

.header[data-v-1309cd11] {
  border: none;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}

.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}

.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}

button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}

.accordion-button:focus {
  border: none;
}

.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}</style>