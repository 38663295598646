<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Ethics & Policies" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4 ethicsAndPolicies">
                <div>
                  <h3 class="headingThree">Ethics and Policies :</h3>
                  <div class="ps-3">
                    <p>
                      International Journal of Innovative Research in
                      Engineering (IJIRE) is (Online), Bi-monthly international
                      journal for publishing new ideas founded by academicians,
                      educationists, engineers, and corporate people. The
                      research results and fundamental advancement are all
                      aspects of Innovative Research in Advanced Engineering &
                      Technology, various engineering disciplines, etc. IJIRE is
                      a scholarly open, accessible, Peer Review
                      Journal that helps an academic person and student
                      community. IJIRE provides the academic community and
                      industry for submitting original research and applications
                      related to Various Engineering disciplines and Advanced
                      Technologies.
                    </p>
                    <p>
                      Publishing an article in a Peer-Reviewed
                      Journal is critical for developing a cohesive and
                      acknowledged knowledge network. It immediately reflects
                      the authors and the institutions that support them's work
                      excellence. Articles that have been 
                      peer-reviewed support and represent the scientific
                      process. As a result, it is critical to agree on required
                      ethical conduct norms.
                    </p>
                  </div>
                </div>

                <div class="ethicsAndTopics">
                  <h5>Ethics topics to consider when publishing :</h5>
                  <div class="ps-3">
                    <p>
                      <b>Authorship of the Paper: </b>Only individuals who made
                      a significant effort to conceptualize, plan, carry out, or
                      interpret the reported study should be allowed to sign
                      their names as authors.
                    </p>
                    <p>
                      <b>Originality and Plagiarism: </b>Authors should
                      guarantee that they have authored original works and that
                      if they have borrowed the work and words of others, they
                      have appropriately cited or quoted them.
                    </p>
                    <p>
                      <b>Data Access and Retention: </b> Authors may be required
                      to provide the raw data related to a publication for
                      editorial review; they should be ready to make the data
                      available to the general public.
                    </p>
                    <p>
                      <b>Multiple, redundant, or concurrent publishing: </b>An
                      author should only publish submissions in one journal or
                      principal publication that describe the same study. IJIRE
                      does not view the following uses of a work as prior
                      publication:
                    </p>
                    <div>
                      <ul>
                        <li>
                          <p>Publication in the form of an abstract</p>
                        </li>
                        <li>
                          <p>Publication as an academic thesis</p>
                        </li>
                        <li>
                          <p>Publication as an electronic preprint</p>
                        </li>
                      </ul>
                      <p>
                        Information on prior publications is included within
                        IJIRE journal Guide for Authors.
                      </p>
                    </div>
                    <p>
                      <b>Acknowledgment Of Sources: </b>Proper acknowledgment.
                    </p>
                    <p>
                      <b>Disclosure and Any Conflicts of Interest: </b> Each
                      submission must disclose any connections that might create
                      a conflict of interest.
                    </p>
                    <p>
                      <b>Fundamental Mistakes in Published Works: </b>When an
                      author finds a substantial error or inaccuracy in their
                      published work, they must contact the publisher or journal
                      editor as soon as possible and work with the editor to
                      withdraw or fix the publication.
                    </p>
                    <p>
                      <b>Reporting Guidelines: </b>Writers of reports on original
                      research should give a truthful assessment of the work
                      done and an unbiased analysis of its relevance.
                    </p>
                    <p>
                      <b>Hazards And Human or Animal Subjects: </b>Statements of
                      compliance are necessary if the job contains substances,
                      methods, or equipment that have particular dangers
                      inherent in their usage or if the animal or human subjects
                      are used.
                    </p>
                    <p>
                      <b>Usage of Patient Photographs or Case Information: </b>
                      Research involving patients or volunteers must get
                      informed permission from all participants and be approved
                      by an ethics committee. This approval must be included in
                      the report.
                    </p>
                  </div>
                </div>
                <AuthorPolicy />
                <EthicsPolicy />
                <TcPolicy />
                <PeerReviewPolicy />
                <Plagiarism />
                <CopyrightPolicy />
                <LicensePolicy />
                <PrivacyPolicy />
                <RefundPolicy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
import AuthorPolicy from "../../Journals/Ijire/Accordion/AuthorPolicy.vue";
import EthicsPolicy from "../../Journals/Ijire/Accordion/EthicsPolicy.vue";
import TcPolicy from "../../Journals/Ijire/Accordion/T&cPolicy.vue";
import PeerReviewPolicy from "../../Journals/Ijire/Accordion/PeerReviewPolicy.vue";
import Plagiarism from "../../Journals/Ijire/Accordion/PlagiarismPolicy.vue";
import CopyrightPolicy from "../../Journals/Ijire/Accordion/CopyrightPolicy.vue";
import LicensePolicy from "../../Journals/Ijire/Accordion/LicensePolicy.vue";
import PrivacyPolicy from "../../Journals/Ijire/Accordion/PrivacyPolicy.vue";
import RefundPolicy from "../../Journals/Ijire/Accordion/RefundPolicy.vue";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
    AuthorPolicy,
    EthicsPolicy,
    TcPolicy,
    PeerReviewPolicy,
    Plagiarism,
    CopyrightPolicy,
    LicensePolicy,
    PrivacyPolicy,
    RefundPolicy,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
      items: {
        q1: {
          title: "Nivedhan",
          content: "aaaaaaaaaaaaaaaaaaaaaaaaaa",
          open: false,
        },
      },
    };
  },
};
</script>

<style scoped></style>