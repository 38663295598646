<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Indian Journal of Computer Science and Technology" pageSubTitle="An International Open Access , Peer-reviewed, Refereed Journal, DOI:10.59256/indjcst
"/>
        <Indjcst />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Indjcst from '../Journals/IndjcstHomePage'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        Indjcst,
        Footer,
    }
}
</script>
