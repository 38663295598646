<template>
  <div class="contact-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="contact-info-box">
            <div class="icon">
              <i class="flaticon-email"></i>
            </div>

            <h3>Email Us at</h3>
            <!-- <p>
              <a href="mailto:queries@fdrpjournals.org"
                >queries@fdrpjournals.org</a
              >
            </p> -->
            <p>
              <a href="mailto:support@fdrpjournals.org"
                >support@fdrpjournals.org</a
              >
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="contact-info-box">
            <div class="icon">
              <i class="flaticon-phone-call"></i>
            </div>

            <h3>Registered Office</h3>
            <p>
              <a href="https://goo.gl/maps/SVe4mDUbTLPotf2g6" target="_blank"
                >5th Dimension Research Publications<br />
                38D5F, Rajaji Nagar, Ariyalur,<br />
                Tamil Nadu, India - 621713.</a
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="contact-info-box">
            <div class="icon">
              <i class="flaticon-marker"></i>
            </div>

            <h3>Call Us at</h3>
            <p><a href="tel:+919840521421">+91 98405 21421</a></p>
            <p>Monday to Friday: 10AM to 8PM (IST)</p>
          </div>
        </div>
      </div>

      <div class="section-title">
        <span class="sub-title">Contact Us</span>
        <h2>Drop us a Message</h2>
        <p>
          For Paper submissions, Submit your paper to the respective journal
          mail address.<br />
          For all other queries, drop us a message below and we will get back to
          you.
        </p>
      </div>

      <div class="row align-items-center">
        <div class="col-lg-4 col-md-4">
          <div class="contact-image">
            <img src="../../assets/images/contact.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-8 col-md-8">
          <div class="contact-form">
            <form id="contactForm">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="form-control"
                      required
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control"
                      required
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      required
                      class="form-control"
                      placeholder="Phone"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      name="msg_subject"
                      id="msg_subject"
                      class="form-control"
                      required
                      placeholder="Subject"
                    />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      class="form-control"
                      id="message"
                      cols="30"
                      rows="5"
                      required
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="default-btn">
                    Send Message <span></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactContent",
};
</script>
