<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingEleven">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseEleven"
            aria-expanded="false"
            aria-controls="flush-collapseEleven"
          >
            Integrity Of Record
          </button>
        </h2>
        <div
          id="flush-collapseEleven"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingEleven"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p>
              The INDJECE maintains a record of the existence of everything we
              publish with information (metadata) describing each publication.
              If our content is deemed not to comply with the laws of a
              sovereign nation, we will make every effort to ensure the metadata
              remain accessible within that jurisdiction. We preserve the
              academic record as far as possible, if we are obliged to alter the
              publication record in any way, such as a retraction against
              research misconduct.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}
</style>