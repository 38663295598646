<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Download Forms" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJECE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
           <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table" > 
              <div class="" style="text-align:center;padding-top: 80px;"> 
             <h6> Manuscript Template</h6>
             <button @click="getManuScript(this.ManuScriptUrl)" class="btn m-2">Download</button>
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table">
              <div  style="text-align:center;padding-top: 80px;"> 
                <h6 class="justify content center"> Copyright Form</h6>
             <button @click="getCopyRightForm(this.CopyRightFormUrl)" class="btn m-2">Download</button> 

              </div>
        
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      ManuScriptUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/4/manuscripttemplate/IndJece-Manuscript-Template.docx',
      CopyRightFormUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/4/copyrightform/IJECE_Copyright+Transfer+Agreement+2021.pdf ',
      links: [
        { id: 1, name: "Home", to: "/indjece" },
        { id: 2, name: "Aim & Scope", to: "/indjece/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjece/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjece/guidance-for-ems"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjece/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjece/current-issue" },
        { id: 7, name: "Archives", to: "/indjece/archives" },
        { id: 8, name: "Editorial Board", to: "/indjece/editorial-board" },
        { id: 9, name: "Topics", to: "/indjece/topics" },
        { id: 10, name: "Call for papers", to: "/indjece/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjece/instruction-for-authors",
        },



        { id: 12, name: "Ethics & Policies", to: "/indjece/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjece/indexing-abstracting" },
        { id: 14, name: "Impact Factor", to: "/indjece/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjece/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjece/downloads" },
        { id: 17, name: "FAQs", to: "/indjece/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjece/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjece/indjece-contact",
        },
      ],
    };
  },
  methods: {
    getManuScript: function(getUrl) {
      window.open(getUrl, '_blank')
    },
    getCopyRightForm: function(getForm) {
      window.open(getForm, '_blank')
    }
  }
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}

.indexingImage  {
  height: 250px;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.indexingImage {
 margin:10px;
 
}
.btn {
  color:#0000ff;
  border:1px solid #0000ff ;
  background-color: #fff;
}
.btn:hover {
  color:#fff;
  background-color: #0000ff;
}
  
</style>
