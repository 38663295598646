<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Current Issue" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-2">
            <div class="paperlist-container">
              <div class="row paperBox mb-3" v-for="paper in papersList" :key="paper.paper_uniqueid">
                <div class="col-9 p-3">
                  <h4>{{ paper.paper_title }}</h4>
                  <p><b>Author:</b> {{ paper.paper_author }}</p>
                  <p><b>Article Type:</b> {{ paper.paper_articletype }}</p>
                  <p><b>Pages:</b> {{ paper.paper_pages }}</p>
                </div>
                <div class="col-3 p-3">
                  <b-button class="col-8 mb-2" variant="outline-primary" size="sm"
                    @click="viewPaperDetails(paper.paper_id)">Paper Details</b-button>
                  <b-button class="col-8 mb-2" variant="outline-primary" size="sm"
                    @click="downloadPDF(paper.paper_url)">Download PDF</b-button>
                  <b-button class="col-8" variant="outline-primary" size="sm"
                    @click="googleSearch('https://www.google.com/search?q=' + paper.paper_title)">Google</b-button>
                </div>
              </div>
            </div>
            <div class="paperdetails-container" v-if="paperPage">
              <div class="row paperBox mb-4 p-3">
                <div class="header-container row">
                  <h4 class="col-10 pr-2">{{ paperDetails.paper_title }}</h4>
                  <div class="col-2">
                    <b-button variant="outline-primary" size="sm" @click="downloadPDF(paperDetails.paper_url)">Download
                      PDF</b-button>
                  </div>
                </div>
                <div class="details-container mt-4 row">
                  <div class="author-details pb-3">
                    <p><b>Author(s) : </b>{{ paperDetails.paper_author }}</p>
                  </div>
                  <div class="submit-details pb-3">
                    <p><b>Submission-Year,Month : </b>{{ paperDetails.year }}, {{ paperDetails.month }}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Pages : </b>{{ paperDetails.paper_pages }}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Article Type : </b>{{ paperDetails.paper_articletype }}</p>
                  </div>
                  <div class="author-details">
                    <p><b>DOI : </b>{{ paperDetails.paper_doi }}</p>
                  </div>
                </div>
              </div>
              <div class="row p-3 paperBox">
                <h4 class="mb-2">Abstract</h4>
                <p class="abstract-details">
                  {{ paperDetails.paper_abstract }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
      years: [],
      currentYear: null,
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
      currentVolume: null,
      currentIssue: null
    };
  },
  methods: {
    getArchivesData: function () {
      this.api.getDataModule("archives/1/getarchives", "journals/v1/").then(
        (res) => {
          this.archives = res.data.archives;
          const years = Object.keys(this.archives);

          // Get the last year
          const lastYear = years[years.length - 1];

          // Get the last entry
          const lastEntry = {
            [lastYear]: this.archives[lastYear]
          };

          console.log(lastEntry);
          for (const year in lastEntry) {
            for (const volume in lastEntry[year]) {
              this.currentVolume = volume
              const issuesArray = lastEntry[year][volume];
              const lastIssue = issuesArray[0];
              this.currentIssue = lastIssue
              
            }
          }
          this.sortData();
          this.getPapersList()
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getPapersList: function () {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperslist",
          "journals/v1/",
          "year=" + this.currentYear + "&volume=" + this.currentVolume + "&issue=" + this.currentIssue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
            console.log(this.papersList)
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails: function (paperid) {
      this.api
        .getDataModulePagination(
          "archives/1/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues: function (val) {
      return val.sort().reverse();

    },
    sortData: function () {
      this.years = Object.keys(this.archives).sort().reverse();
      this.currentYear = this.years[0]
    },
    viewIssueData: function (year, volume, issue) {
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails: function (paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF: function (paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, '_blank');
    }
  },
  watch: {
    $route: function () {
      if (
        !this.paperPage &&
        this.cYear == this.$route.query.year &&
        this.cVolume == this.$route.query.volume &&
        this.cIssue == this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
    },
  },
  mounted: function () {
    this.getArchivesData();
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else if ("year" in this.$route.query &&
      "issue" in this.$route.query &&
      "volume" in this.$route.query) {
      this.viewIssueData(this.$route.query.year, this.$route.query.volume, this.$route.query.issue)
    } else {
      this.listVisible = false;
      this.paperPage = false;
      console.log('here')
    }
  },
};
</script>

<style scoped>
.issueButton {
  cursor: pointer;
}

.box {
  background: #0000ff;
  border-radius: 8px;
}

.box p {
  color: #fff;

  text-align: center;
  padding: 6px;
}

.btn-outline-primary {
  color: #0000ff;
  border-color: #0000ff;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0000ff;
  background: #0000ff;
}

.paperBox {
  /* border: 2px solid #EEEDE7; */
  word-wrap: break-word;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

p {
  margin-bottom: 5px;
  text-align: left;
}

h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.abstract-details {
  white-space: pre-wrap;
}

@media screen and (max-width:991px) {
  .btn-sm {
    font-size: 12px !important;
  }
}

@media screen and (max-width:512px) {
  .btn-sm {
    font-size: 10px !important;
  }
}
</style>
