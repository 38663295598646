<template>
  <div class="choose-area-two ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="choose-content">
            <div class="section-title text-left">
              <span class="sub-title">Promotions</span>
              <h2>How to promote your work</h2>
              <p>
                We use our global networks and advanced technology to optimize the reach of your international journal paper, book, book series or case study. But when it comes to getting your work seen, read, used and cited, you can play a crucial role. After all, no-one is better placed to explain the results than the author. We’ve drawn together some top tips to get you started.
              </p>
            </div>

            <div class="choose-text">
              <i class="bx bx-badge-check"></i>
              <h4></h4>
              <p>
                Do you teach or speak at events or conferences? Feature a slide at the end of your lectures and presentations.
              </p>
            </div>

            <div class="choose-text">
              <i class="bx bx-badge-check"></i>
              <h4></h4>
              <p>
                Spread the word within your networks – post information about your published research to relevant contact lists, forums, associations, and listservs.
              </p>
            </div>

            <div class="choose-text">
              <i class="bx bx-badge-check"></i>
              <h4></h4>
              <p>
                Think your research journal paper might have relevance outside the academic community? Consider creating a press release. 
              </p>
            </div>

          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="choose-image">
            <img src="../../assets/images/promote.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutstandingDigitalExperience",
};
</script>
