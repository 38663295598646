<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Blog Details" />
        <BlogDetailsContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogDetailsContent from '../BlogDetails/BlogDetailsContent'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        BlogDetailsContent,
        Footer,
    },
}
</script>