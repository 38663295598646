<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Aim & Scope" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJEEE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container ">
              <div class="row pt-4">
                <h3>Aim :</h3>
                <div>
                  <div class="aim row">
                    <div class="col-lg-7 col-md-12 col-sm-12 ">
                      <p class="ps-3">Indian journal of electrical and electronics engineering (INDJEEE) publishes papers
                        that report advances from across the rapidly moving fields of electrical engineering. Reflecting
                        the prevalence of electrical systems and devices in our everyday lives and advanced technological
                        environments, the journal welcomes submissions relating to their implementation in all settings
                        and industries. Studies may relate to the design, production, operation, and testing of electrical
                        devices and systems. Authors should submit to one of the following subject areas: power
                        generation, transmission and conversion, electrical machinery, control theory and industrial
                        controls, robotics, electrical transportation equipment, insulation, solar energy, high-power
                        semiconductors, and economic and environmental energy production and distribution.</p>
                    </div>
                    <div class="image col-lg-4 col-md-12 col-sm-12">
                      <img class="mt-5" src="../../../assets/images/AboutUs/aimscopeTwo.png" />
                    </div>
                    <div>
                      <p class="ps-3 mt-3">Please just send to the most suitable one if your article falls in between
                        these categories or lies at their intersection with another branch of engineering. Along with
                        publishing original research, INDJEEE also publishes specialized review papers that assess the
                        current state of the art, pinpoint new trends, and offer recommendations for areas that are still
                        in the early stages of development.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjeee/Guidance-For-Ems-Indjeee"},
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

ul li {
  list-style: none;
}

.mission-of-journal ul li {
  margin-left: -12px;
}</style>
