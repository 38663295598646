<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="FAQs" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJEEE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row pt-4 faq">
                <h3>Frequently asked questions :</h3>
                <div>
                  <div class="FAQs ps-3">
                    <p><b>Q-1: What is the ISSN of the journal?</b></p>
                    <p>Ans : ISSN-Applied</p>
                    <p>
                      <b>Q-2 : When it was started? | when first issue of the
                        journal was published?</b>
                    </p>
                    <p>
                      Ans: It was started in year 2024. Indian Journal of Electrical and Electronics Engineering is being
                      published 5th Dimension Research Publication. . First Issue: January - April 2024
                    </p>
                    <p><b>Q-3 : Is it open access journal?</b></p>
                    <p>Ans : Yes, it is an open access journal.</p>
                    <p><b>Q-4 : Is it peer reviewed journal?</b></p>
                    <p>Ans : Yes, it is peer reviewed journal.</p>
                    <p>
                      <b>Q-5 : How much time you will take to resolve a
                        query?</b>
                    </p>
                    <p>Ans : It will be resolved within 24 hours.</p>
                    <p><b>Q-6 : What is the plagiarism policy?</b></p>
                    <p>
                      Ans : We do not accept papers that are having plagiarized
                      contents. Good quality plagiarism software/ tool will be
                      used to check similarity that would not be more than 30%
                      including reference section. In the case of exclusion of
                      references, it should be less than 5%.
                    </p>
                    <p>
                      <b>Q-7 : Which software is referred for plagiarism/
                        similarity check?</b>
                    </p>
                    <p>
                      Ans : Turnitin software/ithendicate/Plagiarism checker X
                    </p>
                    <p><b>Q-8 : Can I send only abstract for review?</b></p>
                    <p>
                      Ans : No, you have to send full length paper for review
                      process.
                    </p>
                    <p>
                      <b>Q-9 : Is it compulsory to come for reading/presenting
                        the paper in PPT?</b>
                    </p>
                    <p>
                      Ans : No, there is no need to come here for
                      reading/presenting your paper. Your paper is sufficient
                      for all purposes. Send your paper for review process. Once
                      your paper is accepted by the editorial board of committee
                      of the journal, we will send you notification of your
                      paper over the email along with further guidelines for the
                      publication of your paper.
                    </p>
                    <p>
                      <b>Q-10 : How much time will you take for review
                        process?</b>
                    </p>
                    <p>
                      Ans : It depends on reviewer response. It will take min 01 to 07days.
                    </p>
                    <p>
                      <b>Q-11 : My paper has been published. Can make any
                        changes now in the paper?</b>
                    </p>
                    <p>
                      Ans : Author (s) can make rectification in the final paper
                      but after the final submission to the journal,
                      rectification is not possible.
                    </p>
                    <p>
                      <b>Q-12 : What are the terms and conditions to withdraw my
                        paper?</b>
                    </p>
                    <p>
                      Ans : Terms and conditions are available in the ‘Ethics &
                      Policy’ page. Please check the journal website. If once
                      the paper is published it is not possible to withdraw the
                      paper.
                    </p>
                    <p>
                      <b>Q-13 : Is any instructions/ Guidelines for authors?</b>
                    </p>
                    <p>
                      Ans :Yes, It is available in the ‘‘Ethics & Policy’ page.
                      Please visit journal website.
                    </p>
                    <p>
                      <b>Q-14 : What are the Terms & Conditions of the
                        journal?</b>
                    </p>
                    <p>
                      Ans : Please visit journal website. It is available in the
                      ‘‘Ethics & Policy’ page
                    </p>
                    <p><b>Q-15 : Is any payment guideline available?</b></p>
                    <p>
                      Ans : Journal does not take any kind of publication
                      fee/acceptance fee/ review fee/handling fee/processing
                      fee. It is free journal
                    </p>
                    <p>
                      <b>Q-16 : Is any paper publication fee/ handling fee?</b>
                    </p>
                    <p>
                      Ans : No, it’s free, Journal does not take any kind of
                      publication fee/acceptance fee/ review fee/handling
                      fee/processing fee. It is free journal.
                    </p>
                    <p>
                      <b>Q-17 : My article has been published but there is some
                        correction. What did I do? | My article has been
                        published but I want some changes. Is it possible?</b>
                    </p>
                    <p>
                      Ans : Author (s) can make rectification in the final paper
                      but after the final submission to the journal,
                      rectification is not possible.
                    </p>
                    <p>
                      <b>Q-18 : Is the scam, fake, predatory, standalone?</b>
                    </p>
                    <p>
                      Ans : No, this journal is not a fake journal. It is 100%
                      legal, registered and recognized journal. If you have
                      proof of it then please write me. We will give
                      verification and validation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        { id: 4, name: "Guidance of EMS", to: "/indjeee/Guidance-For-Ems-Indjeee" },
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

ul li {
  list-style: none;
}

.mission-of-journal ul li {
  margin-left: -12px;
}
</style>
