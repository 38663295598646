<template>
  <div class="pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <h2>FDRP Journal Publishing Process</h2>
        <p>
          We know it’s important to you to have your research published as quickly as possible. Our journal paper publication offers an article to be typeset, proofed and published online in its final and citable form within an average day of acceptance.
        </p>
      </div>

      <div class="row">
        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverOne"
          @mouseleave="hoverOneLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-bullseye"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Submit</h3>
            <p>
              submit your international journal research paper through your chosen journal’s online submission site. You will need to register for an account to do this. Once your account is set up, you will be guided through a five-step submission process<span v-if="!submit" class="readMore">&nbsp;Read more...</span>
              <span v-if="submit"
                >Once your account is set up, you will be guided through a
                five-step submission process.</span
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverTwo"
          @mouseleave="hoverTwoLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-bar-chart-alt-2"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Editor Check</h3>
            <p>
              The international free research journal paper editor will then read your paper to see if it works with the aims and scope of the journal. Suppose you have submitted to an academic journal. In that case, they will enter it into a rigorous,
              <span v-if="!editorCheck" class="readMore">&nbsp;Read more...</span>
              <span v-if="editorCheck"
                >double-anonymous peer review process that considers your paper's quality, originality, approach, and clarity.</span
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverThree"
          @mouseleave="hoverThreeLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-check-shield"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Decision</h3>
            <p>
              Once the reviewers have read your international research journal paper, they will make one of the following recommendations: Tolerate, Minor revisions, Major 
              <span v-if="!decision" class="readMore">&nbsp;Read more...</span>
              <span v-if="decision">
                revisions, or reject. The final decision lies with the editor. Reviewer feedback on the paper will be delivered to the corresponding author.</span
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverFour"
          @mouseleave="hoverFourLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-bar-chart-alt-2"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Revisions</h3>
            <p>
              You resolve to receive an email from the Editor containing the final decision. If you are asked to complete revisions, you have a week 
              <span v-if="!revisions" class="readMore">&nbsp;Read more...</span>
              <span v-if="revisions"
                >to resubmit for minor revisions and a couple of weeks to resubmit for significant revisions. Once you have resubmitted your journal paper, it will be reassigned to the same reviewers to check whether their comments have been addressed.</span
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverFive"
          @mouseleave="hoverFiveLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-search-alt"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Typesetting</h3>
            <p>
              Once accepted, your paper is immediately processed by our in-house team, who check the copyright forms and whether 
              <span v-if="!typeseting" class="readMore">&nbsp;Read more...</span>
              <span v-if="typeseting"
                >third-party permissions (if applicable) have been provided. Your international journal paper is sent to our typesetting team, who will perform further quality checks.</span
              >
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6"
          @mouseover="hoverSix"
          @mouseleave="hoverSixLeave"
        >
          <div class="service-card-one gray-bg">
            <div class="icon">
              <i class="bx bx-mail-send"></i>
              <i class="bx bxs-badge-check check-icon"></i>
            </div>
            <h3>Publication</h3>
            <p>
              When an international journal paper issue is available for your article to be published, the typeset files will be reworked by the typesetting 
              <span v-if="!publication" class="readMore">&nbsp;Read more...</span>
              <span v-if="publication"
                >team to add pagination, volume, and issue details.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurService",
  data() {
    return {
      name: "Readmore",
      submit: false,
      editorCheck: false,
      decision: false,
      revisions: false,
      typeseting: false,
      publication: false,
    };
  },
  methods: {
    hoverOne() {
      this.submit = true;
    },
    hoverOneLeave() {
      this.submit = false;
    },
    hoverTwo() {
      this.editorCheck = true;
    },
    hoverTwoLeave() {
      this.editorCheck = false;
    },
    hoverThree() {
      this.decision = true;
    },
    hoverThreeLeave() {
      this.decision = false;
    },
    hoverFour() {
      this.revisions = true;
    },
    hoverFourLeave() {
      this.revisions = false;
    },
    hoverFive() {
      this.typeseting = true;
    },
    hoverFiveLeave() {
      this.typeseting = false;
    },
    hoverSix() {
      this.publication = true;
    },
    hoverSixLeave() {
      this.publication = false;
    },
  },
};
</script>

<style scoped>
.readMore {
  color:#0000ff;
  font-weight: 600;
}
</style>
