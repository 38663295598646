<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Aim & Scope" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="aim row pt-4 gy-4">
                <div>
                  <h3>Aim :</h3>
                  <div class="row">
                    <div class="col-sm-9 col-md-9 col-lg-7">
                      <p class="ps-3">
                        The Indian journal of computer science and technology (INDJCST) is a scholarly and peer-reviewed
                        journal dedicated to providing scientists, engineers, and technicians with the latest developments
                        in computer science. The journal offers a window into the most recent discoveries in four
                        categories, namely, computing (computing theory, scientific computing, cloud computing,
                        high-performance computing, numerical and symbolic computing), system (database systems, real-time
                        systems, operating systems, warning systems, decision support systems, information processes, and
                        systems, systems integration), intelligence (robotics, bio-informatics, web intelligence,
                        artificial intelligence), and application (security, networking, software engineering, pattern
                        recognition, e-science, and e-commerce, signal and image processing, control theory and
                        applications). The editorial board welcomes original, substantiated papers on the above topics,
                        which could provoke new science interest and benefit those devoted to computer science.
                      </p>
                    </div>
                    <div class="col-sm-5 col-md-5 col-lg-5 mt-5">
                      <img src="../../../assets/images/AboutUs/aimscopeFour.png" alt="image" />
                    </div>
                  </div>
                </div>
                <div class="focus-scope">
                  <h5>Focus and scope :</h5>
                  <div class="ps-3">
                    <p>
                      The INDJCST welcomes original research papers, technical notes, and review articles on the following
                      disciplines:
                    </p>
                    <div class="row">
                      <div class="col">
                        <ul>
                          <li>
                            <p>E-Science And E-Commerce</p>
                          </li>
                          <li>
                            <p>Signal And Image Processing</p>
                          </li>
                          <li>
                            <p>Control Theory And Applications</p>
                          </li>
                          <li>
                            <p>Computing Theory</p>
                          </li>
                          <li>
                            <p>Scientific Computing</p>
                          </li>
                          <li>
                            <p>Cloud Computing</p>
                          </li>
                          <li>
                            <p>High-Performance Computing</p>
                          </li>
                          <li>
                            <p>Numerical And Symbolic Computing</p>
                          </li>
                          <li>
                            <p>Database Systems</p>
                          </li>
                          <li>
                            <p>Real-Time Systems</p>
                          </li>
                          <li>
                            <p>Operating Systems</p>
                          </li>
                          <li>
                            <p>Warning Systems</p>
                          </li>
                        </ul>
                      </div>
                      <div class="col">
                        <ul>
                          <li>
                            <p>Decision Support Systems</p>
                          </li>
                          <li>
                            <p>Information Processes And Systems</p>
                          </li>
                          <li>
                            <p>Systems Integration</p>
                          </li>
                          <li>
                            <p>Robotics</p>
                          </li>
                          <li>
                            <p>Bio-Informatics</p>
                          </li>
                          <li>
                            <p>Web Intelligence</p>
                          </li>
                          <li>
                            <p>Artificial Intelligence</p>
                          </li>
                          <li>
                            <p>Security</p>
                          </li>
                          <li>
                            <p>Networking</p>
                          </li>
                          <li>
                            <p>Software Engineering</p>
                          </li>
                          <li>
                            <p>Pattern Recognition</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}</style>
