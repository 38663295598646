<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            Peer Review Policy
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div>
              <p>
                International Journal of Innovative Research in Engineering
                (IJIRE) (Online), a Bi-monthly international journal for
                publishing new ideas founded by academicians, educationists,
                engineers, and corporate people. The research results and
                fundamental advancement are all aspects of Innovative Research
                in Advanced Engineering & Technology, various engineering
                disciplines, etc. IJIRE is a scholarly open, accessible, 
                 Peer Review Journal that helps an academic person and the
                student community. IJIRE provides the academic community and
                industry for submitting original research and applications
                related to Various Engineering disciplines and Advanced
                Technologies.
              </p>
            </div>
            <div>
              <p>
                The function of reviewers in the scholarly publication is
                crucial. IJIRE employs a peer review procedure,
                which means that throughout the review process, both the names
                of the reviewer(s) and author(s) are kept secret from one
                another. As a result, neither the author(s) nor the reviewer(s)
                of the document will be able to determine the name of the other
                (s). Peer review enhances networking opportunities within
                research groups and assists in validating research by
                establishing a framework for its evaluation. Despite critiques,
                peer review is still the only commonly used strategy for study
                validation.
              </p>
            </div>
            <div>
              <p>
                All submitted papers will be reviewed by a peer
                review process which may take a minimum of 01 to 03 weeks from
                the submission date. We advise all the author(s) not to submit
                the same paper to multiple journals. It would help if you waited
                for the review status of the paper.
              </p>
            </div>
            <div>
              <p>
                <b
                  >IJIRE is committed to expediting the assessment and
                  publication of fully approved papers. To ensure a high-quality
                  publishing, all entries are subjected to a stringent review
                  procedure. The following are the characteristics of the peer
                  evaluation process:
                </b>
              </p>
              <p>
                Simultaneous submissions of the same manuscript to different
                journals will not be tolerated.
              </p>
              <p>
                Manuscripts with contents outside the scope will not be
                considered for review.
              </p>
              <p>
                Papers will be refereed by at least 3 or 4 experts (reviewers)
                as suggested by the editorial board, of which 01 from India and
                the rest 02 or 03 from overseas.
              </p>
              <p>
                In addition, Editors will have the option of seeking additional
                reviews when needed.
              </p>
              <p>
                Authors will be informed when Editors decide further review is
                required. The journal's Editors-in-Chief make all publication
                decisions based on the referees' reports (reviewers' reports).
              </p>
              <p>
                Authors of papers that are not accepted are notified promptly.
              </p>
              <p>
                All manuscripts received are handled as confidential records.
                All articles submitted will be reviewed by a review
                procedure.
              </p>
              <p>
                All manuscripts submitted for publication in IJIRE were
                cross-checked for plagiarism software. Manuscripts found to be
                plagiarized during the initial stages of review are outrightly
                rejected and not considered for publication in the journal.
              </p>
              <p>
                The Editor-in-Chief will launch a preliminary inquiry if a work
                is discovered to have been plagiarized after it has been
                published, perhaps with the aid of a pertinent group that has
                been assembled. The publication will contact the author's
                Institute/College/University and Funding Agency if any if the
                paper is discovered to have excessive levels of plagiarism.
              </p>
            </div>
            <h6 class="pt-3">
              IJIRE strictly uses following peer review process
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>