<template>
  <div class="seo-featured-services pt-100 pb-70 gray-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="section-title text-left">
            <span class="sub-title">We make a difference in what we do</span>
            <h2>Research that makes a difference</h2>
            <p>
              We publish mission-driven engineering, technology, and scientific
              research that addresses pressing societal issues in line with the
              future. In order to free research paper publication that affects
              thinking, changes policies, and positively impacts lives outside
              of academia, we cooperate with academics who work across
              disciplines and nations as well as with industry
            </p>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="row">
            <div class="col-sm-6 col-lg-6">
              <div class="service-card-one bg-white center">
                <div class="icon">
                  <i class="bx bx-conversation"></i>
                  <i class="bx bxs-badge-check check-icon"></i>
                </div>
                <h3>Broad Scope</h3>
                <p>
                  Your work will impact international journal researchers
                  outside your field and potentially increase exposure.
                </p>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="service-card-one bg-white center">
                <div class="icon">
                  <i class="bx bx-bar-chart-alt-2"></i>
                  <i class="bx bxs-badge-check check-icon"></i>
                </div>
                <h3>Open Access</h3>
                <p>
                  With open access, any international journal researcher can
                  read and create on the findings of others without impediment
                  and without paying any fee, accelerating the author's
                  discoveries among all.
                </p>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="service-card-one bg-white center">
                <div class="icon">
                  <i class="bx bx-search-alt"></i>
                  <i class="bx bxs-badge-check check-icon"></i>
                </div>
                <h3>Indexed</h3>
                <p>
                  Increase your work's visibility, availability, and readership
                  online, which attracts good citations through international
                  journal papers.
                </p>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="service-card-one bg-white center">
                <div class="icon">
                  <i class="bx bx-mail-send"></i>
                  <i class="bx bxs-badge-check check-icon"></i>
                </div>
                <h3>Peer-Reviewed</h3>
                <p>
                  All articles are evaluated using standards, including the
                  international research publication or ideas' excellence,
                  novelty, and significance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAwesomeService",
};
</script>
