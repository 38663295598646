<template>
    <div>
        <div :class="['navbar-area', {'is-sticky': isSticky}]">
            <div class="bionix-nav">
                <div class="container"> 
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img src="../../assets/images/fdrpnewlogo.png" alt="logo">
                        </router-link>

                        <div  
                            class="navbar-toggler"
                            @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ 'active': button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ show: active }">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link">Home</router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/aboutus" class="nav-link">About Us</router-link>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/journals" class="nav-link">Journals</router-link>
                                </li>

                                <!-- <li class="nav-item">
                                    <router-link to="/CallForPaper" class="nav-link">Call for Paper</router-link>
                                </li> -->

                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        For Authors<i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link to="/instruction-authors" class="nav-link">Instruction for Authors</router-link>
                                        </li>
                                        <!--
                                        <li class="nav-item">
                                            <router-link to="/indexing-abstracting" class="nav-link">Indexing & Abstracting</router-link>
                                        </li>
                                        -->
                                    </ul>
                                </li>

                                <!-- <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Blog <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link to="/blog-one" class="nav-link">Blog Grid</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/blog-two" class="nav-link">Blog Right Sidebar</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/blog-details" class="nav-link">Blog Details</router-link>
                                        </li>
                                    </ul>
                                </li> -->

                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link">Contact Us</router-link>
                                </li>
                            </ul>
                        </div>

                    </nav>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            isSearch: false,
            isMenu: false,
            active: false,
            button_active_state: false,
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        }
    },
    methods: {
        isSearchMethod(isSearch){
            return this.isSearch = !isSearch
        },
        isMenuMethod(isMenu){
            return this.isMenu = !isMenu
        }
    }
}
</script>
<style scoped>

</style>
