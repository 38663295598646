<template>
    <div class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>

                        <ul class="footer-contact-info">
                            <li>
                                <i class="flaticon-phone-call"></i>
                                <span>Mon to Fri : 10AM to 08PM (IST)</span>
                                <a href="tel:1235421457852">+91 98405 21421</a>
                            </li>
                            <li>
                                <i class="flaticon-email"></i>
                                <span>Have a Question?</span>
                                <a href="mailto:hello@fdrpjournals.org">support@fdrpjournals.org</a>
                            </li>
                           
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Quick Links</h3>

                        <ul class="footer-quick-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/instruction-authors">For Authors</router-link></li>
                            <li><router-link to="/aboutus">About Us</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                            <li><router-link to="/journals">Journals</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-footer-widget pl-5">
                        <h3>Journals</h3>

                        <ul class="footer-quick-links">
                            
                                <li><router-link to="/ijire">IJIRE</router-link></li>
                                <li><router-link to="/ijsreat">IJSREAT</router-link></li>
                                <li><router-link to="/ijrtmr">IJRTMR</router-link></li>
                                <li><router-link to="/indjeee">INDJEEE</router-link></li>
                                <li><router-link to="/indjece">INDJECE</router-link></li>
                                <li><router-link to="/indjcst">INDJCST</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <p>&copy; {{currentYear}} Fifth Dimension Research Publication. Made with &nbsp;<i class="bi bi-heart-fill" style="color: red;"></i> &nbsp;in India by <a href="https://flaptics.io/" target="_blank">Flaptics</a></p>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <ul>
                            <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>

<style scoped>
</style>
