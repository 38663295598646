<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Indexing and Abstracting" />
        <IndexingAbstracting />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import IndexingAbstracting from '../Authors/IndexingAbstracting'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
        IndexingAbstracting,
        Footer,
    }
}
</script>
