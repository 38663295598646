<template>
  <div>
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=6">
                <button class="default-btn mr-3">Submit your Article<span></span></button>
              </a>
                </div>
              </div>
              <div class="row gy-4 pt-4">
                <h3>Welcome To INDJCST :</h3>
                <div>
                  <div class="ps-3">
                    <p>
                      The Indian journal of Computer Science and Technology (INDJCST) (ISSN 2583-5300) is a scholarly
                      and peer-reviewed journal dedicated to providing scientists, engineers, and technicians with the
                      latest results in computer science. The journal offers a window into the most current discoveries in
                      four categories, namely, computing (computing theory, scientific computing, cloud computing,
                      high-performance computing, numerical and symbolic computing), system (database systems, real-time
                      systems, operating systems, warning systems, finding support systems, information processes, and
                      systems, systems integration), intelligence (robotics, bio-informatics, web intelligence, artificial
                      intelligence), and application (security, networking, software engineering, pattern recognition,
                      e-commerce, and e-science, control theory and applications, signal and image processing). The
                      editorial board welcomes original, substantiated papers on the above topics, which could prompt new
                      science interest and benefit those reserved for computer science.
                    </p>
                  </div>
                </div>
                <div class="particulars">
                  <h5>Journal particulars :</h5>
                  <div class="row">
                    <div class="col">
                      <ul>
                        <li>
                          <p>
                            <b>Title :</b>Indian Journal of Computer Science and
                            Technology
                          </p>
                        </li>
                        <li>
                          <p><b>Frequency :</b>3 issues per year</p>
                        </li>
                        <li>
                          <p><b>ISSN :</b>2583-5300</p>
                        </li>
                        <li>
                          <p>
                            <b>Publisher :</b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Editor-in-Chief :</b>Dr.S.Sivaganesan (Professor
                            & Head)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Copyright :</b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p><b>Starting Year :</b>2022</p>
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <ul>
                        <li>
                          <p>
                            <b>Subject :</b> Computer Science and Technology
                          </p>
                        </li>
                        <li>
                          <p><b>Language :</b>English</p>
                        </li>
                        <li>
                          <p><b>Publication Format :</b>Online</p>
                        </li>
                        <li>
                          <p><b>Email Id :</b>editorinchief@indjcst.com</p>
                        </li>
                        <li>
                          <p><b>Mobile No :</b>+91 9840521421</p>
                        </li>
                        <li>
                          <p><b>Website :</b>www.indjcst.com</p>
                        </li>
                        <li>
                          <p>
                            <b>Address :</b> Fifth Dimension research
                            Publication (P) Ltd. No: 38D5F, Rajaji nagar,
                            Ariyalur-621713. Tamilnadu, India.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="container mt-1"> 
                  <h5 class="pb-2">Why Choose INDJCST Journal :</h5>
                  <div class="row"> 
                    <div class="col-lg-6 col-sm-12 col-md-12"> 
                      <ul> 
                        <li><p><b>Publishing Frequency:</b>3 Issue per year</p></li>
                        <li><p><b>Peer reviewed refereed :</b></p>Journal</li>
                        <li><p>Easy, fast, transparent paper publication process</p></li>
                        <li><p>Registered with Ministry of MSME, Govt. of India</p></li>
                        <li><p>Open Access Journal</p></li>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-12"> 
                      <ul> 
                        <li><p>Strict Plagiarism policy</p></li>
                        <li><p>Simple stpes for publication of research and review articles</p></li>
                        <li><p>High Visibility & Indexing</p></li>
                        <li><p><b>Publication Ethics :</b> COPE's best practice guideliness</p></li>
                        <li><p><b>Publisher License under:</b> creative commons by NC ND</p></li>
                      </ul>
                    </div>
                  </div>
                  </div>
                  <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=6">
                <button class="default-btn mr-3">Submit your Article<span></span></button>
              </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ProjectsDetailsContent",
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
  
<style scoped lang="scss">
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

.particulars ul li {
  padding-bottom: 5px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.why-choose {
  padding-bottom: 5px;
}

.containerbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 120px 50px 120px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;

  .imageBox {
    width: 120px;
    height: auto;
    margin-right: 50px;
    border-radius: 5px;
  }
}

@media screen and (max-width:1199px) {
  .containerbox {
    flex-direction: column;

    .imageBox {
      margin-bottom: 50px;
    }

    .contentBox {
      margin-left: 0px !important;
    }
  }
}</style>
  