<template>
    <div class="fun-facts-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-fun-facts">
                        <h3>2500<span class="sign-icon">+</span></h3>
                        <p>Papers Published</p>
                        <div class="back-text">P</div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-fun-facts">
                        <h3>100<span class="sign-icon">%</span></h3>
                        <p>Peer Reviewed</p>
                        <div class="back-text">D</div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-fun-facts">
                        <h3>6</h3>
                        <p>Journals</p>
                        <div class="back-text">J</div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-fun-facts">
                        <h3>250<span class="sign-icon">+</span></h3>
                        <p>Editorial Members</p>
                        <div class="back-text">E</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FunFacts'
    }
</script>
