<template>
    <div>
      <Navbar />
      <PageTitle pageTitle="Editorial Board"/>
      <div class="journal-page-area pb-100 " style="padding-top: 50px;">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-lg-3">
              <div class="menu-wrapper">
                <div class="menu-header">
                  <h4>IJRTMR</h4>
                </div>
                <ul class="no-style">
                  <li v-for="sublink in links" :key="sublink.id">
                    <i class="fas fa-chevron-right"></i>
                    <router-link :to="sublink.to">
                      {{ sublink.name }}
                      <span></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-9 col-md-9 mt-4 ps-4">
              <div class="containers" style="display: flex; flex-direction: row; margin-bottom: 20px;"> 
          <div class="editorialBox p-4" style=" margin-right: 2%; width: 49%;"> 
            <h6>Patron/Editor-in-chief :</h6>
            <p><b>Name:</b>  Dr. S. Sivaganesan</p>
            <p>
              <b>Designation:</b>  Professor& Head Department of Electrical &
              Electronics Engineering.
            </p>
            <p>
              <b>Address:</b>  Holymary Institute of Technology &
              Science.Hyderabad-501301.India
            </p>
            <p>
              <b>E-Mail:</b>  editorinchief@ijrtmr.com,<br>
               sivaganesan@hmgi.ac.in
            </p>
          </div>
          <div class="editorialBox p-4" style=" width: 49%;"> 
            <h6>Managing Editor:</h6>
          <p><b>Name:</b>  Dr. Vetriveeran Rajamani</p>
          <p><b>Designation:</b>  Assistant Professor Department of Electronics & Communication Engineering,</p>
          <p><b>Address:</b>  Nalla Malla Reddy Engineering College. Hyderabad-500088.India.</p>
          <p><b>E-Mail:</b>  rajamani.ece@nmrec.edu.in</p>
          </div>
         </div>

         <div class="row editorialBox my-2 mx-1" v-for="(data, index) in membersList" :key="(index)">
          <div class="col-lg-3 col-md-12 col-sm-12 p-3 editorialImage">
            <img :src="data.member_image_url" alt="img"  />
          </div>
          <div class="col p-3">
            <h6>{{ data.member_role }}</h6>
              <p><b>Name:</b>{{ data.member_name }}</p>
              <p><b>Designation:</b> {{ data.member_designation }}</p>
              <p><b>Address:</b> {{ data.member_address }}</p>
              <p><b>E-mail:</b> {{ data.member_email }}</p>
            <p><b>Research Area:</b>{{ data.member_researcharea }}</p>
            <p><b>Profile Link: </b>{{ data.member_website }}</p>
            <p><b>Country:</b> {{ data.member_country }}</p>
          </div>
         </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../../Layout/Navbar.vue"
  import Footer from "../../Layout/Footer";
  import PageTitle from "../../Common/PageTitle";
  export default {
    name: "ProjectsDetailsContent",
    components: {
       Navbar,
      Footer,
      PageTitle,
    },
    data: function () {
      return {
        links: [
        { id: 1, name: "Home", to: "/ijrtmr" },
        { id: 2, name: "Aim & Scope", to: "/ijrtmr/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijrtmr/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijrtmr/GuidanceForEms-Ijrtmr"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijrtmr/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijrtmr/current-issue" },
        { id: 7, name: "Archives", to: "/ijrtmr/archives" },
        {
          id: 8,
          name: "Conference",
          to: "/ijrtmr/conference",
        },
        { id: 9, name: "Editorial Board", to: "/ijrtmr/editorial-board" },
        { id: 10, name: "Topics", to: "/ijrtmr/topics" },
        { id: 11, name: "Call for papers", to: "/ijrtmr/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijrtmr/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijrtmr/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijrtmr/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijrtmr/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijrtmr/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijrtmr/downloads" },
        { id: 18, name: "FAQs", to: "/ijrtmr/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijrtmr/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijrtmr/ijrtmr-contact",
        },
      ],
      membersList:[]
      };
    },
    methods:{
    getMemberData() {
      this.api.getData("editorial/2","journals/v1/").then(
        (res) => {
          this.membersList = res.data.membersList;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getMemberData();
  },
  };
  </script>
  <style scoped lang="scss">
  h3 {
    font-size: 24px;
  }
  .titleMail{
    margin-left: -5px;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
    
  }

  ul li {
    list-style: none;
  }
  
  .mission-of-journal ul li {
    margin-left: -12px;
  }

.contact-address ul li h6 span {
  padding:8px;
  color:#0000ff;
  background: white;
  border-radius: 50px;
  margin:10px;
  font-size: 15px;
}
.contact-address ul li h6  {
  color:white;
}

.numbers {
  margin-left: 50px;
}



.contact-address {
  background: #0000ff;
  margin: 10px;
  color: aliceblue;
  border-radius: 20px;
}

.email {
  margin-left: 50px;
  
}
.email p{
  color:white;
}

.editorialBox {
    /* border: 2px solid #EEEDE7; */
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p{
    text-align:left;
    margin-bottom: 5px;
    
}
.editorialImage img {
  height: 200 !important;
}
.imageBox {
  width: 70%;
  height: auto;
  margin-top: 30px;
}

@media screen and (max-width:992px){
  .containers{
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
  </style>
  
