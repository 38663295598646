<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Paper Status" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div> 
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 p-4">
            <h3 class="pb-3">Author can check the article status by using Author login Credential</h3>
            <div class="m-1">
              <!-- <form>
                <div class="row ps-3">
                  <div class="col-sm-6 col-md-6 col-lg-6 p-1">
                    <input
                      type="text"
                      placeholder="Paper ID (Example: IJIREXXXX)"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 p-1">
                    <input
                      type="text"
                      placeholder="First Author Email ID*"
                      class="form-control"
                    />
                  </div>
                  <div class="container mt-3" style="display: flex; justify-content: center;">
                    <button type="submit" class="btn btn-primary">
                      Track Status
                    </button>
                  </div>
                </div>
              </form> -->
              <div class="row m-1 pt-3">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <img src="../../../assets/images/ijsreatlogin.png"/>
                  <!-- <h5>Paper Info :</h5>
                  <p><b>Paper ID :</b> 1200</p>
                  <p>
                    <b>Title :</b> Series Connected Half Bridge Modular
                    Multilevel Converter Motor Drive System
                  </p>
                  <p><b>Register Mail ID :</b> vandanar@brindavancollege.com</p>
                  <p><b>Research Area :</b> Engineering</p> -->
                </div>
              </div>
              <a href="http://editorial.fdrpjournals.org/login?journal=2" class="d-flex pt-3">
                <button class="default-btn mx-auto">CLICK HERE<span></span></button>
              </a>

              <!-- <div class="container mt-5">
                <div class="row">
                  <div class="col-lg-3 col-md-12 col-sm-12 containerBox m-2">
                    <div class="p-3 ">
                      <div class="row">
                    <div class="col offset-5 pt-4">
                      <i
                        class="bi bi-check-square-fill"
                        style="color: #0000ff"
                      ></i>
                    </div>
                  </div>
                  <div class=" submissionBox p-3">
                    <p style="text-align:center"><b>Submit Paper</b></p>
                  </div>
                    </div>
                  </div> 
                  <div class="col-lg-3 col-md-12 col-sm-12 containerBox m-2">
                    <div class="p-3">
                    
                      <div class="row">
                    <div class="col offset-5 pt-4">
                      <i
                        class="bi bi-check-square-fill"
                        style="color: #0000ff"
                      ></i>
                    </div>
                  </div>
                  <div class=" submissionBox p-3">
                    <p style="text-align:center"><b>Plagiarism Check & Peer-Review</b></p>
                  </div>
                  
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 containerBox m-2">
                    <div class="p-3">
                    
                    <div class="row">
                  <div class="col offset-5 pt-4">
                    <i
                        class="bi bi-check-square-fill"
                        style="color: #0000ff"
                      ></i>
                  </div>
                </div>
                <div class=" submissionBox p-3">
                  <p style="text-align:center"><b>Acceptance Status</b></p>
                </div>
                
                  </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 containerBox m-2">
                    <div class="p-3">
                    
                    <div class="row">
                  <div class="col offset-5 pt-4">
                    <i
                        class="bi bi-calendar3-fill"
                        style="color: #0000ff"
                      ></i>
                  </div>
                </div>
                <div class=" submissionBox p-3">
                  <p style="text-align:center"><b>Processing Charges</b></p>
                </div>
                
                  </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 containerBox m-2">
                    <div class="p-3">
                    
                    <div class="row">
                  <div class="col offset-5 pt-4">
                    <i
                        class="bi bi-calendar3-fill"
                        style="color: #0000ff"
                      ></i>
                  </div>
                </div>
                <div class=" submissionBox p-3">
                  <p style="text-align:center"><b>Copyright Submission</b></p>
                </div>
                
                  </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-3 containerBox m-2">
                    <div class="p-3">
                    
                    <div class="row">
                  <div class="col offset-5 pt-4">
                    <i class="bi bi-star-fill" style="color: #0000ff"></i>
                  </div>
                </div>
                <div class=" submissionBox p-3">
                  <p style="text-align:center"><b>Published</b></p>
                </div>
                
                  </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        {
          id: 8,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 9, name: "Topics", to: "/ijsreat/topics" },
        { id: 10, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 12,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 16,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 17, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>

<style scoped>
.containerBox {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.5s;
}

.containerBox:hover {
  transform: translateY(-5px);
}
</style>