<template>
    <div class="subscribe-area bg-F4F7FC">
        <div class="subscribe-inner-area lets-work">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <span class="sub-title">READY TO DO THIS</span>
                        <h2>Let's get your paper published!</h2>
                    </div>

                    <div class="col-lg-6">
                        <div class="contact-btn">
                            <router-link to="/journals" class="default-btn">
                                Journals <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LetsGetToWork'
    }
</script>
