<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Aim & Scope" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJIRE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4 aim-for-ijire">
                <h3 class="headingThree">Mission & Vision :</h3>
                <div class="mission-container">
                  <h5>Mission & Vision :</h5>
                  <div class="ps-3">
                    <p>
                      The journal aims to be an academically open, accessible, peer-reviewed,
                      multidisciplinary, bi-monthly, and fully refereed journal focused on engineering and technology
                      theories, methodologies, and applications. It publishes refereed original research papers and
                      technical notes in various fields of Engineering and Technology. All articles submitted must
                      disclose initial, previously unreported research findings, whether experimental or theoretical and
                      will be peer-reviewed. Papers submitted to the magazine must fulfill these requirements
                      and cannot be considered for publication elsewhere. The paper should be formatted according to the
                      journal's style and subject to both review and modification. To provide a platform for publishing
                      results and research that include an essential empirical component, to bridge a significant gap
                      between research and practice by encouraging the publication of original, novel, industry-relevant
                      research, and to solicit original and unpublished research papers based on theoretical or
                      experimental works.
                    </p>
                  </div>
                </div>

                <div class="mission-of-journal">
                  <h5>The Mission of the journal is to :</h5>
                  <div>
                    <ul>
                      <li>
                        <p>
                          Distribute original, scientific, theoretical, or practical research in engineering and related
                          domains.
                        </p>
                      </li>
                      <li>
                        <p>
                          By encouraging the publishing of unique, innovative, industry-relevant research, you can bridge
                          the gap between theory and practice that now exists.
                        </p>
                      </li>
                      <li>
                        <p>
                          By encouraging the publishing of unique, cutting-edge research pertinent to the industry, we can
                          bridge the substantial gap between theory and practice.
                        </p>
                      </li>
                      <li>
                        <p>
                          They seek innovative and unpublished research articles for worldwide publication based on
                          theoretical or experimental efforts.
                        </p>
                      </li>
                      <li>
                        <p>
                          Solicit unpublished and unique research papers based on theoretical or experimental research.
                        </p>
                      </li>
                      <li>
                        <p>
                          Publish original, theoretical, and practical advances in Mechanical Engineering, Civil
                          Engineering, Computer Science & Engineering, Textile Engineering, Information Technology,
                          Electrical and Electronics Engineering, Electronics and Telecommunication, and all
                          interdisciplinary streams of Engineering Sciences.
                        </p>
                      </li>
                      <li>
                        <p>
                          Impart a platform for publishing results and research with a vital empirical component.
                        </p>
                      </li>
                      <li>
                        <p>
                          Provide a venue for publishing results and research with an essential empirical component.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="scope mt-2">
                  <h5>Scope :</h5>
                  <div class="ps-3">
                    <p>
                      The International Journal of Innovative Research in Engineering (IJIRE) covers all topics of all
                      engineering branches. Some are Computer Science & Engineering, Information Technology, Electronics &
                      Communication, Electrical and Electronics, Electronics and Telecommunication, Civil Engineering,
                      Mechanical Engineering, Textile Engineering, and all interdisciplinary streams of Engineering
                      Sciences. The main topic includes but is not limited to the following.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijire" },
        { id: 2, name: "Aim & Scope", to: "/ijire/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/ijire/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/ijire/GuidanceForEms"},
        { id: 5, name: "Paper Status", to: "/ijire/paper-status" },
        { id: 6, name: "Current Issue", to: "/ijire/current-issue" },
        { id: 7, name: "Archives", to: "/ijire/archives" },
        {id:8,name:"Conference",to:"/ijire/conference"},
        { id: 9, name: "Editorial Board", to: "/ijire/editorial-board" },
        { id: 10, name: "Topics", to: "/ijire/topics" },
        { id: 11, name: "Call for papers", to: "/ijire/call-for-papers" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijire/instruction-for-authors",
        },

        { id: 13, name: "Ethics & Policies", to: "/ijire/ethics-and-policies" },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijire/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijire/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijire/article-processing-charges",
        },
        { id: 17, name: "Downloads", to: "/ijire/downloads" },
        { id: 18, name: "FAQs", to: "/ijire/FAQs" },
        { id: 19, name: "Peer Review Policy", to: "/ijire/peer-review-policy" },
        { id: 20, name: "Contact Us", to: "/ijire/ijire-contact" },
      ],
    };
  },
};
</script>
<style scoped></style>
