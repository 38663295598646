<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Editorial Process
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p>
              The INDJEEE is committed to editorial independence, and strive in all cases to prevent this principle from
              being compromised through conflicts of interest, fear, or any other corporate, business, financial or
              political influence. We do not discriminate against authors, editors or peer reviewers based on personal
              characteristics or identity. During the editorial process,
            </p>
            <ul>
              <li>
                <p>
                  Each Paper Submitted To Our Journals Is Initially Evaluated By An Editor Covering The Subject Area,
                  Who Will Decide Whether To Send It For Peer Review In Consultation With Experts In Relevant Fields. If
                  The Paper Is Suitable For Consideration By The INDJEEE, The Editor Who Has Been Assigned The Paper Will
                  Select A Minimum Of Two Peer Reviewers For The Paper, After Consulting The Editors Who Have Lately
                  Handled Papers In Relevant Fields. The Editor Must Guarantee The Selection Of Peer Reviewers Who Are
                  Most Qualified And Best Able To Provide A Critical, Expert, And Unbiased Evaluation Of The Paper.
                </p>
              </li>
              <li>
                <p>
                  Editorial Decisions On Each Paper Are Made By The Editor Who Has Been Assigned The Paper Based On The
                  Comments Of The Peer Reviewers. Possible Decisions Include Accept, Minor Revision, Major Revision, And
                  Rejection. The Decisions Are Made Solely Based On The Scientific Merits Of The Content Of The Paper,
                  Regardless Of The Author’s Gender, Sexual Orientation, Religious Or Political Beliefs, Or Their Ethnic
                  Or Geographic Origin. If Your Paper Is Rejected, You Could Transfer All Manuscript Materials, The
                  Acceptance Email, And Reviewers’ Comments To Another INDJEEE Journal Without Restarting The Entire
                  Submission Process.
                </p>
              </li>
              <li>
                <p>
                   The Editor Who Has Been Assigned The Paper Not Divulge Any Information Relating To The Paper To Any
                  Other Person Beyond The Authors And Reviewers. He/she Is Also Ultimately In Charge Of Ensuring The
                  Timely Completion Of The Peer Review And Publication/Rejection Of The Paper. The Editorial Board Of The
                  Journal Are Committed To The Confidentiality Of Papers, Authors, And Reviewers, Ensuring The Necessary
                  Anonymity In The Publishing Process.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}

.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}

.header[data-v-1309cd11] {
  border: none;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}

.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}

.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}

button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}

.accordion-button:focus {
  border: none;
}

.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}</style>