<template>
  <div>
    <Navbar />
    <div class="container mt-5 p-5 termsCondition">
      <h6 class="pt-5">Terms of condition:</h6>
      <p>
        It is a general principle of scholarly communication that the
        editor/guest editor of a learned journal/series is solely and
        independently responsible for deciding which articles submitted to the
        journal shall be published. In making this decision, the editor/guest
        editor is guided by the policies of the journal’s editorial board and
        constrained by such legal requirements in force regarding libel,
        copyright infringement, and plagiarism. An outcome of this principle is
        the importance of the scholarly archive as a permanent, historical
        record of the transactions of scholarship. Articles that have been
        published shall remain extant, exact, and unaltered as far as possible.
        However, occasionally, circumstances may arise where an article is
        published that must later be retracted or removed. Such actions must not
        be undertaken lightly and can only occur under exceptional
        circumstances, such as:
      </p>
      <div class="pt-2">
        <h6>FDRP Policy :</h6>
        <p>
          It is a general principle of scholarly communication that the editor
          of a learned journal is solely and independently responsible for
          deciding which articles submitted to the journal shall be published.
          In making this decision, the editor is guided by the policies of the
          journal’s editorial board and constrained by such legal requirements
          in force regarding libel, copyright infringement, and plagiarism. An
          outcome of this principle is the importance of the scholarly archive
          as a permanent, historical record of the transactions of scholarship.
          Articles that have been published shall remain extant, exact, and
          unaltered as far as possible. However, occasionally, circumstances may
          arise where an article is published that must later be retracted or
          removed. Such actions must not be undertaken lightly and can only
          occur under exceptional circumstances.
        </p>
        <p>
          This policy has been designed to address these concerns and consider
          current best practices in the scholarly and library communities. As
          standards evolve and change, we will revisit this issue and welcome
          the input of scholarly and library communities. These issues require
          international standards, and we will actively lobby various
          information bodies to establish international standards and best
          practices that the publishing and information industries can adopt.
        </p>
      </div>
      <div class="pt-2">
        <h6>Refund Cancellation Policy:</h6>
        <p>
          It is a scholarly communication general concept that the editor/guest
          editor of a learned journal/series is exclusively and independently
          accountable for deciding which papers submitted to the journal will be
          published. In making this choice, the editor/guest editor is led by
          the editorial board's policies and limited by current legal
          requirements regarding libel, copyright violation, and plagiarism. As
          a result of this concept, the academic archive is essential as a
          permanent, historical document of scholarly interactions. Articles
          already released must be kept as accurate and unaltered as feasible.
          However, there are times when a piece must be retracted or deleted
          after it has been released. Such acts should not be done lightly and
          should only be carried out in exceptional situations, such as:
        </p>
      </div>
      <div class="pt-2">
        <h6>Article withdrawal :</h6>
        <p>
          Only used for Articles in Press that represent early versions of
          articles and sometimes contain errors or may have been accidentally
          submitted twice. Occasionally, but less frequently, the articles may
          represent infringements of professional, ethical codes, such as
          multiple submissions, bogus claims of authorship, plagiarism,
          fraudulent use of data, or the like. Articles in Press (articles that
          have been accepted for publication but which have not been formally
          published and will not yet have the complete volume/issue/page
          information) that include errors or are discovered to be accidental
          duplicates of other published article(s) or are determined to violate
          our journal publishing ethics guidelines in the view of the editors
          (such as multiple submission, bogus claims of authorship, plagiarism,
          fraudulent use of data or the like), maybe “Withdrawn” from FDRP
          Journals. Withdrawn means that the article content (HTML and PDF) is
          removed and replaced with an HTML page and PDF stating that the
          article has been withdrawn according to the FDRP Policy on Article in
          Press Withdrawal with a link to the current policy document.
        </p>
      </div>
      <div class="pt-2">
        <h6>Article retraction :</h6>
        <p>
          infringement of professional, ethical standards, such as numerous
          entries, false authorship claims, plagiarism, fraudulent data use, or
          similar offences. On rare occasions, a retraction will be used to fix
          submittal or publish mistakes. It has long been a rare occurrence in
          the academic world for a paper to be retracted by its authors or the
          editor on the advice of other scholars. Several scholarly and library
          organizations have created standards for handling retractions, and
          FDRP has embraced this best practice for paper retractions.
        </p>
        <p>
          A retraction notice titled "Retraction: [article title]," signed by
          the authors and the editor, is released in the paginated section of
          the next journal issue and mentioned in the contents list.
        </p>
        <p>
          In the electronic version, a link is made to the original article.
        </p>
        <p>
          The online article is preceded by a screen containing the retraction
          note. It is to this screen that the link resolves; the reader can then
          proceed to the article itself.
        </p>
        <p>
          The original article is kept in its entirety, with the exception of a
          watermark on each page showing that it has been "retracted." The HTML
          form of the text is no longer available.
        </p>
      </div>
      <div class="pt-2">
        <h6>Article removal: legal limitations:</h6>
        <p>
          In a minimal number of cases, removing an article from the online
          database may be necessary. This will only occur where the article is
          defamatory or infringes others’ legal rights, or where the article is,
          or we have good reason to expect it will be, the subject of a court
          order, or where the article, if acted upon, might pose a severe health
          risk. While the metadata (Title and Authors) will be retained, the
          text will be replaced with a screen indicating the article has been
          removed for legal reasons.
        </p>
      </div>
      <div class="pt-2">
        <h6>Article replacement:</h6>
        <p>
          In cases where the article, if acted upon, might pose a severe health
          risk, the original article's authors may wish to retract the flawed
          original and replace it with a corrected version. In these
          circumstances, the procedures for retraction will be followed with the
          difference that the database retraction notice will publish a link to
          the corrected re-published article and a history of the document.
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
export default {
  components: {
    Navbar,

    Footer,
  },
};
</script>

<style scoped>
.termsCondition {
  text-align: justify;
}
</style>