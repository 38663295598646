<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSeven">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSeven"
            aria-expanded="false"
            aria-controls="flush-collapseSeven"
          >
            Open Access license Policy
          </button>
        </h2>
        <div
          id="flush-collapseSeven"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingSeven"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <div>
              <p>
                <b
                  >International Journal of Innovative Research in Engineering
                  (IJIRE)</b
                >
                is loyal to open access to academic work. All the original
                articles and review papers published in this journal are free to
                access immediately from the publication date. We don’t charge
                any fees for readers to download articles and reviews for their
                educational use.
              </p>
            </div>
            <div>
              <h5 class="pt-3">
                Benefits of open access for authors, include:
              </h5>
              <ul>
                <li><p>Free access for all users worldwide</p></li>
                <li><p>Authors retain copyright to their work</p></li>
                <li><p>Increased visibility and readership</p></li>
                <li><p>Rapid publication</p></li>
                <li><p>No spatial constraints</p></li>
              </ul>
              <p>
                The IJIRE also operates under the Creative Commons License
                CC-BY. This allows for the reproduction of articles, free of
                charge, with the appropriate citation information. All authors
                publishing with the IJIRE accept these as the terms of
                publication.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}
</style>