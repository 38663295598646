<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThirteen">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThirteen"
            aria-expanded="false"
            aria-controls="flush-collapseThirteen"
          >
            Copyright Information
          </button>
        </h2>
        <div
          id="flush-collapseThirteen"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThirteen"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p>
              Once the editorial board informs the author that the paper under
              review is accepted, via email or OJS, it is tacitly approved that
              the copyright of the paper is transferred from the author to the
              journal, no matter whether Copyright Transfer Agreement is signed
              or the formal acceptance letter is offered instantly after the
              acceptance. As soon as the acceptance decision is notified, the
              editorial board will not agree to any request from the author for
              cancellation or removal of the paper.
            </p>
            <p>
              INDJECE will hold copyright on all papers, while the author will
              maintain all other rights including patents and the right to use
              and reproduce material.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}
.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}
.header[data-v-1309cd11] {
  border: none;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}
.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}
.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}
button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}
.accordion-button:focus {
  border: none;
}
.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}
.accordion-button:focus {
  box-shadow: none;
}
</style>