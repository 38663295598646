<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Instruction For Authors" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJECE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4">
                <div class="intruction-for-author">
                  <h3>Instruction For Authors :</h3>
                  <div class="ps-3">
                    <p>
                      Publish with Indian journal of electronics and communication engineering (INDJECE)
                    </p>
                    <h6>Join our community of authors and benefit from the following:</h6>
                  </div>
                  <ul>
                    <li>
                      <p>
                        An Easy-To-Use Manuscript Submission System, Without Manuscript Formatting Requirements.
                      </p>
                    </li>
                    <li>
                      <p>
                        Free Of Charge, Full Language Editing Report At the Point Of Submission, To Help You Assess And
                        Improve Your Manuscript Before Peer Review.
                      </p>
                    </li>
                    <li>
                      <p>
                        Dedicated Editors Who Are Active In Their Specific Communities.
                      </p>
                    </li>
                    <li>
                      <p>
                        High Editorial Standards, Ensuring All Published Manuscripts Undergo An In-Depth Peer Review
                        Process.
                      </p>
                    </li>
                    <li>
                      <p>
                        Quick, Efficient Publication With Full Transparency On All Publishing Metrics And Turnaround
                        Times.
                      </p>
                    </li>
                    <li>
                      <p>
                        Greater Impact, Reach, And Visibility Of Your Research Through Open Access.
                      </p>
                    </li>
                    <li>
                      <p>
                        Retention Of All Ownership And Copyright Of Your Published Research.
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="submission mt-2">
                  <h5>Submission :</h5>
                  <div class="ps-3">
                    <p>
                      One of the manuscript's authors should submit manuscripts through the online submission system for
                      our journals. Only Word (.doc, .docx, .odt, .rtf, .txt) files can be submitted through the
                      manuscript submission system, and there is no page limit. Special characters should not be included
                      in the file name of the main manuscript file. Submissions by anyone other than one of the authors
                      will not be accepted. The submitting author takes responsibility for the manuscript during
                      submission and peer review.
                    </p>
                  </div>
                </div>
                <div class="terms-of-submission :">
                  <h5>Terms of submission :</h5>
                  <div class="ps-3">
                    <p>
                      You must submit manuscripts because they are not to be published elsewhere, and This journal is only
                      being considered. The submitting author is responsible for assuring that all the other co-authors
                      have approved the article's publication. The submitting author is responsible for obtaining all
                      required institutional permissions for the work. The date of receipt may only be verified formally
                      by an acknowledgment from the editing office. Unless otherwise stated, all further correspondence
                      and proofs will be delivered to the author(s) before publication. The authors' permission to edit
                      the work for readability is a submission requirement. All inquiries concerning accepted manuscripts'
                      publication should be addressed to the editor-in-chief/INDJECE. All submissions are bound by FDRP
                      terms of service.
                    </p>
                  </div>
                </div>
                <div class="peer-review">
                  <h3>Peer review :</h3>
                  <div class="ps-3">
                    <p>
                      All submitted articles are subject to assessment and peer review to ensure editorial appropriateness
                      and technical correctness.
                    </p>
                    <h6>Research published in the journal must be :</h6>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <p>
                          Scientifically Valid - Complying With Community Accepted Standards Of Research.
                        </p>
                      </li>
                      <li>
                        <p>Technically Accurate In Its Methods And Results.</p>
                      </li>
                      <li>
                        <p>
                          Representative Of A Specific Advance, Replication, Or Null/Negative Result, Which Is Worthy Of
                          Publication.
                        </p>
                      </li>
                      <li>
                        <p>
                          As reproducible as possible - share underlying data, code, and supporting materials wherever
                          able.
                        </p>
                      </li>
                      <li>
                        <p>
                          Ethically sound and transparent - adhering to best practices for animal and human studies,
                          consent to publish, and explicitly declaring any potential conflicts of interest, both real and
                          perceived.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="ps-3">
                    <p>
                      In the spirit of sharing results through our open science, emphasis is not placed on novelty,
                      interest, or perceived impact. Replication studies, particularly of research published in this
                      journal, are encouraged.
                    </p>
                    <p>
                      An article must fulfill the basic editorial requirements and fall within the journal's purview for
                      the assigned editor to accept it for publishing. Let's say a piece of writing gets approved for
                      publication in the journal. In that situation, the editor will preferably request at least two
                      outside peer reviewers (who will remain anonymous to the authors unless they wish to reveal their
                      name by signing the review report) to evaluate the article before confirming a decision to approve.
                      The editor-in-chief has the final say on submissions that should reject.
                    </p>
                    <p>
                      Our research integrity team occasionally seeks advice outside formal peer review, for example, on
                      submissions with serious ethical, security, biosecurity, or societal implications. We may consult
                      experts and the editor before fixing on appropriate actions, including but not limited to:
                      recruiting reviewers with specific expertise, assessment by additional editors, and declining to
                      consider a submission further.
                    </p>
                  </div>
                </div>
                <div class="article-types">
                  <h5>Article types :</h5>
                  <div class="ps-3">
                    <p>
                      <b>The journal will consider the following article
                        types:</b>
                    </p>
                  </div>
                </div>
                <div class="research-article">
                  <h6>Research article :</h6>
                  <div class="ps-3">
                    <p>
                      Research articles should present the results of an original research study. These manuscripts should
                      describe how to conduct the research project and provide a thorough analysis of the project results.
                    </p>
                  </div>
                </div>
                <div class="reviews">
                  <h6>Reviews :</h6>
                  <div class="ps-3">
                    <p>
                      A review article provides an overview of the published literature in a particular subject area.
                    </p>
                  </div>
                </div>
                <div class="formatting">
                  <h6>Formatting :</h6>
                  <div class="ps-3">
                    <p>
                      An optional research article manuscript template can download from the website. We recommend that
                      all manuscripts include line numbers and follow the structure below:
                    </p>
                  </div>
                </div>
                <div class="title">
                  <h6>Title and authorship information :</h6>
                  <div class="ps-3">
                    <p>The following information should be included:</p>
                  </div>
                  <ul>
                    <li>
                      <p>Manuscript Title</p>
                    </li>
                    <li>
                      <p>Full Author Names</p>
                    </li>
                    <li>
                      <p>Full Institutional Mailing Addresses</p>
                    </li>
                    <li>
                      <p>Email Addresses</p>
                    </li>
                  </ul>
                </div>
                <div class="abstract mt-2">
                  <h6>Abstract :</h6>
                  <div class="ps-3">
                    <p>
                      The manuscript should contain an abstract. The abstract should be 300 words or less, self-contained,
                      and contain citation-free.
                    </p>
                  </div>
                </div>
                <div class="introduction">
                  <h5>Introduction :</h5>
                  <div class="ps-3">
                    <p>This section should be concise, with no subheadings.</p>
                  </div>
                </div>
                <div class="material">
                  <h5>Materials and methods :</h5>
                  <div class="ps-3">
                    <p>
                      The methods section should provide enough detail for others to replicate the study. If you have
                      multiple methods, use subsections with relevant headings, e.g., different models, in vitro and in
                      vivo studies, statistics, materials, reagents, etc.
                    </p>
                    <p>
                      INDJECE has no space restriction on methods. Detailed descriptions of the methods (including
                      protocols or project descriptions) and algorithms may also upload as supplementary information, or a
                      previous publication that gives more details may be cited. If the method from a previous paper is
                      used, then this paper must be cited and discussed. If the wording is reused from a published
                      article, note this must, e.g., This study uses the method of Smith et al., and the description of
                      the method partly produce their wording [1].
                    </p>
                    <p>
                      If a method or tool is introduced in the study, including software, questionnaires, and scales, the
                      license this is available under and any requirement for permission for use should be stated. If an
                      existing method or tool is used in the research, the authors are responsible for checking the
                      license and obtaining any necessary permission. If permission was required, a statement confirming
                      permission was granted should be included in the materials and methods section.
                    </p>
                  </div>
                </div>
                <div class="result">
                  <h5>Results and discussion :</h5>
                  <div class="ps-3">
                    <p>
                      This section may be split into subsections or may be combined.
                    </p>
                  </div>
                </div>
                <div class="main-text">
                  <h5>Main text (review only) :</h5>
                  <div class="ps-3">
                    <p>
                      This section may be divided into subsections or may be combined
                    </p>
                  </div>
                </div>
                <div class="conclusion">
                  <h5>Consulsion :</h5>
                  <div class="ps-3">
                    <p>
                      Clearly explain the article's main conclusions, highlighting their importance and relevance.
                    </p>
                  </div>
                </div>
                <div class="data-availability">
                  <h5>Data availability :</h5>
                  <div class="ps-3">
                    <p>
                      This statement should describe how readers can access the data supporting the study's conclusions
                      and clearly outline why unavailable data cannot be released.
                    </p>
                  </div>
                </div>
                <div class="conflicts">
                  <h5>Conflicts of interest :</h5>
                  <div class="ps-3">
                    <p>
                      The authors must declare all relevant interests that can perceive as conflicting. Writers should
                      describe how each conflicting interest could exist. The writers should declare any lack of conflicts
                      of interest. Co-authors' declarations of interest are the responsibility of the submitting authors.
                    </p>
                    <p>
                      Conflicts of interest (COIs, also called "competing interests") occur when issues outside research
                      could be reasonably perceived to affect the neutrality or impartiality of the work or its
                      assessment. For more information, see our publication ethics policy. Authors must declare all
                      potential interests – whether or not they had an influence – in the conflicts of interest section,
                      which should explain why the interest may be a conflict. If there are none, the authors should
                      state: "The author(s) declare(s) that there is no conflict of interest regarding the publication of
                      this paper." Submitting authors are responsible for co-authors declaring their interests. Declared
                      conflicts of interest will be considered by the editor and reviewers and included in the published
                      papers.
                    </p>
                    <p>
                      Authors must declare current or recent funding (including for article processing charges) and other
                      payments, goods, or services that might influence the work. All funding must be declared in the
                      funding statement, whether a conflict or not. The involvement of anyone other than the authors who:
                      i) has an interest in the outcome of the work; ii) is affiliated to an organization with such an
                      interest; or iii) was employed or paid by a funder in the commissioning, must declare the
                      conception, planning, design, conduct, or analysis of the work, the preparation or editing of the
                      manuscript, or the decision to publish.
                    </p>
                    <p>
                      Due to your declaration, you may ask to make specific changes to your manuscript. These requests are
                      not an accusation of impropriety. The editor or reviewer is helping you to protect your work against
                      potential criticisms.
                    </p>
                    <p>
                      If you doubt declaring a potential conflict, remember that if it is revealed later – especially
                      after publication – it could cause more problems than simply declaring it at the time of submission.
                      Undeclared conflicts of interest could lead to a corrigendum or, in the most severe cases, a
                      retraction.
                    </p>
                  </div>
                </div>
                <div class="funding">
                  <h5>Funding statement :</h5>
                  <div class="ps-3">
                    <p>
                      Authors should indicate how the research and publication of their article were funded by naming the
                      funding support body (written in total), followed by the relevant grant number(s) in square brackets
                      (if applicable), for example: "This work was support by the Engineering and Physical Sciences
                      Research Council [grant numbers xxxx, yyyy]; National Science Foundation [grant number zzzz]; and a
                      Leverhulme Trust Research Project Grant.
                    </p>
                    <p>
                      If the research did not receive specific funding but was performed as part of the authors'
                      employment, please name this employer. If the funder was involved in the writing, editing, approval,
                      or decision to publish the manuscript, please declare this.
                    </p>
                  </div>
                </div>
                <div class="acknowledgements">
                  <h5>Acknowledgements :</h5>
                  <div class="ps-3">
                    <p>
                      All acknowledgments (if any) should be contained at the very end of the manuscript before the
                      references. Anyone who contributed to the analysis or manuscript but is not a listed author should
                      be acknowledged (with their permission).
                    </p>
                  </div>
                </div>
                <div class="reference">
                  <h5>Reference :</h5>
                  <div class="ps-3">
                    <p>
                      References from authors may be submitted in any format. INDJECE will reformat them if they are
                      approved. Each reference must contain comprehensive and correct information, which is the author's
                      responsibility. Each reference has to have a unique number that follows the order of the initial
                      citation. References should be cited inside square brackets, such as "as mentioned by Smith [9]" or
                      "as discussed elsewhere [9, 10]," for example. Uncited references will discard, so cite anything you
                      use.
                    </p>
                    <p>
                      <b>Citation standards.</b>All data, program code and other methods must cite appropriately. Such
                      materials should be recognized as original intellectual contributions and acknowledged by citation.
                    </p>
                  </div>
                </div>
                <div class="date-formatting">
                  <h5>Date formatting :</h5>
                  <div class="ps-3">
                    <p>
                      INDJECE recommends writing dates out entirely to avoid confusion with different all-numeral date
                      styles. For example, 11/10/2021 could be November 10, 2021, or October 11, 2021, depending on the
                      reader; therefore, the date should be written in full. For example, September 1, 2021, should be
                      used rather than 01/09/2021 or 09/01/2021.
                    </p>
                  </div>
                </div>
                <div class="units">
                  <h5>Units of measurements :</h5>
                  <div class="ps-3">
                    <p>
                      Units of measurement should be presented and concisely using the International System of Units (SI).
                    </p>
                  </div>
                </div>
                <div class="preparation-of-figures">
                  <h5>Preparation of figures :</h5>
                  <div class="ps-3">
                    <p>
                      Upon submission of an article, authors should include all figures and tables in the PDF file of the
                      manuscript. Figures and tables should submit in multiple files. If the article is accepted, authors
                      will be asked to provide the source files of the figures. Each figure should be supplied in a
                      separate electronic file. All figures should be cited in the manuscript in consecutive order.
                      Figures should be supplied in either vector art formats (Illustrator, EPS, WMF, FreeHand, CorelDraw,
                      PowerPoint, Excel, etc.) or bitmap formats (Photoshop, TIFF, GIF, JPEG, etc.). Bitmap images should
                      be of 300 dpi resolution at least unless the resolution is intentionally set to a lower level for
                      scientific reasons. If a bitmap image has labels, the image and labels should be embedded in
                      separate layers.
                    </p>
                    <p>
                      <b>Maps.</b>INDJECE Limited remains neutral with regard to jurisdictional claims in published maps.
                      For reasons of consistency, authors are requested to use accepted standard maps as the basis for map
                      figure drawing, for example, using the latest standard base map of Map Press. Responsibility for
                      maps rests with the author, and it is their responsibility also to provide any copyright or license
                      information when using maps that are not owned or created by the author (e.g., Google Maps, etc.)
                    </p>
                  </div>
                </div>
                <div class="preparation-of-table">
                  <h5>Preparation of tables :</h5>
                  <div class="ps-3">
                    <p>
                      Tables should be cited consecutively in the text. Every table must have a descriptive title; if
                      numerical measurements give, the units should include in the column heading. Vertical rules should
                      not be used.
                    </p>
                  </div>
                </div>
                <div class="proofs">
                  <h5>Proofs :</h5>
                  <div class="ps-3">
                    <p>
                      Corrected proofs must be returned to the publisher within two to three days of receipt. The
                      publisher will do everything possible to ensure prompt publication.
                    </p>
                  </div>
                </div>
                <div class="copyright">
                  <h5>Copyright and permission :</h5>
                  <div class="ps-3">
                    <p>
                      Writers maintain ownership of their manuscripts, and all open-access papers are released under the
                      terms of the Creative Commons Attribution License, which allows free use, distribution, and
                      reproduction in any form as long as the original work is properly credited.
                      The use of general descriptive names, trade names, trademarks, and so on in this book, even if not
                      expressly mentioned, does not indicate that applicable laws and regulations do not protect these
                      names. The submitting author is responsible for obtaining any rights required to utilize copyrighted
                      elements in the submission.

                    </p>
                  </div>
                </div>
                <div class="appeals">
                  <h5>Appeals :</h5>
                  <div class="ps-3">
                    <p>
                      Authors may file an appeal if they believe that the decision to reject the manuscript was made
                      because there was a significant misinterpretation of a technical component of the article or ii) the
                      scientific advance demonstrated by the manuscript needed to be understood. Appeals that ask for a
                      second opinion must provide more explanation to be considered. Please send an email to the journal
                      with your appeal and your manuscript number. Only appeals from the original submitter's author will
                      be taken into consideration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjece" },
        { id: 2, name: "Aim & Scope", to: "/indjece/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjece/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjece/guidance-for-ems"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjece/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjece/current-issue" },
        { id: 7, name: "Archives", to: "/indjece/archives" },
        { id: 8, name: "Editorial Board", to: "/indjece/editorial-board" },
        { id: 9, name: "Topics", to: "/indjece/topics" },
        { id: 10, name: "Call for papers", to: "/indjece/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjece/instruction-for-authors",
        },



        { id: 12, name: "Ethics & Policies", to: "/indjece/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjece/indexing-abstracting" },
        { id: 14, name: "Impact Factor", to: "/indjece/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjece/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjece/downloads" },
        { id: 17, name: "FAQs", to: "/indjece/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjece/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjece/indjece-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}</style>
