<template>
    <div>
        <Navbar />
        <MainBannerFive />
        <CreateAwesomeService />
        <WeAredDynamicTeam />
        <FunFacts />
        <OurService />
        <OurCaseStudies />
        <OutstandingDigitalExperience />
        <WeAlwaysTryToUnderstand class="gray-bg" />
        <OurAffordablePricingPlans />
        <GetFreeAnalysis />
        <LetsGetToWork />
        <!-- <LatestNews /> -->
        <GetOnlyNewUpdateTwo class="bg-white" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBannerFive from '../HomeSix/MainBannerFive'
import CreateAwesomeService from '../HomeSix/CreateAwesomeService'
import WeAredDynamicTeam from '../HomeSix/WeAredDynamicTeam'
import FunFacts from '../Common/FunFacts'
import OurService from '../HomeSix/OurService'
import OurCaseStudies from '../HomeSix/OurCaseStudies'
import OutstandingDigitalExperience from '../HomeSix/OutstandingDigitalExperience'
import OurAffordablePricingPlans from '../HomeSix/OurAffordablePricingPlans'
import WeAlwaysTryToUnderstand from '../HomeSix/WeAlwaysTryToUnderstand'
import GetFreeAnalysis from '../HomeSix/GetFreeAnalysis'
import LetsGetToWork from '../Common/LetsGetToWork'
// import LatestNews from '../Common/LatestNews'
import GetOnlyNewUpdateTwo from '../Common/GetOnlyNewUpdateTwo'
import Footer from '../Layout/Footer'

export default {
    components: { 
        Navbar,
        MainBannerFive,
        CreateAwesomeService,
        FunFacts,
        WeAredDynamicTeam,
        OurService,
        OurCaseStudies,
        OurAffordablePricingPlans,
        OutstandingDigitalExperience,
        WeAlwaysTryToUnderstand,
        GetFreeAnalysis,
        LetsGetToWork,
        // LatestNews,
        GetOnlyNewUpdateTwo,
        Footer,
    }
}
</script>
