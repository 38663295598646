<template>
    <div>
      <Navbar />
      <PageTitle pageTitle="Contact Us"/>
      <div class="journal-page-area pb-100 " style="padding-top: 50px;">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-lg-3">
              <div class="menu-wrapper">
                <div class="menu-header">
                  <h4>INDJEEE</h4>
                </div> 
                <ul class="no-style">
                  <li v-for="sublink in links" :key="sublink.id">
                    <i class="fas fa-chevron-right"></i>
                    <router-link :to="sublink.to">
                      {{ sublink.name }}
                      <span></span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-9 col-md-9 mt-3">
              <div
              class="containers"
              style="display: flex; flex-direction: row; margin-bottom: 20px"
            >
              <div
                class="editorialBox p-4"
                style="margin-right: 2%; width: 49%"
              >
                <p>
                  <b>Publisher:</b>  Fifth Dimension research Publication (P)
                  Ltd. No: 38D5F, Rajaji nagar, Ariyalur, Perambalur.
                  Tamilnadu-621713 India.
                </p>
                <p><b>Website:</b> www.fdrpjournals.org</p>
                <p><b>Phone : </b>+91 9840521421</p>
                <p><b>Email : </b><i> fdrpjournals@gmail.com</i></p>
              </div>
              <div class="editorialBox p-4" style="width: 49%">
                <p>
                  <b>Editor-in-chief:</b> Dr. S. Sivaganesan Professor & Head in
                  EEE House No:55-E Kamaraj Nagar, 2nd Street, Ariyalur-621713.
                </p>
                <p><b>Phone :</b> +91 98405 21421</p>
                <p><b>Email : </b><i> Editor_indjeee@fdrpjournals.org</i></p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../../Layout/Navbar.vue"
  import Footer from "../../Layout/Footer";
  import PageTitle from "../../Common/PageTitle";
  export default {
    name: "ProjectsDetailsContent",
    components: {
       Navbar,
      Footer,
      PageTitle,
    },
    data: function () {
      return {
        links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjeee/Guidance-For-Ems-Indjeee"},
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
      };
    },
  };
  </script>
  <style scoped lang="scss">
  h3 {
    font-size: 24px;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
  }
  ul li {
    list-style: none;
  }
  
  .mission-of-journal ul li {
    margin-left: -12px;
  }
  @media screen and (max-width:992px){
  .containers{
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
.editorialBox {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media screen and (max-width: 992px) {
  .containers {
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
  </style>
  
