<template>
  <div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingSix">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            Conflicts of Interest and Funding
          </button>
        </h2>
        <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p>
              In the interests of transparency and to help readers form their own judgments of potential bias, the INDJEEE
              requires authors to declare any competing interests in relation to the work described. The competing
              interests are defined as financial and non-financial interests that could directly undermine, or be
              perceived to undermine the objectivity, integrity, and value of a publication, through a potential influence
              on the judgments and actions of authors regarding objective data presentation, analysis, and interpretation.
            </p>
            <p>
              The corresponding author is responsible for submitting a competing interests’ statement via the open journal
              system (OJS) on behalf of all authors of the paper. Since the INDJEEE adopts a peer review,
              reviewers will be provided with a minimal statement disclosing the existence of any financial or
              non-financial interest, to prevent the disclosure of authors’ identities. We also expect that anyone who
              suspects an undisclosed conflict of interest regarding a work published or under consideration by INDJEEE to
              inform the relevant editor or email editorinchief@indjeee.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  color: #f5f5f5 !important;
  background-color: #0000ff !important;
  border: none;
}

.accordion-header[data-v-1309cd11] {
  background-color: white;
  border: none;
}

.header[data-v-1309cd11] {
  border: none;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 23px;
  height: 14px;
  margin-left: auto;
  content: "";
  background-image: url("./VectorW.png");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-top: 6px;
}

.accordion-button:not(.collapsed) {
  background-color: #0000ff;
}

.header[data-v-1309cd11] {
  background-color: #0000ff !important;
}

button:focus:not(:focus-visible) {
  background-color: #0000ff !important;
}

.accordion-button:focus {
  border: none;
}

.accordion-body {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #f5f5f5;
}

.accordion-button:focus {
  box-shadow: none;
}</style>