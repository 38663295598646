<template>
  <div class="features-area ptb-100 pt-0">
    <div class="container">
      <div class="section-title">
        <span class="sub-title mt-5"></span>
        <h2>We always try to understand customers expectation</h2>
      </div>

      <div class="features-list-tab">
        <div class="tabs">
          <ul class="tabs__header">
            <li
              class="tabs__header-item"
              v-for="tab in tabs"
              v-on:click="selectTab(tab.id)"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <i :class="tab.icon"></i>
              {{ tab.title }}
            </li>
          </ul>
        </div>
        <div class="tabs__container">
          <div class="tabs__list" ref="tabsList">
            <div
              class="tabs__list-tab"
              v-for="tab in tabs"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <div class="features-overview">
                <div class="overview-content">
                  <div class="content">
                    <span class="sub-title">{{ tab.subTitle }}</span>
                    <h2>{{ tab.title }}</h2>
                    <p>{{ tab.description }}</p>

                    <ul class="features-list">
                      <li
                        v-for="features in tab.featuresList"
                        :key="features.id"
                      >
                        <span>
                          <i :class="features.icon"></i>
                          {{ features.list }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="overview-image">
                  <div class="image">
                    <img :src="tab.image" alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape-img7">
      <img src="../../assets/images/shape/shape-7.png" alt="image" />
    </div>
    <div class="shape-img2">
      <img src="../../assets/images/shape/shape-2.svg" alt="image" />
    </div>
    <div class="shape-img3">
      <img src="../../assets/images/shape/shape-3.svg" alt="image" />
    </div>
    <div class="shape-img4">
      <img src="../../assets/images/shape/shape-4.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WeAlwaysTryToUnderstand",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,
      tabs: [
        {
          id: 1,
          icon: "flaticon-achievement",
          title: "For Author",
          subTitle: "",
          description:
            "Find the right home for your research, the international journal paper and discover the resources available to support you through your publishing journey.",
          featuresList: [
            {
              id: 1,
              icon: "flaticon-tick",
              list: "Publish with us",
            },
            {
              id: 2,
              icon: "flaticon-tick",
              list: "Find a Journal",
            },
            {
              id: 3,
              icon: "flaticon-tick",
              list: "Calls for Papers",
            },
            {
              id: 4,
              icon: "flaticon-tick",
              list: "Publish open access",
            },
          ],
          image: require("../../assets/images/features/author.png"),
        },
        {
          id: 2,
          icon: "flaticon-architecture",
          title: "For Researchers",
          subTitle: "",
          description:
            "Help & guidance on how to plan and optimize your research international journal paper, data and insight collection, and analysis.",
          featuresList: [
            {
              id: 1,
              icon: "flaticon-tick",
              list: "Use mixed methods research",
            },
            {
              id: 2,
              icon: "flaticon-tick",
              list: "Conduct empirical research",
            },
            {
              id: 3,
              icon: "flaticon-tick",
              list: "Design a research study",
            },
            {
              id: 4,
              icon: "flaticon-tick",
              list: "Manage the research process",
            },
          ],
          image: require("../../assets/images/features/research.png"),
        },
        {
          id: 3,
          icon: "flaticon-digital-marketing",
          title: "Editing & Reviewing",
          subTitle: "",
          description:
            "In-depth support on a range of academic publishing topics, from research methodologies, observation and analysis to how to publish an international journal paper and ensure it's downloaded, and writing a dissertation.",
          featuresList: [
            {
              id: 1,
              icon: "flaticon-tick",
              list: "Ensure your article is highly download",
            },
            {
              id: 2,
              icon: "flaticon-tick",
              list: "Edit a Multi-authored volume",
            },
            {
              id: 3,
              icon: "flaticon-tick",
              list: "Write a book review",
            },
            {
              id: 4,
              icon: "flaticon-tick",
              list: "Publish a journal article",
            },
          ],
          image: require("../../assets/images/features/editing.png"),
        },
        {
          id: 4,
          icon: "flaticon-analytics",
          title: "For Librarians",
          subTitle: "",
          description:
            "We've collated all our guidance for librarians here, including links to the librarian toolkit and useful resources.",
          featuresList: [
            {
              id: 1,
              icon: "flaticon-tick",
              list: "Promote your research",
            },
            {
              id: 2,
              icon: "flaticon-tick",
              list: "Librarian Toolkit",
            },
            {
              id: 3,
              icon: "flaticon-tick",
              list: "Library Research",
            },
            {
              id: 4,
              icon: "flaticon-tick",
              list: "Resource for discovery",
            },
          ],
          image: require("../../assets/images/features/librariens.png"),
        },
        {
          id: 5,
          icon: "flaticon-data",
          title: "For Reviewers",
          subTitle: "",
          description:
            "Becoming and being a peer reviewer is an important role to play in the scholarly landscape. Without reviewers continuing to commit time and passion to their peer review role, the integrity of international research papers would be harder to uphold.",
          featuresList: [
            {
              id: 1,
              icon: "flaticon-tick",
              list: "Peer review resources",
            },
            {
              id: 2,
              icon: "flaticon-tick",
              list: "Good review",
            },
            {
              id: 3,
              icon: "flaticon-tick",
              list: "Top tips",
            },
            {
              id: 4,
              icon: "flaticon-tick",
              list: "Further resources",
            },
          ],
          image: require("../../assets/images/features/reviewers.png"),
        },
      ],
    };
  },
  methods: {
    selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
      this.offsetRight = tabsList.clientWidth * (id - 1);
      tabsList.style.right = this.offsetRight + "px";
    },
  },
  mounted() {
    let tabsList = this.$refs.tabsList;
    tabsList.style.right = this.offsetRight + "px";
  },
};
</script>
